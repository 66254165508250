import { FC } from 'react';
import {
  CircularProgress,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core';

interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
}

const Button: FC<ButtonProps> = (props) => {
  const { loading = false, ...restProps } = props;

  return (
    <MuiButton
      disableRipple
      disableElevation
      disableFocusRipple
      disableTouchRipple
      endIcon={loading ? <CircularProgress size={20} /> : null}
      {...restProps}
    />
  );
};

export default Button;
