import { useRef, useState } from 'react';

import {
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';

// Components
import Button from 'Views/FlexPayV2/common-components/Mui/Button';

// Icons
import { ReactComponent as FlashIcon } from 'assets/icons/flexpay/flash.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/flexpay/note.svg';
import { PAGE_URLS } from 'Routes/Main/constants';

const InvoiceCreateBtn = ({
  pageOrigin = PAGE_URLS.FLEX_PAY_INVOICES_LIST,
}) => {
  // navigation hook :
  const history = useHistory();

  const selectInvoiceType = (key: string) => {
    // Redirect the page to create invoice page , by setting the type
    history.push(PAGE_URLS.FLEX_PAY_INVOICES_MANAGE, {
      type: key,
      origin: pageOrigin,
    });
  };

  return <InvoiceCreateBtnRenderer onMenuClick={selectInvoiceType} />;
};

export const InvoiceCreateBtnRenderer = ({
  onMenuClick = (key: string) => {},
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  const openInvoiceDropdown = () => setAnchorEl(ref?.current);

  return (
    <Grid item ref={ref}>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        endIcon={
          <ExpandMoreIcon fontSize="large" style={{ width: 24, height: 24 }} />
        }
        onClick={openInvoiceDropdown}
        data-testid="create-invoice-button"
      >
        Create Invoice
      </Button>
      {anchorEl ? (
        <InvoiceTypeMenu
          anchorEL={anchorEl}
          closeMenu={() => setAnchorEl(null)}
          onMenuClick={onMenuClick}
        />
      ) : (
        <></>
      )}
    </Grid>
  );
};

export const InvoiceTypeMenu = ({
  anchorEL,
  closeMenu,
  onMenuClick,
}: {
  anchorEL: HTMLDivElement | null;
  closeMenu: () => void;
  onMenuClick: (key: string) => void;
}) => {
  const createMenuOptions = [
    {
      title: 'Quick Invoice',
      icon: <FlashIcon />,
      key: 'quick',
    },
    {
      title: 'Standard Invoice',
      icon: <NoteIcon />,
      key: 'standard',
    },
  ];

  return (
    <Menu
      keepMounted
      anchorEl={anchorEL}
      getContentAnchorEl={null}
      open={Boolean(anchorEL)}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          padding: 4,
        },
      }}
      style={{ marginTop: -6 }}
    >
      {createMenuOptions.map(({ title, icon, key }) => (
        <MenuItem
          key={key}
          onClick={() => onMenuClick(key)}
          data-testid={`CREATE-INVOICE-MENU-ITEM-${key}`}
        >
          <ListItemIcon style={{ minWidth: 'unset' }} className="m-r-8">
            {icon}
          </ListItemIcon>
          <ListItemText primary={title} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default InvoiceCreateBtn;
