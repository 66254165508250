const palette = {
  alternate: {
    main: '#f7f5fb',
    dark: '#E8EAF6',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    light: '#DBC0FF',
    main: '#5A3B84',
    contrastText: '#FFF',
    highlight: '#FF7B9F',
    highlight2: '#FC8E83',
    highlight3: '#FF7F7C',
  },
  secondary: {
    light: '#9BDDFF',
    main: '#53BDE7',
    contrastText: '#FFF',
    hightlight: '#49B2FF',
  },
  text: {
    primary: '#475670',
    secondary: '#697282',
    disabled: '#CCC',
    highlight: '#FFF',
    light: '#EAF1F8',
    success: '#38ada9',
    errorBorder: '#F44336',
    levelOnboardColor: '#475670',
    lightBlue: '#17A4EA',
    lightGrey: '#64748B',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#FFF',
    variant1: '#F2F8FA',
    default: '#FFF',
    level2: '#F5F5F5',
    level1: '#FFF',
    progressBG: '#E5EAF4',
    success: '#38ada9',
    warning: '#ff9800',
    error: '#FFEAEA',
    loginGradiant:
      'linear-gradient(168.43deg, rgba(240, 83, 84, 0.3) -18.79%, #f8f6fc 23%, #f8f6fc 50%, rgba(252, 142, 131, 0) 60%, #2095d733 87%)',
    levelOnboardGradiant:
      'linear-gradient(168.43deg, rgba(240, 83, 84, 0.3) -18.79%, #f8f6fc 23%, #f8f6fc 50%, rgba(252, 142, 131, 0) 60%, #2095d733 87%)',
    levelOnboardBg: '#f8f6fc',
  },
  hover: 'rgba(245,244,246,.5)',
  table_head: '#FEE8E780',
};

export default palette;
