import httpService from '../Api/http.service';

const { REACT_APP_API_BASE_URL, REACT_APP_GOOGLE_MAPS_KEY } = process.env;
export const getSubApiBaseUrl = () => `${REACT_APP_API_BASE_URL}/sub`;
export const getSubV2ApiBaseUrl = () => `${REACT_APP_API_BASE_URL}/subv2`;
export const getSubscriberPortalBaseUrl = () =>
  `${REACT_APP_API_BASE_URL}/subscriberportal`;
export const getCareApiBaseUrl = () => `${REACT_APP_API_BASE_URL}/care`;
const getReportsBaseUrl = () => `${REACT_APP_API_BASE_URL}/report`;

const updateRenewalStatus = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/cancel_renewal`,
    method: 'put',
    data: payload,
  });

const validateCouponCode = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/validate-coupon`,
    method: 'post',
    data: payload,
  });

const saveSubscriberCoupon = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/coupons`,
    method: 'put',
    data: payload,
  });

const addressSuggestion = (address) => {
  const suggestionEndPoint = `/subscriberportal/places/autocomplete`;
  return httpService({
    url:
      REACT_APP_API_BASE_URL +
      `${suggestionEndPoint}?components=%7B%22country%22%3A%22us%22%7D&input_text=${address}`,
    method: 'get',
  });
};

const getPlaceDetails = (placeId) => {
  const placeDetailsEndPoint = `/subscriberportal/places`;
  return httpService({
    url: REACT_APP_API_BASE_URL + `${placeDetailsEndPoint}?place_id=${placeId}`,
    method: 'get',
  });
};

const validateAddress = (payload) => {
  return httpService({
    url: `https://addressvalidation.googleapis.com/v1:validateAddress?key=${REACT_APP_GOOGLE_MAPS_KEY}`,
    method: 'post',
    data: payload,
  });
};

const checkEmailValidity = (groupId, payload) =>
  httpService({
    url: getSubscriberPortalBaseUrl() + `/group/${groupId}/verify`,
    method: 'put',
    data: payload,
  });

const fetchSubscriberByQueryParams = (params) => {
  const api = `/subscriptions?${params || ''}`;

  return httpService({
    url: getSubV2ApiBaseUrl() + api,
    method: 'get',
  });
};

const exportAsyncSubscribers = (payload) => {
  return httpService({
    url: getReportsBaseUrl() + `/offline-report`,
    data: payload,
    method: 'post',
  });
}

const getReportStatus = (reportId) => {
  return httpService({
    url: getReportsBaseUrl() + `/offline-report/${reportId}`,
    method: 'get',
  });
}

const exportSubscribers = (reportId) => {
  const api = `/offline-report/${reportId}/download`;

  return httpService({
    url: getReportsBaseUrl() + api,
    method: 'get',
    headers: {
      Accept: 'application/ms-excel',
    },
    responseType: 'arraybuffer',
  });
};

const postSubscriberData = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscription`,
    method: 'post',
    data: payload,
  });

const getSubscriberInfoData = (subscription_id) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/${subscription_id}`,
    method: 'get',
  });

const getSubscriberActivity = (params) =>
  httpService({
    url: getCareApiBaseUrl() + `/activities${params}`,
    method: 'get',
  });

const getCancelSubscriptionInfo = (subscription_id) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/${subscription_id}/cancel-info`,
    method: 'get',
  });

const getDeleteDependentInfo = (patient_id) =>
  httpService({
    url:
      getSubApiBaseUrl() + `/subscriptions/members/${patient_id}/remove-info`,
    method: 'get',
  });

const updateSubscriberData = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscription`,
    method: 'put',
    data: payload,
  });

const updateSubscriberDetails = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriberDetails`,
    method: 'put',
    data: payload,
  });

const getSetupIntent = () =>
  httpService({
    url: getSubApiBaseUrl() + `/setup_intent`,
    method: 'get',
  });

const cancelSubscription = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/cancel_subscription`,
    method: 'put',
    data: payload,
  });

const cancelRefundSubscription = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/refund_cancel`,
    method: 'post',
    data: payload,
  });

const pauseSubscription = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/pause_subscription`,
    method: 'put',
    data: payload,
  });

const resumeSubscription = (subscription_id) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/${subscription_id}/resume`,
    method: 'put',
  });

const reactivateSubscription = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscription/reactivate`,
    method: 'put',
    data: payload,
  });

const activateDraftSubscription = (payload) => {
  const { subscription_id, ...restProps } = payload;

  return httpService({
    url: getSubApiBaseUrl() + `/subscriptions/${subscription_id}/activate`,
    method: 'put',
    data: restProps,
  });
};

const reactivateRenewal = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/reactivate_renewal`,
    method: 'put',
    data: payload,
  });

const getActiveSubscriptions = (params) =>
  httpService({
    url: getSubV2ApiBaseUrl() + `/subscriptions/dashboards/months${params}`,
    method: 'get',
  });

const getPlanDashboard = (params) =>
  httpService({
    url: getSubV2ApiBaseUrl() + `/subscriptions/dashboards/active${params}`,
    method: 'get',
  });

const getLocationDashboard = (params) =>
  httpService({
    url: getSubV2ApiBaseUrl() + `/subscriptions/dashboards/locations?${params}`,
    method: 'get',
  });

const getOverdueDetails = (params) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscription/overdue?${params}`,
    method: 'get',
  });

const reinstateSubscription = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscription/reinstate`,
    method: 'put',
    data: payload,
  });

const getSubscriberEmails = (params) =>
  httpService({
    url: getCareApiBaseUrl() + `/comms/emails${params}`,
    method: 'get',
  });

const addNotes = (payload) =>
  httpService({
    url: getCareApiBaseUrl() + `/notes`,
    method: 'post',
    data: payload,
  });

const getNotes = (params) =>
  httpService({
    url: getCareApiBaseUrl() + `/notes${params}`,
    method: 'get',
  });

const updateNotes = (note_id, payload) =>
  httpService({
    url: getCareApiBaseUrl() + `/notes/${note_id}`,
    method: 'put',
    data: payload,
  });

const uploadNotesDocument = (note_id, payload) =>
  httpService({
    url: getCareApiBaseUrl() + `/notes/${note_id}/file`,
    method: 'post',
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const deleteNotesDocument = (note_id, payload) =>
  httpService({
    url: getCareApiBaseUrl() + `/notes/${note_id}/file`,
    method: 'delete',
    data: payload,
  });

const addAddonSubscription = (subscription_id, payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/${subscription_id}/addon`,
    method: 'put',
    data: payload,
  });

const changeSubscription = (subscription_id, payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/${subscription_id}/plans`,
    method: 'put',
    data: payload,
  });

const changePrimarySubscriber = (subscription_id, payload) =>
  httpService({
    url:
      getSubApiBaseUrl() + `/subscriptions/${subscription_id}/switch-primary`,
    method: 'put',
    data: payload,
  });

const prepayRenewal = (subscription_id, payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/${subscription_id}/prepay`,
    method: 'put',
    data: payload,
  });

const getInvoiceDetails = (subscription_id, params) =>
  httpService({
    url: getSubV2ApiBaseUrl() + `/subscriptions/${subscription_id}/invoices?${params || ''}`,
    method: 'get',
  });

const getUpcomingInvoiceDetails = (subscription_id) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/${subscription_id}/upcoming`,
    method: 'get',
  });

const changeBillingDate = (subscription_id, payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/${subscription_id}/billing`,
    method: 'put',
    data: payload,
  });

const getMemberCard = (subscription_id) =>
  httpService({
    url:
      getSubApiBaseUrl() + `/subscriptions/${subscription_id}/membership-card`,
    method: 'get',
  });

const subscriberService = {
  postSubscriberData,
  checkEmailValidity,
  fetchSubscriberByQueryParams,
  exportAsyncSubscribers,
  getReportStatus,
  exportSubscribers,
  getSubscriberInfoData,
  getSubscriberActivity,
  getCancelSubscriptionInfo,
  getDeleteDependentInfo,
  validateCouponCode,
  saveSubscriberCoupon,
  updateRenewalStatus,
  updateSubscriberData,
  updateSubscriberDetails,
  getSetupIntent,
  cancelSubscription,
  cancelRefundSubscription,
  pauseSubscription,
  resumeSubscription,
  activateDraftSubscription,
  reactivateSubscription,
  reactivateRenewal,
  getActiveSubscriptions,
  getPlanDashboard,
  getLocationDashboard,
  getOverdueDetails,
  reinstateSubscription,
  getSubscriberEmails,
  addNotes,
  getNotes,
  updateNotes,
  uploadNotesDocument,
  deleteNotesDocument,
  addAddonSubscription,
  changeSubscription,
  changePrimarySubscriber,
  prepayRenewal,
  changeBillingDate,
  addressSuggestion,
  getMemberCard,
  getPlaceDetails,
  validateAddress,
  getInvoiceDetails,
  getUpcomingInvoiceDetails,
};

export default subscriberService;
