import moment from 'moment';
import IInvoiceCrudSliceData from 'Views/FlexPayV2/types/store/invoiceCrudSlice/IInvoiceCrudSliceData';

const getInvoiceCrudSliceData = (): IInvoiceCrudSliceData => ({
  invoiceVO: {
    amount: 0,
    processing_fees: 0,
    refunded_amount: 0,
    currency_code: '',
    status: '',
    terms_accepted: false,
    id: '',
    display_id: '',
    location_id: '',
    location_name: '',
    group_id: '',
    invoice_type: 'standard',
    due_at: moment().toISOString(),
    due_error: '',
    created_at: '',
    updated_at: '',
    deleted_at: null,
    patient: {
      first_name: '',
      last_name: '',
      email: '',
      date_of_birth: '',
    },
    billing_detail: {
      address_line1: '',
      city: '',
      state: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      postal_code: '',
      country_code: 'US',
    },
    same_as_patient_info: true,
    mailing_address: {
      address_line1: '',
      city: '',
      state: '',
      postal_code: '',
      id: '',
    },
    payments: [
      {
        id: '',
        paid_at: '',
        invoice_id: '',
        amount: 0,
        processing_fees: 0,
        payment_status: '',
        payment_type: '',
        payment_method: {
          id: '',
          payment_method_funding: '',
          payment_method_name: '',
          payment_method_last4: '',
        },
        payment_refunds: [],
      },
    ],
    line_items: [],
    cancellation_reason: '',
    cancellation_desc: '',
    adjustment_types: [],
  },

  // Patient Balance data
  balanceLoading: false,
  patientBalance: {
    pms_patient_total_balance: 0,
    pms_patient_estimated_insurance: 0,
    pms_patient_estimated_portion: 0,
  },

  // Reasons for custom line item
  customReasons: [],

  // ui data :
  loading: true,
  mode: 'CREATE',
  currentPage: 'SEARCH_PATIENT',
  terminalsLoading: false,
  terminalVOList: [],
  paymentDetails: {
    paymentType: 'select',
    terminalId: 'select',
    cardType: '',
    cardStatus: {
      error: {},
      complete: false,
    },
  },
  intentDetails: {
    client_secret: '',
  },
  crudLoading: false,
  sendLoading: false,
});

export default getInvoiceCrudSliceData;
