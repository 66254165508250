import httpService from "../Api/http.service";
import { processPlanByFrequency } from "Shared/Utils";

const planServices = {
  createPlan: (data) =>
    httpService({
      url: `/plan`,
      method: 'post',
      data,
    }),

  updatePlan: (data, plan_package_id) =>
    httpService({
      url: `/plan/${plan_package_id}`,
      method: 'put',
      data,
    }),

  // fetchGroupPlans: (group_id) =>
  //   httpService(`/group_plans/${group_id}`),

  // fetchLocationPlans: (location_id) =>
  //   httpService(`/location_plan/${location_id}`),

  getPlanAttributes: () => httpService('/plans/supported-attributes'),

  getPlanDetails: (plan_id, group_id) =>
    httpService(`/plan/${plan_id}?group_id=${group_id}`),

  fetchGroupPlanPackages: (group_id) =>
    httpService(`/group/${group_id}/plan-packages?mode=1`),

  fetchLocationPlanPackages: (
    location_id,
    hiddenPlans = false,
    member_type = 'Customer',
    mode = 1
  ) =>
    httpService(
      `/location/${location_id}/plan-packages?member_type=${member_type}${
        hiddenPlans ? '&all=yes' : ''
      }&mode=${mode}`,
    ).then((response) => ({
      ...response,
      data: {
        ...response.data,
        plansById: response?.data?.rows || [],
        plansByFrequency: processPlanByFrequency(response?.data?.rows || []),
      },
    })),

  fetchLocationEmployeePlanPackages: (location_id, hiddenPlans = false) =>
    httpService(
      `/location/${location_id}/plan-packages?member_type=Employee${
        hiddenPlans ? '&all=yes' : ''
      }&mode=1`,
    ).then((response) => ({
      ...response,
      data: {
        ...response.data,
        plansById: response?.data?.rows || [],
        plansByFrequency: processPlanByFrequency(response?.data?.rows || []),
      },
    })),

  getPlanPackageDetails: (plan_package_id) =>
    httpService(`/plan-package/${plan_package_id}`),
};

export default planServices;