import httpService from 'Api/http.service';
import { checkResponse } from './service.utils';
import IInvoiceVO, {
  IInvoiceLineItemsVO,
} from 'Views/FlexPayV2/types/vo/IInVoiceVO';

const { REACT_APP_FLEX_API_BASE_URL } = process.env;
const baseURL = `${REACT_APP_FLEX_API_BASE_URL}/v1`;
const invoiceURL = `${REACT_APP_FLEX_API_BASE_URL}/v1/invoices`;
const paymentsURL = `${REACT_APP_FLEX_API_BASE_URL}/v1/payments`;

export const getInvoices = async (params: any) => {
  const response = await httpService({
    url: invoiceURL,
    method: 'get',
    params: {
      ...params,
    },
    paramsSerializer: {
      indexes: null, // no brackets at all
    },
  });

  return checkResponse(response);
};

export const downloadInvoices = async (data: any) => {
  const response = await httpService({
    url: invoiceURL + '/download',
    method: 'post',
    data,
  });

  return checkResponse(response);
};

export const getInvoiceById = async (id: string) => {
  const response = await httpService({
    url: invoiceURL + `/${id}`,
    method: 'get',
  });

  return checkResponse(response);
};

export const cancelInvoiceById = async (
  id: string,
  data: { cancel_reason; cancel_desc },
) => {
  const response = await httpService({
    url: invoiceURL + `/${id}/cancel`,
    method: 'post',
    data: {
      cancellation_reason: data.cancel_reason,
      cancellation_description: data.cancel_desc,
    },
  });

  return checkResponse(response);
};

export const deleteInvoiceById = async (id: string) => {
  const response = await httpService({
    url: invoiceURL + `/${id}`,
    method: 'delete',
  });

  return checkResponse(response);
};

export const refundInvoiceById = async (
  id: string,
  data: { amount; refund_reason; refund_description },
) => {
  const response = await httpService({
    url: invoiceURL + `/${id}/refund`,
    method: 'post',
    data,
  });

  return checkResponse(response);
};

export const downloadInvoiceById = async (id: string) => {
  const response = await httpService({
    url: invoiceURL + `/${id}/download`,
    method: 'get',
  });

  return checkResponse(response);
};

export const sendInvoiceReminder = async (id: string) => {
  const response = await httpService({
    url: invoiceURL + `/${id}/send`,
    method: 'post',
    data: {},
  });

  return checkResponse(response);
};

export const sendInvoiceReceipt = async (id: string) => {
  const response = await httpService({
    url: invoiceURL + `/${id}/send`,
    method: 'post',
    data: {},
  });

  return checkResponse(response);
};

export const sendInvoiceToPay = async (id: string, data: any) => {
  const response = await httpService({
    url: invoiceURL + `/${id}/send`,
    method: 'post',
    data,
  });

  return checkResponse(response);
};

export const createInvoice = async (
  patientId: string,
  data: {
    type: string;
    line_items: Partial<IInvoiceVO['line_items']>;
  },
) => {
  const response = await httpService({
    url: invoiceURL,
    method: 'post',
    data: {
      ...data,
      patient_id: patientId,
    },
  });

  return checkResponse(response);
};

export const updateInvoice = async (
  invoiceId: string,
  payload: { delete?: string[]; line_items?: Partial<IInvoiceLineItemsVO>[] },
) => {
  const response = await httpService({
    url: `${invoiceURL}/${invoiceId}`,
    method: 'PATCH',
    data: {
      ...payload,
    },
  });

  return checkResponse(response);
};

export const getPaymentIntent = async (data: any) => {
  const response = await httpService({
    url: paymentsURL + '/intents',
    method: 'post',
    data,
  });

  return checkResponse(response);
};

export const payInvoice = async ({ invoiceId, data }) => {
  const response = await httpService({
    url: invoiceURL + `/${invoiceId}/pay`,
    method: 'post',
    data,
  });

  return checkResponse(response);
};

export const getTerminalPaymentStatus = async (paymentId: string) => {
  const response = await httpService({
    url: paymentsURL + `/${paymentId}`,
    method: 'get',
  });

  return checkResponse(response);
};

export const getAccessVerfied = async ({ data }) => {
  const response = await httpService({
    url: baseURL + '/verify',
    method: 'post',
    data,
  });

  return checkResponse(response);
};
