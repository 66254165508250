import ITransactionSliceData from 'Views/FlexPayV2/types/store/transactionSlice/ITransactionSliceData';
import ITransactionSliceReducers from 'Views/FlexPayV2/types/store/transactionSlice/ITransactionSliceReducers';

const getTransactionSliceReducers = (): ITransactionSliceReducers => ({
  setLoading(state, { payload: loading }) {
    state.loading = loading;
  },
  setCurrentPage(state, { payload: currentPage }) {
    state.currentPage = currentPage;
  },
  setStatsMap(state, { payload: statsMap }) {
    state.statsMap = statsMap;
  },
  setFilterMap(state, { payload: filterMap }) {
    const currentPage = state.currentPage;
    if (currentPage === 'payments') {
      state.filterMap = {
        ...state.filterMap, // We can only apply the needed filter, no need to give the full filter map to this reducers
        ...(filterMap as ITransactionSliceData['filterMap']),
      };
    } else {
      state.transfersFilterMap = {
        ...state.transfersFilterMap,
        ...(filterMap as ITransactionSliceData['transfersFilterMap']),
      };
    }
  },
  resetFilterMap(state, { payload: newFilterMap }) {
    const currentPage = state.currentPage;
    // This will update full filter map , not only the value
    if (currentPage === 'payments') {
      state.filterMap = newFilterMap as ITransactionSliceData['filterMap'];
    } else {
      state.transfersFilterMap =
        newFilterMap as ITransactionSliceData['transfersFilterMap'];
    }
  },
  setPaymentsIdMap(state, { payload: paymentsIdMap }) {
    state.paymentsIdMap = paymentsIdMap;
  },
  setPaginationConfigs(state, { payload: paginationConfig }) {
    const currentPage = state.currentPage;
    if (currentPage === 'payments') {
      state.paymentsPaginationConfig = {
        // Why this destructuring? So that this reducers can accept only updated values and keep the rest as it is
        ...state.paymentsPaginationConfig,
        ...paginationConfig,
      };
    } else {
      state.transfersPaginationConfig = {
        ...state.transfersPaginationConfig,
        ...paginationConfig,
      };
    }
    // Else for transfer page, so no need to pass the current page and no need to have one more reducers
  },
  resetTransactionStore(state, { payload: initData }) {
    return initData; // Need to return the init data to restore the state (sayings from redux toolkit)
  },
  setExportLoading(state, { payload: isExportLoding }) {
    state.isExportLoding = isExportLoding;
  },
  setTransfersIdMap(state, { payload: transfersIdMap }) {
    state.transfersIdMap = transfersIdMap;
  },
});

export default getTransactionSliceReducers;
