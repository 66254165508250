import IGLobalSliceReducers from 'Views/FlexPayV2/types/store/globalSlice/IGlobalSliceReducers';

const getGlobalSliceReducers = (): IGLobalSliceReducers => ({
  setUserInfo(state, { payload: userInfo }) {
    state.userInfo = userInfo;
  },
  setPartnerId(state, { payload: partnerId }) {
    state.partnerId = partnerId;
  },
  setGroupId: (state, { payload: groupId }) => {
    state.groupId = groupId;
  },
  setLocationId: (state, { payload: locationId }) => {
    state.locationId = locationId;
  },
  setGlobalLoading(state, { payload: loading }) {
    state.loading = loading;
  },
  setInitialGroupMap(state, { payload: groupList }) {
    const groupVOMap = groupList.reduce((acc, eachGroup) => {
      acc[eachGroup.group_id] = eachGroup;
      return acc;
    }, {});
    state.groupVOMap = groupVOMap;
  },
  setInitialLocationMap(state, { payload: locationList }) {
    const locationVOMap = locationList.reduce((acc, eachLocation) => {
      acc[eachLocation.location_id] = eachLocation;
      return acc;
    }, {});
    state.locationVOMap = locationVOMap;
  },
  setLocationLoading(state, { payload: locationLoading }) {
    state.locationLoading = locationLoading;
  },
});

export default getGlobalSliceReducers;
