import IGlobalSliceData from 'Views/FlexPayV2/types/store/globalSlice/IGlobalSliceData';

const getGlobalSliceData = (): IGlobalSliceData => ({
  userInfo: {} as any,
  loading: true,
  partnerId: '',
  groupId: '',
  locationId: '',
  groupVOMap: {},
  locationVOMap: {},
  locationLoading: false,
});

export default getGlobalSliceData;
