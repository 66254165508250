// Utils
import { formatToDateString, formatUTC } from 'utils';
import Utils from 'Shared/Utils';

// Types
import IInvoiceFilters, {
  IInvoiceTablePagination,
  IInvoiceTableSort,
} from '../types/vo/IInvoiceFilters';
import IGlobalSliceData from '../types/store/globalSlice/IGlobalSliceData';
import IPatientListFilters, {
  IPatientListTablePagination,
  IPatientListTableSort,
} from '../types/vo/IPatientListFilters';
import moment from 'moment';

export const constructInVoiceParams = (
  globalData: IGlobalSliceData,
  invoiceFilterData: IInvoiceFilters,
  invoiceTablePagination: IInvoiceTablePagination,
  invoiceTableSort: IInvoiceTableSort,
) => {
  const { groupId, locationId } = globalData;
  const {
    created: { createdEnd, createdStart },
    due: { dueEnd, dueStart },
    patientId,
    name: { firstName = '', lastName = '' },
    invoiceId,
    email,
    status,
  } = invoiceFilterData;
  
  const { currentPageNo, currentRowsPerPage } = invoiceTablePagination;
  const { orderBy, orderDesc = true } = invoiceTableSort;
  const dateField = dueStart ? 'due_at' : 'created_at'
  let params = {
    offset: +currentPageNo * +currentRowsPerPage,
    limit: +currentRowsPerPage,
    sort_by: orderBy || undefined,
    sort_order: orderDesc ? 'desc' : 'asc',
    group_ids: groupId === 'ALL_GROUPS' ? '' : groupId,
    location_ids: locationId === 'ALL_LOCATIONS' ? '' : locationId,
    date_field: dateField || undefined,
    start_date: createdStart ? formatUTC(createdStart) : undefined,
    end_date: createdEnd ? formatUTC(createdEnd) : undefined,
    due_start: dueStart ? formatUTC(dueStart) : undefined,
    due_end: dueEnd ? formatUTC(dueEnd) : undefined,
    pms_patient_id: patientId || undefined,
    first_name: firstName || undefined,
    last_name: lastName || undefined,
    display_id: invoiceId || undefined,
    email: email ? encodeURIComponent(email) : undefined,
    status: status?.length ? status : undefined,
  };

  return params;
};

export const constructPatientListParams = (
  globalData: IGlobalSliceData,
  patientListFilterData: IPatientListFilters,
  patientListTablePagination: IPatientListTablePagination,
  patientListTableSort: IPatientListTableSort,
) => {
  const { groupId, locationId } = globalData;
  const {
    appointmentDate: { apptDateStart, apptDateEnd },
    name: { firstName = '', lastName = '' },
    email,
    patientId,
    dateOfBirth,
    phoneNumber,
  } = patientListFilterData;

  const { currentPageNo, currentRowsPerPage } = patientListTablePagination;
  const { orderBy, orderDesc = true } = patientListTableSort;

  let params = {
    offset: +currentPageNo * +currentRowsPerPage,
    limit: +currentRowsPerPage,
    sort_by: orderBy,
    sort_order: orderDesc ? 'desc' : 'asc',
    group_ids: groupId === 'ALL_GROUPS' ? '' : groupId,
    location_ids: locationId === 'ALL_LOCATIONS' ? '' : locationId,
    appointment_date_start: apptDateStart && formatToDateString(apptDateStart),
    appointment_date_end: apptDateEnd && formatToDateString(apptDateEnd),
    first_name: firstName,
    last_name: lastName,
    email: email && encodeURIComponent(email),
    pms_patient_id: patientId,
    date_of_birth: dateOfBirth && formatToDateString(dateOfBirth),
    current_date: formatToDateString(moment().toISOString()),
    phone: phoneNumber,
  };

  let qParams = Utils.getQueryString(params);

  // Need to change logic in getQueryString as it is skipping 0 and false values
  if (currentPageNo === 0) {
    qParams += `&offset=0`;
  }

  return {
    params,
    qParams,
  };
};
