import { createSlice } from '@reduxjs/toolkit';
import getTransactionSliceData from './transactionSliceData';
import getTransactionSliceReducers from './transactionSliceReducers';
import { RootState } from '../storeRoot';
import ITransactionSliceData from 'Views/FlexPayV2/types/store/transactionSlice/ITransactionSliceData';

const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState: getTransactionSliceData(),
  reducers: {
    ...getTransactionSliceReducers(),
  },
});

export const {
  setLoading,
  setCurrentPage,
  setStatsMap,
  setFilterMap,
  setPaymentsIdMap,
  setPaginationConfigs,
  resetFilterMap,
  resetTransactionStore,
  setExportLoading,
  setTransfersIdMap,
} = transactionSlice.actions;

// Exporting a utility function to access this slice easily :
export const getTransactionSlice = (state: RootState) => state.transaction;

// Getting the filter map as per the current page :
export const getTransactionFilterMap = (
  state: RootState,
):
  | ITransactionSliceData['filterMap']
  | ITransactionSliceData['transfersFilterMap'] => {
  const { currentPage, filterMap, transfersFilterMap } =
    getTransactionSlice(state);
  if (currentPage === 'payments') {
    return filterMap;
  } else {
    return transfersFilterMap;
  }
};

export default transactionSlice.reducer;
