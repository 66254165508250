import IInvoicePaySliceData from 'Views/FlexPayV2/types/store/invoicePaySlice/invoicePaySliceData';

const getInvoiceSliceData = (): IInvoicePaySliceData => ({
  locationSettingsLoading: true,
  locationSettings: {
    location_id: '',
    location_name: '',
    logo_url: '',
    signed_logo_url: '',
    group_id: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipcode: '',
    },
  },
  invoiceInfoLoading: true,
  invoiceInfo: {
    invoice_type: '',
    id: '',
    display_id: '',
    location_id: '',
    location_name: '',
    group_id: '',
    amount: 0,
    processing_fees: 0,
    refunded_amount: 0,
    currency_code: '',
    status: '',
    cancellation_desc: '',
    cancellation_reason: '',
    due_at: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    same_as_patient_info: false,
    terms_accepted: false,
    patient: {
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      date_of_birth: '',
    },
    billing_detail: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address_line1: '',
      city: '',
      state: '',
      country_code: '',
      postal_code: '',
    },
    mailing_address: {
      address_line1: '',
      city: '',
      state: '',
      postal_code: '',
      id: '',
    },
    line_items: [],
    adjustment_types: [],
    payments: [
      {
        id: '',
        paid_at: '',
        invoice_id: '',
        amount: 0,
        processing_fees: 0,
        payment_status: '',
        payment_type: '',
        payment_method: {
          id: '',
          payment_method_funding: '',
          payment_method_name: '',
          payment_method_last4: '',
        },
        payment_refunds: [],
      },
    ],
  },

  // UI State
  verifyLoading: false,
  accessVerified: false,
  invoicePayment: {
    type: '',
    cardType: '',
    error: false,
    complete: false,
  },
  isHSAFSACardType: false,
  submitLoading: false,
  intentDetails: {
    client_secret: '',
  },
});

export default getInvoiceSliceData;
