import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import ReactPhoneInput from 'react-phone-input-2';

import { InputRef } from 'components';
import 'react-phone-input-2/lib/material.css';
import styles from './PhoneInputstyles.module.scss';

const PhoneInputField = (props) => {
  const {
    label = 'Phone',
    error,
    helperText,
    onBlur,
    required = true,
    ...rest
  } = props;

  return (
    <TextField
      fullWidth
      required={required}
      label={label}
      helperText={helperText}
      error={error}
      onBlur={onBlur}
      variant="outlined"
      disabled={rest?.disabled || false}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputRef,
        inputProps: {
          ...rest,
          placeholder: '',
          component: ReactPhoneInput,
          specialLabel: '',
          country: 'us',
          onlyCountries: ['us'],
          containerClass: styles.containers,
          inputClass: styles.phoneInput,
          countryCodeEditable: false,
          disableDropdown: true,
          inputProps: {
            id: rest?.id,
            name: rest?.name,
            'data-testid': `${rest?.id || 'phone-input-field'}`,
          },
        },
      }}
    />
  );
};

PhoneInputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  countryCodeEditable: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PhoneInputField;
