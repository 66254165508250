import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import globalSliceReducer from './globalSlice/globalSlice';
import invoiceSliceReducer from './invoiceSlice/invoiceSlice';
import invoiceInfoSliceReducer from './invoiceInfoSlice/invoiceInfoSlice';
import invoiceCrudSliceReducer from './invoiceCrudSlice/invoiceCrudSlice';
import invoicePaySliceReducer from './invoicePaySlice/invoicePaySlice';
import patientListSliceReduce from './patientListSlice/patientListSlice';
import patientInfoSliceReduce from './patientInfoSlice/patientInfoSlice';
import dashboardSliceReducer from './dashboardSlice/dashboardSlice';
import transactionSliceReducer from './transactionSlice/transactionSlice';

export const store = configureStore({
  reducer: {
    global: globalSliceReducer,
    invoiceList: invoiceSliceReducer,
    invoiceInfo: invoiceInfoSliceReducer,
    invoiceCrud: invoiceCrudSliceReducer,
    invoicePay: invoicePaySliceReducer,
    patientList: patientListSliceReduce,
    patientInfo: patientInfoSliceReduce,
    dashboard: dashboardSliceReducer,
    transaction: transactionSliceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () =>
  useDispatch<AppDispatch & ThunkDispatch<any, any, any>>();

// Define a typed version of useSelector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Utility to read from method even in normal method :
// Use this method to access the value in non component place :
// Accept the slice and return the complete slice :
export const getSlice = (sliceName: keyof RootState) => {
  return store.getState()[sliceName];
};

// More depth utility method :
// defaultly use the globalSlice:

// return the current group id :
export const getCurrentGroupId = () => {
  return store.getState().global.groupId;
};

// return the current location id
export const getCurrentLocationId = () => {
  return (
    store.getState().invoiceCrud.invoiceVO.location_id ||
    store.getState().global.locationId
  );
};
