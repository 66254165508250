import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import { EXT_PLAN_FREQUENCIES } from 'global-constants/constants';
import { CPT_DISPLAY_LIST } from 'Views/Common/enum';
import { PlansContext } from 'Views/Plans/context';
import {
  CPT_UPDATED,
  UPDATE_FIELDS_VALIDITY,
} from 'Views/Plans/context/actions';
import { SectionHeader, Select } from 'components';
import styles from './CPTValues.module.scss';

const CPTValues = () => {
  const {
    state: {
      productInfo = {},
      cpt_codes,
      default_cpt_value,
      isValid,
      highlightErrors,
      plan_id,
      has_subscriptions,
      selectedGroup,
    },
    dispatch,
  } = useContext(PlansContext);
  const vet_only = selectedGroup.industry === 'Veterinary';
  const addonPlan = productInfo?.plan_package_type === 'Addon';

  useEffect(() => {
    if (!plan_id || (addonPlan && !has_subscriptions && cpt_codes.length === 0))
      dispatchCPTValues([default_cpt_value]);
  }, [dispatch]);

  const checkCptValidity = (code) =>
    Object.keys(code).some(
      (key) => key !== 'duplicate_code' && !code?.[key]?.toString().length,
    );

  useEffect(() => {
    let isFieldsError = false;

    if (cpt_codes.length === 1) {
      /* One CPT row & changes made */
      if (!_.isEqual(cpt_codes?.[0], default_cpt_value)) {
        isFieldsError = checkCptValidity(cpt_codes?.[0]);
      }
    } else {
      /* Multiple CPT rows & has all values */
      isFieldsError = cpt_codes.some((code) => checkCptValidity(code));
    }

    dispatch({
      type: UPDATE_FIELDS_VALIDITY,
      payload: {
        isValid: { ...isValid, cpt: !isFieldsError },
      },
    });
  }, [highlightErrors, cpt_codes]);

  const dispatchCPTValues = (updated_cpt_codes) =>
    dispatch({
      type: CPT_UPDATED,
      payload: { cpt_codes: updated_cpt_codes },
    });

  const addCPTCodes = () =>
    dispatchCPTValues(
      cpt_codes.concat([
        {
          ...default_cpt_value,
          list: cpt_codes.length
            ? cpt_codes[cpt_codes.length - 1]?.list + 1
            : 1,
        },
      ]),
    );

  const addToList = (index) => {
    const newCodes = _.cloneDeep(cpt_codes);
    const selectedCode = newCodes[index];
    const { list, frequency, quantity, total_unit, pdt_name } = selectedCode;

    let overrideValues = {
      quantity,
      total_unit,
    };

    if (frequency === 'unlimited')
      overrideValues = {
        quantity: 'inf',
        total_unit: 'inf',
      };

    newCodes.splice(index + 1, 0, {
      ...default_cpt_value,
      list,
      frequency,
      pdt_name,
      ...overrideValues,
    });

    dispatchCPTValues(newCodes);
  };

  const deleteCPTCodes = (rowIndex, deletedList) => {
    const shouldUpdateLists =
      cpt_codes.filter((item) => item.list === deletedList).length === 1;

    dispatchCPTValues(
      cpt_codes
        .filter((_code, index) => index !== rowIndex)
        .map(({ list, ...rest }) => ({
          ...rest,
          list: shouldUpdateLists && list > deletedList ? list - 1 : list,
        })),
    );
  };

  const updateValues = (name, value, rowIndex, shouldUpdateList, list) => {
    if (name === 'frequency' && value === 'unlimited') {
      dispatchCPTValues(
        cpt_codes.map((code, index) =>
          rowIndex === index || (shouldUpdateList && list === code.list)
            ? {
                ...code,
                [name]: value,
                quantity: 'inf',
                total_unit: 'inf',
              }
            : code,
        ),
      );

      return;
    } else if (name === 'frequency') {
      dispatchCPTValues(
        cpt_codes.map((code, index) =>
          rowIndex === index || (shouldUpdateList && list === code.list)
            ? {
                ...code,
                [name]: value,
                quantity: code?.quantity === 'inf' ? '' : code?.quantity || '',
                total_unit:
                  code?.total_unit === 'inf' ? '' : code?.total_unit || '',
              }
            : code,
        ),
      );

      return;
    }

    dispatchCPTValues(
      cpt_codes.map((code, index) =>
        rowIndex === index || (shouldUpdateList && list === code.list)
          ? {
              ...code,
              [name]: value,
            }
          : code,
      ),
    );
  };

  const getValue = ({ target: { name, type, value } }) => {
    const allowDecimals =
      name === 'retail_price' || name === 'subscription_price';

    if (type === 'number') {
      let parsedValue;

      /* Allow decimal values for Retail and Subscription price */
      if (allowDecimals)
        parsedValue = Math.abs(parseFloat(+value.replace(/^[/D.]/gi, '')));
      else parsedValue = Math.abs(parseFloat(+value.replace(/^[/D]/gi, '')));

      return isNaN(parsedValue) ? 0 : parsedValue;
    }

    return value;
  };

  const handleFocus = (event) => event.target.select();

  const commonCellHeaderProps = (className) => () => ({ className });

  const tableNumberFieldProps = {
    inputProps: {
      type: 'number',
      onWheel: (event) => event.target.blur()
    },
    onFocus: handleFocus,
  };

  const renderTableInput = (
    value,
    name,
    rowIndex,
    textFieldProps,
    shouldUpdateList,
    list,
    required = true,
  ) => (
    <TextField
      required
      variant="outlined"
      margin="dense"
      value={value}
      name={name}
      error={Boolean(
        required &&
          highlightErrors &&
          !isValid?.cpt &&
          !value &&
          !value?.toString?.length,
      )}
      onChange={(event) =>
        updateValues(
          event.target.name,
          getValue(event),
          rowIndex,
          shouldUpdateList,
          list,
        )
      }
      inputProps={{
        'data-testid': `cpt-${name}`,
      }}
      {...textFieldProps}
    />
  );

  const renderStatusSelect = (value, name, rowIndex) => (
    <FormControl fullWidth margin="dense" variant="outlined">
      <Select
        id="cpt-select-display"
        value={value}
        name={name}
        onChange={(event) =>
          updateValues(event.target.name, event.target.value, rowIndex)
        }
      >
        {Object.keys(CPT_DISPLAY_LIST).map((item, itemIndex) => (
          <MenuItem
            key={itemIndex}
            value={item}
            data-testid={`cpt-display-select-item-${itemIndex + 1}`}
          >
            {CPT_DISPLAY_LIST[item]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const tableColumns = [
    {
      name: 'list',
      label: 'List',
    },
    {
      name: 'pdt_name',
      label: 'Category *',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.longColumn),
        customBodyRender: (value, { rowIndex, rowData }) => {
          const isSameList = Boolean(
            rowIndex && cpt_codes[rowIndex - 1].list === rowData[0],
          );

          return (
            !isSameList &&
            renderTableInput(
              value,
              'pdt_name',
              rowIndex,
              {
                className: styles.longColumn,
              },
              true,
              rowData[0],
            )
          );
        },
      },
    },
    {
      name: 'standard_code',
      label: 'Standard Code *',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.mediumColumn),
        customBodyRender: (value, { rowIndex }) =>
          renderTableInput(value, 'standard_code', rowIndex, {
            className: styles.mediumColumn,
          }),
        display: vet_only,
      },
    },
    {
      name: 'code',
      label: 'Code *',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.mediumColumn),
        customBodyRender: (value, { rowIndex }) =>
          renderTableInput(value, 'code', rowIndex, {
            className: styles.mediumColumn,
          }),
      },
    },
    {
      name: 'description',
      label: 'Description *',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.longColumn),
        customBodyRender: (value, { rowIndex }) =>
          renderTableInput(value, 'description', rowIndex, {
            className: styles.longColumn,
          }),
      },
    },
    {
      name: 'retail_price',
      label: 'Retail Price',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.inputColumn),
        customBodyRender: (value, { rowIndex }) =>
          renderTableInput(value, 'retail_price', rowIndex, {
            className: styles.mediumColumn,
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
            ...tableNumberFieldProps,
          }),
      },
    },
    {
      name: 'subscription_price',
      label: 'Subscription Price',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.inputColumn),
        customBodyRender: (value, { rowIndex }) =>
          renderTableInput(value, 'subscription_price', rowIndex, {
            className: styles.mediumColumn,
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
            ...tableNumberFieldProps,
            disabled: has_subscriptions,
          }),
      },
    },
    {
      name: 'frequency',
      label: 'Frequency *',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.mediumColumn),
        customBodyRender: (value, { rowIndex, rowData }) => {
          const isSameList = Boolean(
            rowIndex && cpt_codes[rowIndex - 1].list === rowData[0],
          );

          return (
            !isSameList && (
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                disabled={has_subscriptions}
              >
                <Select
                  id="cpt-select-frequency"
                  value={value}
                  name="frequency"
                  onChange={(event) =>
                    updateValues(
                      event.target.name,
                      event.target.value,
                      rowIndex,
                      true,
                      rowData[0],
                    )
                  }
                >
                  {Object.keys(EXT_PLAN_FREQUENCIES).map((item, itemIndex) => (
                    <MenuItem
                      key={`cpt-frequency-select-item-${itemIndex + 1}`}
                      value={item}
                      data-testid={`cpt-frequency-select-item-${itemIndex}`}
                    >
                      {' '}
                      {EXT_PLAN_FREQUENCIES[item]}{' '}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          );
        },
      },
    },
    {
      name: 'quantity',
      label: 'Allowed Units *',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.inputColumn),
        customBodyRender: (value, { rowIndex, rowData }) => {
          const isSameList = Boolean(
            rowIndex && cpt_codes[rowIndex - 1].list === rowData[0],
          );

          return (
            !isSameList &&
            renderTableInput(
              value,
              'quantity',
              rowIndex,
              {
                ...tableNumberFieldProps,
                className: styles.inputColumn,
                disabled:
                  has_subscriptions ||
                  cpt_codes[rowIndex]?.frequency === 'unlimited',
              },
              true,
              rowData[0],
            )
          );
        },
      },
    },
    {
      name: 'total_unit',
      label: 'Total Units *',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.inputColumn),
        customBodyRender: (value, { rowIndex, rowData }) => {
          const isSameList = Boolean(
            rowIndex && cpt_codes[rowIndex - 1].list === rowData[0],
          );

          return (
            !isSameList &&
            renderTableInput(
              value,
              'total_unit',
              rowIndex,
              {
                ...tableNumberFieldProps,
                className: styles.inputColumn,
                disabled:
                  has_subscriptions ||
                  cpt_codes[rowIndex]?.frequency === 'unlimited',
              },
              true,
              rowData[0],
            )
          );
        },
      },
    },
    {
      name: 'cpt_display',
      label: 'Display *',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.inputColumn),
        customBodyRender: (value, { rowIndex }) =>
          renderStatusSelect(value, 'cpt_display', rowIndex),
      },
    },
    {
      name: '',
      label: ' ',
      options: {
        setCellHeaderProps: commonCellHeaderProps(styles.iconColumn),
        display: !has_subscriptions,
        customBodyRender: (value, tableMetaData) => {
          const { rowIndex, rowData } = tableMetaData;

          return (
            <div className="d-flex flex-no-wrap">
              <IconButton
                color="primary"
                title="Add to List"
                aria-label="add-to-list"
                onClick={() => addToList(rowIndex)}
                data-testid={`add-to-list-button-${rowIndex}`}
              >
                <AddRoundedIcon fontSize="inherit" />
              </IconButton>

              {rowIndex > 0 && (
                <IconButton
                  aria-label="delete"
                  onClick={() => deleteCPTCodes(rowIndex, rowData[0])}
                  data-testid={`delete-cpt-button-${rowIndex}`}
                  classes={{
                    root: styles.deleteIconColor,
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    sort: false,
    selectableRows: 'none',
    responsive: 'standard',
    print: false,
    pagination: false,
    search: false,
    download: false,
    viewColumns: false,
    elevation: 0,
  };

  return (
    <>
      <SectionHeader
        title="Procedure Codes"
        gutterBottom
        titleVariant="h5"
        titleComponent="h5"
        rightSection={
          !has_subscriptions && (
            <Button
              variant="text"
              disableElevation
              color="secondary"
              onClick={addCPTCodes}
              startIcon={<AddRoundedIcon />}
              data-testid="add-product"
            >
              Add Product
            </Button>
          )
        }
      />
      <Divider />
      <div id="cpt-codes-table">
        <MUIDataTable
          data={cpt_codes}
          columns={tableColumns}
          options={options}
        />
      </div>

      {Boolean(highlightErrors && !isValid.cpt) && (
        <Typography className={styles.errorText} color="error" align="right">
          * Please fill the required fields
        </Typography>
      )}
    </>
  );
};

export default CPTValues;
