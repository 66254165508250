import IInvoiceSliceReducers from 'Views/FlexPayV2/types/store/invoiceSlice/invoiceSliceReducers';

// Note: in each action one data should be updated, not multiple

const getInvoiceSliceReducers = (): IInvoiceSliceReducers => ({
  setInvoiceLoading: (state, action) => {
    state.invoiceLoading = action.payload;
  },
  setInvoiceVOMap(state, { payload: invoiceVOMap }) {
    state.invoiceVOMap = invoiceVOMap;
  },
  setInvoiceStats: (state, action) => {
    state.invoiceStats = action.payload;
  },
  // filter vo should not be partial, it should be complete
  setInvoiceFilter: (state, { payload: newFilterVO }) => {
    state.invoiceFilter = newFilterVO;
  },
  setInvoiceTablePagination: (state, action) => {
    state.invoiceTablePagination = action.payload;
  },
  setInvoiceTableSorting: (state, action) => {
    state.invoiceTableSort = action.payload;
  },
  setExportLoading: (state, action) => {
    state.exportLoading = action.payload;
  },
  setActionLoading(state, { payload }) {
    state.actionLoading = {
      ...state.actionLoading,
      ...payload,
    };
  },
});

export default getInvoiceSliceReducers;
