import React, { useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { SectionHeader } from 'components';
import LayoutView from 'Views/Common/LayoutView';
import {
  AssociatedLocation,
  AssociatedPartner,
  AssociatedPlan,
  AssociatedRegion,
  ContactDetails,
  CommunicationTemplates,
  Details,
  OnboardingDetails,
  UserManagement,
  Capabilities,
  ReportCapabilities,
  Documents,
  AssociatedGroup,
  LogoDetails,
  UploadMarketingMaterial,
  UcrFeesSchedule,
  PaymentCapabilities,
} from './components';
import ConfirmModal from 'Views/Common/ConfirmModal';
import { USER_SERVICES } from 'Services';
import { Context } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';

const VIEWS = {
  PARTNER: 'partner',
  GROUP: 'group',
  REGION: 'region',
  LOCATION: 'location',
};

const Info = (props) => {
  const {
    name,
    keyName,
    infoDetail,
    capabilities,
    reportCapabilities,
    createPlan: enableCreatePlan = true,
    autolinking,
    patientSearch,
    showEmployeeSupport,
    showAutoAssignPlan = false,
    showAllowGetAddons = false,
    showAllowFacility = false,
    showSeparateLocation = false,
    feesSchedule = false,
    documents,
    groupList,
    regionList,
    locationList,
    plansLoading,
    planList,
    data = {},
    users,
    onUpdate,
  } = props;

  const { dispatch } = useContext(Context);

  const showGroups = [VIEWS.PARTNER].includes(keyName);
  const showRegions = [VIEWS.GROUP, VIEWS.PARTNER].includes(keyName);
  const showLocations = [VIEWS.GROUP, VIEWS.PARTNER, VIEWS.REGION].includes(
    keyName,
  );
  const showPlans = [VIEWS.GROUP, VIEWS.LOCATION].includes(keyName);
  const showEmails = [VIEWS.GROUP].includes(keyName);
  const showMarketingMaterials = [VIEWS.GROUP].includes(keyName);
  const showPayments = [VIEWS.GROUP].includes(keyName);
  const showPmsIntegration = [VIEWS.LOCATION].includes(keyName);
  const showDuplicateAppts = [VIEWS.LOCATION].includes(keyName);
  const showPatientConsentForm = [VIEWS.GROUP].includes(keyName);

  const { partner_id, group_id, region_id, location_id } = infoDetail;
  const hierarchyDetails = {
    name,
    partner_id,
    group_id,
    region_id,
    location_id,
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [isResetLoading, setResetLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openConfirmPopup = () => setIsPopupOpen(true);
  const closeConfirmPopup = () => setIsPopupOpen(false);

  const onResetPasswordClick = (user) => {
    setUserData(user);

    openConfirmPopup();
  };

  const onResetClick = async () => {
    if (isResetLoading) return;

    setResetLoading(true);

    try {
      const { cognito_username = '' } = userData;

      const response = await USER_SERVICES.resetDefaultPassword(
        cognito_username,
      );

      if (response?.type === 'success') {
        closeConfirmPopup();

        setGlobalNotification('success', 'Password reset successfully');
      } else {
        throw response?.message || response?.error;
      }
    } catch (err) {
      setGlobalNotification(
        'error',
        err || 'Error while reseting the password',
      );
    }
    setResetLoading(false);
  };

  const setGlobalNotification = (severity, message) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message,
        },
      },
    });

  const saveSettingsData = async (type = '', settingsData = {}) => {
    setIsLoading(type);

    const levelPayload = {
      [data?.settings?.setting_level]: {
        location_id,
        region_id,
        group_id,
        partner_id,
      },
    };

    try {
      const payload = { ...levelPayload, setting: settingsData };

      const response = await USER_SERVICES.updateLevels(
        payload,
        data?.settings?.setting_level,
      );

      if (response?.type === 'success') {
        setGlobalNotification(
          'success',
          'Report Capability updated successfully',
        );

        onUpdate?.('Settings');
      } else {
        throw response?.message || response?.error;
      }
    } catch (error) {
      setGlobalNotification(
        'error',
        error || 'Error updating the capability details',
      );
    }

    setIsLoading(false);
  };

  const handleSwitchChange = async (event) => {
    const { name: eName, checked } = event.target;

    const { additional_report = {} } = data?.settings || {};

    let settingsData = {
      additional_report: {
        ...additional_report,
        [eName]: checked,
      },
    };

    saveSettingsData(eName, settingsData);
  };

  return (
    <>
      <LayoutView.DocumentTitle title={`${name} Info`} />

      <SectionHeader
        title={`${name} Info`}
        titleVariant="h4"
        titleComponent="h4"
        gutterBottom
      />

      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <AssociatedPartner
            infoDetail={infoDetail}
            accountDetails={data?.account}
          />
        </Grid>

        <Grid item xs={12}>
          <Details name={name} data={data} onUpdate={onUpdate} />
        </Grid>

        {Boolean(documents) && (
          <Grid item xs={12}>
            <LogoDetails
              levelData={data?.level_data}
              settings={data?.settings}
              onUpdate={onUpdate}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <ContactDetails
            name={name}
            subHeading="This contact will be used as the Stripe contact and will also receive notifications and reports"
            contactMembers={data?.level_data?.contact_members || []}
            level_data={data?.level_data}
            settings={data?.settings}
            onUpdate={onUpdate}
          />
        </Grid>

        {showMarketingMaterials && (
          <Grid item xs={12}>
            <UploadMarketingMaterial
              group_id={group_id}
              data={data?.level_data?.materials || []}
              onUpdate={() => onUpdate('Settings')}
            />
          </Grid>
        )}

        {showEmails && (
          <Grid item xs={12}>
            <CommunicationTemplates
              event_payload={data?.level_data?.comms?.email_templates || []}
              group_id={group_id}
              onUpdate={onUpdate}
            />
          </Grid>
        )}

        {data?.account?.payouts_enabled && (
          <Grid item xs={12}>
            <OnboardingDetails name={name} details={data} onUpdate={onUpdate} />
          </Grid>
        )}

        {Boolean(capabilities) && (
          <Grid item xs={12}>
            <Capabilities
              name={name}
              levelData={data?.level_data}
              settings={data?.settings}
              onUpdate={() => onUpdate('Settings')}
              autolinking={autolinking}
              patientSearch={patientSearch}
              showEmployeeSupport={showEmployeeSupport}
              showPmsIntegration={showPmsIntegration}
              showDuplicateAppts={showDuplicateAppts}
              showAutoAssignPlan={showAutoAssignPlan}
              showAllowGetAddons={showAllowGetAddons}
              showAllowFacility={showAllowFacility}
              showSeparateLocation={showSeparateLocation}
              showPatientConsent={showPatientConsentForm}
            />
          </Grid>
        )}

        {Boolean(showPayments) && (
          <Grid item xs={12} className='position-relative'>
            <PaymentCapabilities
              levelData={data?.level_data}
              settings={data?.settings}
              onUpdate={() => onUpdate('Settings')}
            />
          </Grid>
        )}

        {Boolean(reportCapabilities) && (
          <Grid item xs={12}>
            <ReportCapabilities
              type={'info'}
              data={data?.settings?.additional_report}
              handleChange={handleSwitchChange}
              isLoading={isLoading}
            />
          </Grid>
        )}

        {Boolean(documents) && (
          <Grid item xs={12}>
            <Documents
              levelData={data?.level_data}
              settings={data?.settings}
              onUpdate={onUpdate}
            />
          </Grid>
        )}
        {Boolean(feesSchedule) && (
          <Grid item xs={12}>
            <UcrFeesSchedule
              levelData={data?.level_data}
              settings={data?.settings}
              onUpdate={onUpdate}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <UserManagement
            users={users}
            onUpdate={() => onUpdate('User')}
            handleResetPassword={onResetPasswordClick}
          />
        </Grid>

        {showGroups && (
          <Grid item xs={12}>
            <AssociatedGroup
              hierarchyDetails={hierarchyDetails}
              groupList={groupList}
              isCreationEnabled={true}
            />
          </Grid>
        )}

        {showRegions && (
          <Grid item xs={12}>
            <AssociatedRegion
              hierarchyDetails={hierarchyDetails}
              regionList={regionList}
              isCreationEnabled={true}
            />
          </Grid>
        )}

        {showLocations && (
          <Grid item xs={12}>
            <AssociatedLocation
              hierarchyDetails={hierarchyDetails}
              locationList={locationList}
              isCreationEnabled={true}
            />
          </Grid>
        )}

        {showPlans && (
          <Grid item xs={12}>
            <AssociatedPlan
              hierarchyDetails={hierarchyDetails}
              plansLoading={plansLoading}
              planList={planList}
              isCreationEnabled={enableCreatePlan}
              onUpdate={() => onUpdate('Plans')}
            />
          </Grid>
        )}

        <ConfirmModal
          isOpen={isPopupOpen}
          closeModal={closeConfirmPopup}
          emitConfirmEvent={onResetClick}
          isLoading={isResetLoading}
          options={{
            title: `Reset password`,
            description: (
              <>
                Are you sure you want to reset <br /> the password for{' '}
                {userData?.name}?
              </>
            ),
            confirmText: 'Confirm',
            cancelText: 'Cancel',
          }}
        />
      </Grid>
    </>
  );
};

Info.propTypes = {
  name: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  infoDetail: PropTypes.object,
  capabilities: PropTypes.bool,
  reportCapabilities: PropTypes.bool,
  createPlan: PropTypes.bool,
  viewPlan: PropTypes.bool,
  autolinking: PropTypes.bool,
  patientSearch: PropTypes.bool,
  showEmployeeSupport: PropTypes.bool,
  showAutoAssignPlan: PropTypes.bool,
  showAllowGetAddons: PropTypes.bool,
  showAllowFacility: PropTypes.bool,
  showSeparateLocation: PropTypes.bool,
  documents: PropTypes.bool,
  feesSchedule: PropTypes.bool,
  groupList: PropTypes.array,
  regionList: PropTypes.array,
  locationList: PropTypes.array,
  plansLoading: PropTypes.bool,
  planList: PropTypes.array,
  data: PropTypes.object,
  users: PropTypes.array,
  onUpdate: PropTypes.func,
};

export default Info;
