import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CommonDialog from 'components/CommonDialog';
import {
  Grid,
  FormControl,
  FormHelperText,
  Typography,
  TextField,
  Divider,
} from '@material-ui/core';
import DatePickers from 'Views/Common/DatePickers';
import { SubscriberContext } from 'Views/Subscriber/context';
import { Context } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';
import { SUBSCRIBER_SERVICES } from 'Services';
import Utils, { getDateError } from 'Shared/Utils';
import { formatCurrency, formatToDateString } from 'utils';
import { MINOR_AGE } from 'Views/Common/enum';
import styles from './ChangeRoleModal.module.scss';

const ChangeRoleModal = (props) => {
  const { closePopup, data, subscriberInfo, onUpdate } = props;
  const { subscription_id = '' } = subscriberInfo;
  const {
    patient_id = '',
    date_of_birth: subscriberDOB = null,
    patient_name: { first_name: SubscriberName = '' },
  } = data;

  const { state: { communicationDetails = {} } = {} } =
    useContext(SubscriberContext);
  const { dispatch } = useContext(Context);
  const { values: respMemDetailsFromContext = {} } = communicationDetails;

  const [loading, setLoading] = useState(false);
  const [isMinor, setIsMinor] = useState(false);
  const [nextInvoiceData, setNextInvoiceData] = useState();
  const [validate, setValidate] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [respMemDetails, setRespMemDetails] = useState({});

  const { is_invoice_changed = false, next_invoice = {} } =
    nextInvoiceData || {};
  const nextInvoiceAmount = next_invoice?.amount_due || '';

  const checkForErrors =
    !respMemDetails?.relationDOB ||
    dateError ||
    !respMemDetails?.member ||
    !respMemDetails?.relationship;

  useEffect(() => {
    handleChangeRole(true);
  }, []);

  useEffect(() => {
    if (Object.keys(respMemDetailsFromContext)?.length) {
      setRespMemDetails(respMemDetailsFromContext);
    }
  }, [respMemDetailsFromContext]);

  const renderDateField = (name, label, value) => (
    <FormControl variant="outlined" fullWidth>
      <DatePickers
        required
        inputVariant="outlined"
        label={label}
        format="MM/dd/yyyy"
        placeholder="MM/DD/YYYY"
        views={['date', 'month', 'year']}
        disableFuture
        invalidDateMessage=""
        maxDate={moment().subtract(MINOR_AGE, 'years')}
        minDateMessage=""
        maxDateMessage=""
        name={name}
        value={value}
        onChange={(value) => handleDateChange(value, name)}
        KeyboardButtonProps={{
          'data-testid': 'communication-details-change-role-date-picker-button',
          'aria-label': 'change date',
        }}
        inputProps={{
          'data-testid': 'communication-details-change-roledate-picker',
        }}
        error={(!respMemDetails?.relationDOB || dateError) && validate}
      />
      {dateError && (
        <FormHelperText className="color_error">{dateError}</FormHelperText>
      )}
    </FormControl>
  );

  const renderHelperText = (attribute) => {
    switch (attribute) {
      case respMemDetails?.relationship:
        return 'Relationship is required';
      case respMemDetails?.relationDOB:
        return 'Date of birth is required';
      case respMemDetails?.member:
        return 'Representative name is required';
      default:
        return '';
    }
  };

  const handleDateChange = (value, name) => {
    const exceedsMinDate = moment(value).isAfter(
      moment().subtract(MINOR_AGE, 'years'),
    );
    const isDateError = getDateError(value);
    if (isDateError) {
      setDateError(isDateError);
    } else if (exceedsMinDate) {
      setDateError(`Personal Representative cannot be below of ${MINOR_AGE}`);
    } else {
      setDateError(false);
    }
    handleInputChange(value, name);
  };

  const setGlobalNotification = (severity, message) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message,
        },
      },
    });

  const handleChangeRole = async (checkout = true) => {
    try {
      setLoading(true);
      const Adult = {
        patient_id,
        checkout: checkout,
      };

      const Minor = {
        ...Adult,
        responsible_member: {
          full_name: respMemDetails.member || '',
          relationship: respMemDetails.relationship || '',
          date_of_birth: formatToDateString(
            moment(respMemDetails?.relationDOB),
          ),
          address: {
            line1: respMemDetails.addressline || '',
            city: respMemDetails.city || '',
            state: respMemDetails.state || '',
            zipcode: respMemDetails.zipcode || '',
          },
        },
      };

      const payload = isMinor ? Minor : Adult;
      const response = await SUBSCRIBER_SERVICES.changePrimarySubscriber(
        subscription_id,
        payload,
      );

      if (response?.type === 'success') {
        if (response?.data && checkout) {
          setNextInvoiceData(response.data);
        }
        if (!checkout) {
          setGlobalNotification(
            'success',
            `Your request to make ${SubscriberName} as primary subscriber is successfully completed`,
          );
          onUpdate();
        }
      }
    } catch (err) {
      setGlobalNotification(
        'error',
        err ||
          'error while processing your request please try again in sometimes',
      );
    } finally {
      setLoading(false);
    }
  };

  const renderRespForm = () => (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label="Representative Name"
          name="full_name"
          value={respMemDetails?.member || ''}
          onChange={(e) => handleInputChange(e, 'member')}
          inputProps={{
            'data-testid': 'responsible-full-name',
          }}
          helperText={
            validate
              ? !respMemDetails?.member &&
                renderHelperText(respMemDetails?.member)
              : ''
          }
          error={!respMemDetails?.member && validate}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        {renderDateField(
          'relationDOB',
          'Date of Birth',
          respMemDetails?.relationDOB || null,
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label="Relationship"
          name="relationship"
          value={respMemDetails?.relationship || ''}
          onChange={(e) => handleInputChange(e, 'relationship')}
          inputProps={{
            'data-testid': 'responsible-relationship',
          }}
          helperText={
            validate
              ? !respMemDetails?.relationship &&
                renderHelperText(respMemDetails?.relationship)
              : ''
          }
          error={!respMemDetails?.relationship && validate}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );

  const handleInputChange = (e, field) => {
    let value = e?.target?.value || '';

    if (field === 'relationDOB') {
      value = e;
    }

    setRespMemDetails((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (subscriberDOB) {
      const ageCal = Utils._calculateAge(subscriberDOB);
      setIsMinor(ageCal < MINOR_AGE);
    }
  }, [subscriberDOB]);

  return (
    <CommonDialog
      open={true}
      handleClose={closePopup}
      paperClass={styles.changeRoleModal}
      dialogTitle="Confirm Subscriber Role Update"
      primaryBtnTxt="Update"
      handlePrimaryButtonClick={() => {
        setValidate(true);
        handleChangeRole(false);
      }}
      disabledPrimaryButton={(isMinor && checkForErrors) || loading}
      secondaryBtnTxt="Cancel"
      isLoading={loading}
    >
      {isMinor && renderRespForm()}
      {isMinor ? (
        <Typography variant="h7">
          <br />
          Effective immediately, {SubscriberName} has been designated as the
          primary member.
        </Typography>
      ) : (
        <Typography variant="h7">
          {SubscriberName} has now been designated as the primary member. This
          change is effective immediately.
        </Typography>
      )}
      <br />
      <br />
      {is_invoice_changed && (
        <Typography variant="h7">
          Next invoice amount will be{' '}
          <span className={styles.amount}>
            {formatCurrency(nextInvoiceAmount / 100)}
          </span>
        </Typography>
      )}
    </CommonDialog>
  );
};

ChangeRoleModal.propTypes = {
  closePopup: PropTypes.func,
  data: PropTypes.object,
  subscriberInfo: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default ChangeRoleModal;
