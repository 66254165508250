import { useMemo } from 'react';

import { InputAdornment } from '@material-ui/core';

// Components
import { RoleValidator } from 'components';
import Dropdown from '../../../common-components/Dropdown/Dropdown';

// Icons
import { ReactComponent as GroupIcon } from 'assets/icons/flexpay/group.svg';

// Constants
import {
  PARTNER_ADMIN,
  PARTNER_USER,
  SUBSCRIBILI_USER,
  SUPER_ADMIN,
} from 'global-constants/roles';

// Store
import {
  useAppDispatch,
  useAppSelector,
} from 'Views/FlexPayV2/store/storeRoot';
import { changeGroupInVoiceThunk } from 'Views/FlexPayV2/store/invoiceSlice/invoiceSliceThunks';

const InVoiceGroupSelector = () => {
  return (
    <RoleValidator
      roles={[SUPER_ADMIN, SUBSCRIBILI_USER, PARTNER_ADMIN, PARTNER_USER]}
    >
      <InVoiceGroupSelectorRenderer />
    </RoleValidator>
  );
};

const InVoiceGroupSelectorRenderer = () => {
  const groupVOMap = useAppSelector((state) => state.global.groupVOMap);
  const groupList = useMemo(() => Object.values(groupVOMap), [groupVOMap]);
  const dispatch = useAppDispatch();

  const selectedGroupId = useAppSelector((state) => state.global.groupId);

  const handleSelectGroup = (_, groupId: string) =>
    dispatch(changeGroupInVoiceThunk(groupId));

  return (
    <Dropdown
      name="group_id"
      label=""
      value={selectedGroupId}
      list={groupList}
      listName="group_name"
      onChange={handleSelectGroup}
      loading={false}
      disabled={false}
      startAdornment={
        <InputAdornment position="start">
          <GroupIcon />
        </InputAdornment>
      }
    />
  );
};

export default InVoiceGroupSelector;
