import { useMemo } from 'react';

import { useHistory } from 'react-router-dom';
import { InputAdornment } from '@material-ui/core';

// Components
import { RoleValidator } from 'components';
import Dropdown from '../../../common-components/Dropdown/Dropdown';

// Constants
import {
  SUPER_ADMIN,
  SUBSCRIBILI_USER,
  PARTNER_ADMIN,
  PARTNER_USER,
  GROUP_ADMIN,
  GROUP_USER,
  REGION_ADMIN,
  REGION_USER,
} from 'global-constants/roles';

// Icons
import { ReactComponent as LocationIcon } from 'assets/icons/flexpay/location.svg';

// Store
import {
  useAppDispatch,
  useAppSelector,
} from 'Views/FlexPayV2/store/storeRoot';
import { changeLocationInVoiceThunk } from 'Views/FlexPayV2/store/invoiceSlice/invoiceSliceThunks';

const InVoiceLocationSelector = () => {
  return (
    <RoleValidator
      roles={[
        SUPER_ADMIN,
        SUBSCRIBILI_USER,
        PARTNER_ADMIN,
        PARTNER_USER,
        GROUP_ADMIN,
        GROUP_USER,
        REGION_ADMIN,
        REGION_USER,
      ]}
    >
      <InVoiceLocationSelectorRenderer />
    </RoleValidator>
  );
};

const InVoiceLocationSelectorRenderer = () => {
  const locationVOMap = useAppSelector((state) => state.global.locationVOMap);
  const locationList = useMemo(
    () => Object.values(locationVOMap),
    [locationVOMap],
  );
  const locationLoading = useAppSelector(
    (state) => state.global.locationLoading,
  );
  const selectedGroupId = useAppSelector((state) => state.global.groupId);
  const selectedLocationId = useAppSelector((state) => state.global.locationId);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleLocationChange = (_name: string, value: string) =>
    dispatch(changeLocationInVoiceThunk({ locationId: value, history }));

  return (
    <Dropdown
      name="location_id"
      label=""
      value={selectedLocationId}
      list={locationList}
      listName="location_name"
      onChange={handleLocationChange}
      loading={locationLoading}
      disabled={!selectedGroupId || locationLoading}
      defaultLabel={'All Locations'}
      defaultValue={'ALL_LOCATIONS'}
      startAdornment={
        <InputAdornment position="start">
          <LocationIcon />
        </InputAdornment>
      }
    />
  );
};

export default InVoiceLocationSelector;
