import Utils from 'Shared/Utils';

export const checkResponse = (response) => {
  // status check happen here :
  if (!Utils.checkIfSuccess(response)) {
    // need to show the notification about the error
    window.setWindowNotification(
      'error',
      response?.message ||
        response?.error ||
        'An unexpected error occurred. Please try again later.',
    );
  }

  return response;
};
