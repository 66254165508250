import httpService from 'Api/http.service';
import { checkResponse } from './service.utils';

const { REACT_APP_FLEX_API_BASE_URL } = process.env;

const invoiceURL = `${REACT_APP_FLEX_API_BASE_URL}/v1/invoices`;
const notesURL = `${REACT_APP_FLEX_API_BASE_URL}/v1/notes`;

export const getInvoiceNotes = async (id: string) => {
  const response = await httpService({
    url: invoiceURL + `/${id}/notes`,
    method: 'get',
  });

  return checkResponse(response);
};

export const addNotesToInvoice = async (data: {
  invoice_id;
  note;
  visibility;
}) => {
  const response = await httpService({
    url: notesURL,
    method: 'post',
    data,
  });

  return checkResponse(response);
};

export const updateInvoiceNotes = async (
  noteId: string,
  data: { note; visibility },
) => {
  const response = await httpService({
    url: notesURL + `/${noteId}`,
    method: 'put',
    data,
  });

  return checkResponse(response);
};

export const deleteInvoiceNotes = async (noteId: string) => {
  const response = await httpService({
    url: notesURL + `/${noteId}`,
    method: 'delete',
  });

  return checkResponse(response);
};
