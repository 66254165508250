import IInvoicePaySliceReducers from 'Views/FlexPayV2/types/store/invoicePaySlice/invoicePaySliceReducers';

// Note: in each action one data should be updated, not multiple

const getInvoiceSliceReducers = (): IInvoicePaySliceReducers => ({
  setLocationSettingsLoading: (state, action) => {
    state.locationSettingsLoading = action.payload;
  },
  setLocationSettings: (state, action) => {
    state.locationSettings = action.payload;
  },
  setVerifyLoading: (state, action) => {
    state.verifyLoading = action.payload;
  },
  setAccessVerified: (state, action) => {
    state.accessVerified = action.payload;
  },
  setInvoiceInfoLoading: (state, action) => {
    state.invoiceInfoLoading = action.payload;
  },
  setInvoiceInfo: (state, action) => {
    state.invoiceInfo = action.payload;
  },
  setInvoicePayment: (state, action) => {
    state.invoicePayment = action.payload;
  },
  setHSAFSACardType(state, action) {
    state.isHSAFSACardType = action.payload;
  },
  setTermsAccepted: (state, { payload: termsAccepted }) => {
    state.invoiceInfo.terms_accepted = termsAccepted;
  },
  setSubmitLoading: (state, action) => {
    state.submitLoading = action.payload;
  },
  setIntentDetails: (state, action) => {
    state.intentDetails = action.payload;
  },
});

export default getInvoiceSliceReducers;
