import { createSlice } from '@reduxjs/toolkit';
import getGlobalSliceData from './globalSliceData';
import getGlobalSliceReducers from './globalSliceReducers';
import { RootState } from '../storeRoot';

const globalSlice = createSlice({
  name: 'global',
  initialState: getGlobalSliceData(),
  reducers: {
    ...getGlobalSliceReducers(),
  },
});

export const {
  setUserInfo,
  setPartnerId,
  setGroupId,
  setLocationId,
  setInitialGroupMap,
  setGlobalLoading,
  setInitialLocationMap,
  setLocationLoading,
} = globalSlice.actions;

// Utility function to access this slice easily :
export const getGlobalSlice = (state: RootState) => state.global;

export default globalSlice.reducer;
