import { createSlice } from '@reduxjs/toolkit';
import getInvoiceSliceData from './invoiceSliceData';
import getInvoiceSliceReducers from './invoiceSliceReducers';

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: getInvoiceSliceData(),
  reducers: {
    ...getInvoiceSliceReducers(),
  },
});

export const {
  setInvoiceLoading,
  setInvoiceVOMap,
  setInvoiceStats,
  setInvoiceFilter,
  setInvoiceTablePagination,
  setInvoiceTableSorting,
  setExportLoading,
  setActionLoading,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
