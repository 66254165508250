import httpService from 'Api/http.service';
import { checkResponse } from './service.utils';

const { REACT_APP_FLEX_API_BASE_URL } = process.env;
const groupURL = `${REACT_APP_FLEX_API_BASE_URL}/v1/groups`;

export const getGroups = async () => {
  const response = await httpService({
    url: groupURL,
    method: 'get',
  });

  return checkResponse(response);
};

export const getLocationsByGroup = async (groupId) => {
  const response = await httpService({
    url: groupURL + `/${groupId}/locations`,
    method: 'get',
  });

  return checkResponse(response);
};
