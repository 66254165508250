import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import {
  getGroups,
  getLocationsByGroup,
} from 'Views/FlexPayV2/invoices/services/groups.service';

// Utils
import Utils, {
  checkIfInternalUser,
  isLocationUser,
  isPartnerUser,
} from 'Shared/Utils';

// Types
import IGroupVO from 'Views/FlexPayV2/types/vo/IGroupVO';

// Store
import { RootState } from '../storeRoot';
import {
  setGlobalLoading,
  setGroupId,
  setInitialGroupMap,
  setInitialLocationMap,
  setLocationId,
  setLocationLoading,
  setPartnerId,
  setUserInfo,
} from './globalSlice';
// import {
//   fakeGroupList,
//   fakeLocationList,
// } from 'Views/FlexPayV2/types/store/globalSlice/IGlobalSliceData';

export const globalInitThunk = createAsyncThunk<
  void,
  any,
  { state: RootState }
>('global/initThunk', async (userInfo, { getState: gs, dispatch }) => {
  let {
    partner_id: partnerId = '',
    group_id: groupId = '',
    location_id: locationId = '',
  } = userInfo?.settings?.level_data || {};

  dispatch(setUserInfo(userInfo));
  dispatch(setGlobalLoading(true));

  if (isPartnerUser(userInfo) || checkIfInternalUser(userInfo)) {
    // fetch the group data and set the init group Id
    const groupResponse = await getGroups();

    // 2. set the group list in store
    if (!(Utils.checkIfSuccess(groupResponse) && groupResponse?.data?.length)) {
      dispatch(setGlobalLoading(false));
      return;
    }

    const groupList = groupResponse.data as IGroupVO[];
    // const groupList = fakeGroupList;
    groupId = groupList[0].group_id;
    partnerId = groupList[0].partner_id;

    dispatch(setInitialGroupMap(groupList));

    batch(() => {
      dispatch(setGroupId(groupId));
      dispatch(setPartnerId(partnerId));
    });
  }

  // if not location user :
  if (!isLocationUser(userInfo)) {
    // Need to fetch the location data and set the location Id
    const locationResponse = await getLocationsByGroup(groupId);

    if (
      !Utils.checkIfSuccess(locationResponse) ||
      !locationResponse?.data?.length
    ) {
      dispatch(setGlobalLoading(false));
      return;
    }

    const locationList = locationResponse.data as ILocationVO[];
    // const locationList = fakeLocationList;
    locationId = locationList[0].location_id;
    batch(() => {
      dispatch(setInitialLocationMap(locationList));
      dispatch(setLocationId(locationId));
      dispatch(setGroupId(groupId));
      dispatch(setPartnerId(partnerId));
    });
  } else {
    if (!locationId) return;

    dispatch(setPartnerId(partnerId));
    dispatch(setGroupId(groupId));
    dispatch(setLocationId(locationId));
  }

  dispatch(setGlobalLoading(false));
});

// changeGroupThunk
export const changeGroupThunk = createAsyncThunk<
  boolean,
  string,
  { state: RootState }
>('global/changeGroupThunk', async (newGroupId, { getState: gs, dispatch }) => {
  //Fetch the location for the new group Id, if there are no location we eliminiate setting the new group setting
  batch(() => {
    dispatch(setGroupId(newGroupId));
    dispatch(setLocationId(''));
    // If user changing group, surely there is location. so making location to show load
    dispatch(setLocationLoading(true));
  });

  const locationResponse = await getLocationsByGroup(newGroupId);

  if (
    !Utils.checkIfSuccess(locationResponse) ||
    !locationResponse?.data?.length
  ) {
    dispatch(setLocationLoading(false));
    return false;
  }

  const locationList = locationResponse.data as ILocationVO[];
  // const locationList = fakeLocationList;
  const locationId = locationList[0].location_id;

  batch(() => {
    dispatch(setInitialLocationMap(locationList));
    dispatch(setLocationId(locationId));
    dispatch(setLocationLoading(false));
  });

  return true;
});
