import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MUIDataTable from 'mui-datatables';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

import { SearchBarSection } from 'components';
import styles from './TableListView.module.scss';

const TableListView = (props) => {
  const {
    onFilterEvent,
    paperClass,
    className,
    tableBodyClass,
    tableTitle,
    tableData,
    tableOptions,
    tableProps = {},
    tableColumns,
    handleSearchInputChange,
    handleSecondaryButtonClick,
    searchBarSection,
    pagination,
    headerSection,
    headerSubtitle,
    headerTitle,
    headerClasses = '',
    titleCard,
    headerButton,
    headerButtonIcon,
    handleHeaderButtonClick,
    headerButtonProps,
    emptyPlaceHolderContent,
    emptyPlaceHolderDom,
    otherButtons,
    isLoading,
    enableExport,
    isExportLoading = true,
  } = props;

  const options = {
    textLabels: {
      body: {
        noMatch: emptyPlaceHolderContent
          ? emptyPlaceHolderContent
          : 'Sorry, no matching records found',
      },
    },
    setRowProps: () => ({
      style: { backgroundColor: "#FFFFFF" },
      onMouseEnter: (event) => {
        event.currentTarget.style.backgroundColor = "#f0f0f0";
      },
      onMouseLeave: (event) => {
        event.currentTarget.style.backgroundColor = "#FFFFFF";
      }
    }),
    filter: false,
    sort: true,
    selectableRows: 'none',
    responsive: 'standard',
    print: false,
    pagination: pagination,
    search: false,
    download: false,
    viewColumns: false,
    elevation: 0,
    customSearchRender: () => null,
    ...tableOptions,
  };

  return (
    <Paper
      elevation={0}
      className={clsx(styles.table_list_container, paperClass)}
    >
      <Grid container alignItems="stretch">
        {headerSection && (
          <Grid
            container
            alignItems="stretch"
            classes={{ root: styles.table_list_header_container }}
          >
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={12}
                  sm={headerButton || otherButtons ? 6 : 12}
                  lg={headerButton || otherButtons ? 6 : 12}
                  className={`d-flex align-center flex-no-wrap ${
                    headerSubtitle ? 'flex-column align-start' : ''
                  } ${headerClasses}`}
                >
                  <Typography variant="h5">{headerTitle}</Typography>
                  {Boolean(headerSubtitle) && (
                    <Typography variant="body1">{headerSubtitle}</Typography>
                  )}
                  {titleCard}
                </Grid>

                {Boolean(headerButton || otherButtons) && (
                  <Grid item xs={12} sm={6} lg={6} className="text_align_end">
                    {otherButtons}
                    {headerButton && (
                      <Button
                        variant="contained"
                        startIcon={headerButtonIcon}
                        onClick={handleHeaderButtonClick}
                        color="secondary"
                        size="large"
                        data-testid={`table-list-${headerButton
                          .replace(/ /g, '-')
                          .toLowerCase()}`}
                        {...headerButtonProps}
                      >
                        {headerButton}
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {searchBarSection && (
          <Grid item xs={12} className={styles.table_search_bar_container}>
            <SearchBarSection
              actionBtn={
                enableExport && (
                  <Button
                    startIcon={
                      isExportLoading ? (
                        <CircularProgress
                          className={styles.spinner}
                          color="secondary"
                          thickness={5}
                          size={17}
                        />
                      ) : (
                        <img src="/images/export.svg" alt="Export" />
                      )
                    }
                    className="fullHeight"
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    disabled={!Boolean(tableData?.length)}
                    onClick={handleSecondaryButtonClick}
                    data-testid="table-list-export-data"
                  >
                    {isExportLoading ? 'Exporting Data' : 'Export Data'}
                  </Button>
                )
              }
              handleFilterClick={onFilterEvent}
              handleSearchInputChange={handleSearchInputChange}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={clsx(
            { [styles.table_list_body_container]: !searchBarSection },
            'table-body-container',
            tableBodyClass,
          )}
        >
          {isLoading && (
            <Box textAlign="center" p={6}>
              <CircularProgress
                className={styles.spinner}
                color="primary"
                thickness={5}
                size={25}
              />
            </Box>
          )}
          {!isLoading &&
            (emptyPlaceHolderDom && !Boolean(tableData.length) ? (
              emptyPlaceHolderDom
            ) : (
              <MUIDataTable
                title={tableTitle}
                data={tableData}
                columns={tableColumns}
                options={options}
                className={clsx(className, {
                  'secondary-table': !searchBarSection,
                })}
                {...tableProps}
              />
            ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

TableListView.propTypes = {
  onFilterEvent: PropTypes.func,
  paperClass: PropTypes.string,
  className: PropTypes.string,
  tableTitle: PropTypes.node,
  tableBodyClass: PropTypes.string,
  tableData: PropTypes.array,
  tableOptions: PropTypes.object,
  tableProps: PropTypes.object,
  tableColumns: PropTypes.array,
  handleSearchInputChange: PropTypes.func,
  handleSecondaryButtonClick: PropTypes.func,
  searchBarSection: PropTypes.bool,
  pagination: PropTypes.bool,
  headerSection: PropTypes.bool,
  headerTitle: PropTypes.string,
  headerClasses: PropTypes.string,
  headerSubtitle: PropTypes.string,
  titleCard: PropTypes.string,
  headerButton: PropTypes.string,
  headerButtonIcon: PropTypes.any,
  handleHeaderButtonClick: PropTypes.func,
  headerButtonProps: PropTypes.object,
  emptyPlaceHolderContent: PropTypes.string,
  emptyPlaceHolderDom: PropTypes.any,
  otherButtons: PropTypes.node,
  isLoading: PropTypes.bool,
  enableExport: PropTypes.bool,
  isExportLoading: PropTypes.bool,
};

export default TableListView;
