import { PayloadAction } from '@reduxjs/toolkit';
import IDashboardReducers from 'Views/FlexPayV2/types/store/dashboardSlice/IDashboardReducers';
import IDashboardSliceData from 'Views/FlexPayV2/types/store/dashboardSlice/IDashboardSliceData';

const dashboardSliceReducers = (): IDashboardReducers => ({
  setLoading(state, { payload: loading }) {
    state.loading = loading;
  },
  setOverviewData(state, { payload: overview }) {
    state.overview = overview;
  },
  setRecentInvoices(state, { payload: recentInvoices }) {
    state.recentInvoices = recentInvoices;
  },
  setOverdueInvoices: function (
    state: IDashboardSliceData,
    action: PayloadAction<IDashboardSliceData['overdueInvoices']>,
  ): void {
    state.overdueInvoices = action.payload;
  },
  setPaymentDetails: function (
    state: IDashboardSliceData,
    action: PayloadAction<IDashboardSliceData['paymentDetails']>,
  ): void {
    state.paymentDetails = action.payload;
  },
  setInvoiceStatus: function (
    state: IDashboardSliceData,
    action: PayloadAction<IDashboardSliceData['invoiceStatus']>,
  ): void {
    state.invoiceStatus = action.payload;
  },
  setRangeSourceAmount(state, { payload: rangeSourceAmount }) {
    state.paymentSource.rangeSourceAmount = rangeSourceAmount;
  },
  setTotalSourceAmount(state, { payload: totalSourceAmount }) {
    state.paymentSource.totalSourceAmount = totalSourceAmount;
  },
  setSourceMode(state, { payload: sourceMode }) {
    state.paymentSource.sourceMode = sourceMode;
  },
  setSelectedDateRange(state, { payload: selectedDateRange }) {
    state.selectedDateRange = selectedDateRange;
  },
  setPaymentSpecialLoading(state, { payload: paymentSpecialLoading }) {
    state.paymentSpecialLoading = paymentSpecialLoading;
  },
});

export default dashboardSliceReducers;
