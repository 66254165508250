import { createSlice } from '@reduxjs/toolkit';
import getInvoiceCrudSliceData from './invoiceCrudSliceData';
import getInvoiceCrudSliceReducers from './invoiceCrudSliceReducers';

const invoiceCrudSlice = createSlice({
  name: 'invoiceCrudSlice',
  initialState: getInvoiceCrudSliceData(),
  reducers: {
    ...getInvoiceCrudSliceReducers(),
  },
});

export const {
  setInvoice,
  setInvoiceMode,
  setLoading,
  setCurrentPage,
  setPatientInfo,
  setInitialInvoiceLineItems,
  setNewCustomInvoiceItem,
  updateBillingInfo,
  editBillingInfo,
  setTerminalsLoading,
  setTerminalList,
  updatePaymentDetails,
  resetPaymentDetails,
  setIntentDetails,
  setCrudLoading,
  setSendLoading,
  bulkSelectLineItems,
  setInvoiceDueDate,
  setInvoiceDueError,
  resetManageState,
  setInvoiceType,
  setInitialAdjustmentTypes,
  setBalanceLoading,
  setPatientBalanceStats,
  setInvoiceAmount,
  updateInvoiceLineItem,
  setCustomReasons,
} = invoiceCrudSlice.actions;

export default invoiceCrudSlice.reducer;
