import IPatientInfoSliceReducers from 'Views/FlexPayV2/types/store/patientInfoSlice/patientInfoSliceReducers';

// Note: in each action one data should be updated, not multiple

const getPatientSliceReducers = (): IPatientInfoSliceReducers => ({
  setPatientInfoLoading: (state, action) => {
    state.patientInfoLoading = action.payload;
  },
  setPatientInfo: (state, action) => {
    state.patientVO = action.payload;
  },
});

export default getPatientSliceReducers;
