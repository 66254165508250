import { createSlice } from '@reduxjs/toolkit';

import getInvoiceSliceData from './patientListSliceData';
import getInvoiceSliceReducers from './patientListSliceReducers';

const patientListSlice = createSlice({
  name: 'patientList',
  initialState: getInvoiceSliceData(),
  reducers: {
    ...getInvoiceSliceReducers(),
  },
});

export const {
  setPatientListLoading,
  setPatientListVOMap,
  setPatientListFilter,
  setPatientListTablePagination,
  setPatientListTableSorting,
  setExportLoading,
} = patientListSlice.actions;

export default patientListSlice.reducer;
