import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { getInvoiceById } from 'Views/FlexPayV2/invoices/services/invoices.service';
import {
  addNotesToInvoice,
  deleteInvoiceNotes,
  getInvoiceNotes,
  updateInvoiceNotes,
} from 'Views/FlexPayV2/invoices/services/notes.service';
import { getInvoiceActivities } from 'Views/FlexPayV2/invoices/services/activities.service';

// Utils
import Utils from 'Shared/Utils';

// Store
import { RootState } from '../storeRoot';
import {
  setInvoiceInfoLoading,
  setInvoiceInfo,
  setNoteActionLoading,
  setInvoiceNotesLoading,
  setInvoiceNotes,
  setActivitiesLoading,
  setInvoiceActivities,
} from './invoiceInfoSlice';

// Fake Data
// import { fakeInvoiceVO } from 'Views/FlexPayV2/types/vo/IInvoiceInfoVO';

// fetchInvoiceInfoThunk
export const fetchInvoiceInfoThunk = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  'invoiceInfo/fetchInvoiceInfoThunk',
  async (invoiceId, { getState: gs, dispatch }) => {
    // set loading to true
    dispatch(setInvoiceInfoLoading(true));

    const invoiceResponse = await getInvoiceById(invoiceId);

    // check if success
    if (Utils.checkIfSuccess(invoiceResponse) && invoiceResponse?.data) {
      dispatch(setInvoiceInfo(invoiceResponse.data));

      // set loading to false
      dispatch(setInvoiceInfoLoading(false));
    } else {
      dispatch(setInvoiceInfo());
    }
  },
);

// fetchInvoiceNotesThunk
export const fetchInvoiceNotesThunk = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  'invoiceInfo/fetchInvoiceNotesThunk',
  async (invoiceId, { getState: gs, dispatch }) => {
    // set loading to true
    dispatch(setInvoiceNotesLoading(true));

    const notesResponse = await getInvoiceNotes(invoiceId);

    // check if success
    if (Utils.checkIfSuccess(notesResponse) && notesResponse.data) {
      dispatch(setInvoiceNotes(notesResponse.data));
    } else {
      dispatch(setInvoiceNotes([]));
    }

    // set loading to false
    dispatch(setInvoiceNotesLoading(false));
  },
);

// add notes thunk : this will add notes to the invoice
export const addNotesThunk = createAsyncThunk<
  { success: boolean },
  {
    invoiceId: string;
    note: string;
    visibility: 'public' | 'private';
  },
  { state: RootState }
>(
  'invoice/addNotesThunk',
  async (
    { invoiceId, note, visibility = 'public' },
    { getState: gs, dispatch },
  ) => {
    dispatch(setNoteActionLoading(true));

    const response = await addNotesToInvoice({
      invoice_id: invoiceId,
      note,
      visibility,
    });

    let success = false;

    if (Utils.checkIfSuccess(response)) {
      window.setWindowNotification('success', 'Notes added successfully');

      // Fetch notes list and rerender
      dispatch(fetchInvoiceNotesThunk(invoiceId));

      success = true;
    }

    dispatch(setNoteActionLoading(false));

    return { success };
  },
);

// update notes thunk : this will update the notes attached to the invoice
export const updateNotesThunk = createAsyncThunk<
  { success: boolean },
  {
    invoiceId: string;
    noteId: string;
    note: string;
    visibility: 'public' | 'private';
  },
  { state: RootState }
>(
  'invoice/updateNotesThunk',
  async (
    { invoiceId, noteId, note, visibility = 'public' },
    { getState: gs, dispatch },
  ) => {
    dispatch(setNoteActionLoading(true));

    const response = await updateInvoiceNotes(noteId, {
      note,
      visibility,
    });

    let success = false;

    if (Utils.checkIfSuccess(response)) {
      window.setWindowNotification('success', 'Notes updated successfully');

      // Fetch notes list and rerender
      dispatch(fetchInvoiceNotesThunk(invoiceId));

      success = true;
    }

    dispatch(setNoteActionLoading(false));

    return { success };
  },
);

// delete notes thunk : this will delete the notes attached to the invoice
export const deleteNotesThunk = createAsyncThunk<
  { success: boolean },
  {
    invoiceId: string;
    noteId: string;
  },
  { state: RootState }
>(
  'invoice/deleteNotesThunk',
  async ({ invoiceId, noteId }, { getState: gs, dispatch }) => {
    dispatch(setNoteActionLoading(true));

    const response = await deleteInvoiceNotes(noteId);

    let success = false;

    if (Utils.checkIfSuccess(response)) {
      window.setWindowNotification('success', 'Notes deleted successfully');

      // Fetch notes list and rerender
      dispatch(fetchInvoiceNotesThunk(invoiceId));

      success = true;
    }

    dispatch(setNoteActionLoading(false));

    return { success };
  },
);

// fetchInvoiceActivityThunk
export const fetchInvoiceActivityThunk = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  'invoiceInfo/fetchInvoiceActivityThunk',
  async (invoiceId, { getState: gs, dispatch }) => {
    // set loading to true
    dispatch(setActivitiesLoading(true));

    const activitiesResponse = await getInvoiceActivities(invoiceId);

    // check if success
    if (Utils.checkIfSuccess(activitiesResponse) && activitiesResponse?.data) {
      dispatch(setInvoiceActivities(activitiesResponse.data));
    } else {
      dispatch(setInvoiceActivities([]));
    }

    // set loading to false
    dispatch(setActivitiesLoading(false));
  },
);
