import moment from 'moment';
import ITransactionSliceData from 'Views/FlexPayV2/types/store/transactionSlice/ITransactionSliceData';

const getTransactionSliceData = (): ITransactionSliceData => {
  return {
    loading: true,
    currentPage: 'payments',
    statsMap: {
      all: 0,
      success: 0,
      refunded: 0,
      failed: 0,
      disputed: 0,
    },

    filterMap: {
      date: {
        startDate: moment().subtract(30, 'days').toDate().toISOString(),
        endDate: moment().toISOString(),
        label: 'Last 30 Days',
      },
    },

    paymentsIdMap: null,
    paymentsPaginationConfig: {
      currentPage: 0,
      pageSize: 25,
      total: 0,
    },
    isExportLoding: false,
    statusGroupMap: {
      success: ['paid'],
      failed: ['failed'],
      disputed: ['dispute'],
      refunded: ['refunded', 'partially_refunded'],
    },
    transfersIdMap: null,
    transfersPaginationConfig: {
      currentPage: 0,
      pageSize: 25,
      total: 0,
    },
    transfersFilterMap: {
      date: {
        startDate: moment().subtract(30, 'days').toDate().toISOString(),
        endDate: moment().toISOString(),
        label: 'Last 30 Days',
      },
    },
  };
};

export default getTransactionSliceData;
