import IPatientListSliceReducers from 'Views/FlexPayV2/types/store/patientListSlice/patientListSliceReducers';

// Note: in each action one data should be updated, not multiple

const getPatientListSliceReducers = (): IPatientListSliceReducers => ({
  setPatientListLoading: (state, action) => {
    state.patientListLoading = action.payload;
  },
  setPatientListVOMap(state, { payload: listVOMap }) {
    state.patientVOMap = listVOMap;
  },
  // filter vo should not be partial, it should be complete
  setPatientListFilter: (state, { payload: newFilterVO }) => {
    state.patientListFilter = newFilterVO;
  },
  setPatientListTablePagination: (state, action) => {
    state.patientListTablePagination = action.payload;
  },
  setPatientListTableSorting: (state, action) => {
    state.patientListTableSort = action.payload;
  },
  setExportLoading: (state, action) => {
    state.exportLoading = action.payload;
  },
});

export default getPatientListSliceReducers;
