import { createSlice } from '@reduxjs/toolkit';
import getDashboardSliceData from './dashboardSliceData';
import dashboardSliceReducers from './dashboardSliceReducers';

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState: getDashboardSliceData(),
  reducers: {
    ...dashboardSliceReducers(),
  },
});

export const {
  setLoading,
  setOverviewData,
  setRecentInvoices,
  setOverdueInvoices,
  setPaymentDetails,
  setInvoiceStatus,
  setTotalSourceAmount,
  setRangeSourceAmount,
  setSourceMode,
  setSelectedDateRange,
  setPaymentSpecialLoading,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
