import React, { useState, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Button,
  Grid,
  Hidden,
  Typography,
  FormControlLabel,
  Radio,
  InputAdornment,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Chip, ClearButton, CommonDialog, TextField } from "components";
import { Search as SearchIcon, Cancel as CloseIcon } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { formatDob } from "utils";
import { getTargetValue, formatPhoneNumber } from "Shared/Utils";
import { PMS_SERVICES } from "Services";
import styles from "./PatientLookup.module.scss";

const PatientLookup = (props) => {
  const { group_id, location_id, onSelect, showPatientLookupModal, setShowPatientLookupModal } = props;

  const [fetchLoading, setFetchLoading] = useState(false);
  const [noMatches, setNoMatches] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState([]);
  const [verifiedPatients, setVerifiedPatients] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [searchPmsId, setSearchPmsId] = useState("");
  const [linkingLoading, setLinkingLoading] = useState(false);
  const [isPatientSelected, setIsPatientSelected] = useState(false);

  useEffect(() => {
    if (isPatientSelected) {
      setSelectedPatient();
      setIsPatientSelected(false);
    }
  }, [group_id, location_id]);

  const selectPatient = (uValue, verifiedPats = verifiedPatients) => {
    if (!verifiedPats[uValue]) {
      setSelectedPatient(uValue);
    }
  };

  const renderVerificationIcon = (pms_pat_id) => {
    if (isVerifyLoading.includes(pms_pat_id)) {
      return (
        <CircularProgress
          className={clsx(styles.verifyIcon, "mg_left_8")}
          thickness={5}
          size={20}
          data-testid="patient-lookup-verify-loader"
        />
      );
    }

    if (verifiedPatients[pms_pat_id] !== undefined) {
      if (verifiedPatients[pms_pat_id]) {
        return (
          <Chip
            label={"Subscribed"}
            status={"subscribed"}
            className={"mg_left_8"}
          />
        );
      } else {
        return (
          <Chip
            label={"Not subscribed"}
            status={"Not subscribed"}
            className={"mg_left_8"}
          />
        );
      }
    }
  };

  const renderPmsIdSearchBox = (tableMeta) => {
    const { rowIndex } = tableMeta;
    return (
      <TextField
        required
        fullWidth
        autoFocus
        variant="outlined"
        margin="dense"
        value={searchPmsId}
        disabled={fetchLoading || Boolean(isVerifyLoading.length)}
        name="pms_pat_id_search"
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            handleSearchPmsClick();
          }
        }}
        onChange={(event) => {
          const tValue = getTargetValue(event);
          setSearchPmsId(tValue);
          setNoMatches(false);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={styles.searchIcon}>
              <IconButton
                size="small"
                data-testid={`patient-lookup-search-pms-icon-${rowIndex}`}
              >
                {fetchLoading ? (
                  <CircularProgress thickness={5} size={20} />
                ) : (
                  <SearchIcon onClick={handleSearchPmsClick} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        className={styles.searchInput}
        placeholder="Patient ID"
        helperText={noMatches && "No matches found"}
        error={noMatches}
        inputProps={{
          "data-testid": `patient-lookup-search-pms-id-${rowIndex}`,
        }}
      />
    );
  };

  const renderPmsIdField = (value, tableMeta) => {
    const { rowIndex } = tableMeta;
    return (
      <Grid container alignItems="center" className={styles.patientId}>
        <Typography
          variant="body1"
          data-testid={`patient-lookup-pms-id-${rowIndex}`}
        >
          {value}
        </Typography>
        {renderVerificationIcon(value)}
      </Grid>
    );
  };

  const renderPmsRadioButton = (value, tableMeta) => {
    const { rowIndex } = tableMeta;
    return (
      <FormControlLabel
        label=""
        value={value}
        control={
          <Radio
            checked={Boolean(selectedPatient === value)}
            value={value}
            color="primary"
            disabled={
              isVerifyLoading.includes(value) ||
              Boolean(verifiedPatients[value])
            }
            inputProps={{
              "data-testid": `patient-lookup-radio-button-${rowIndex}`,
            }}
          />
        }
        onChange={() => selectPatient(value)}
        data-testid={`patient-lookup-radio-label-${rowIndex}`}
      />
    );
  };

  const commonCellProps = () => ({ className: styles.tableColumns });
  const idCellProps = () => ({ className: styles.idColumn });
  const tableColumns = [
    {
      name: "radio_id",
      label: " ",
      options: {
        setCellProps: () => ({ className: styles.radioColumn }),
        setCellHeaderProps: () => ({ className: styles.radioColumn }),
        customBodyRender: (value, tableMeta) =>
          value ? renderPmsRadioButton(value, tableMeta) : "",
      },
    },
    {
      name: "pms_pat_id",
      label: "Patient ID",
      options: {
        setCellHeaderProps: idCellProps,
        customBodyRender: (value, tableMeta) =>
          value
            ? renderPmsIdField(value, tableMeta)
            : renderPmsIdSearchBox(tableMeta),
      },
    },
    {
      name: "first_name",
      label: "First Name",
      options: {
        setCellProps: commonCellProps,
        customBodyRender: (value) => value ?? "-",
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        setCellProps: commonCellProps,
        customBodyRender: (value) => value ?? "-",
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        setCellProps: commonCellProps,
        customBodyRender: (value) => value ?? "-",
      },
    },
    {
      name: "email",
      label: "Email Address",
      options: {
        setCellProps: commonCellProps,
        customBodyRender: (value) => value ?? "-",
      },
    },
    {
      name: "dob",
      label: "Date of Birth",
      options: {
        setCellProps: commonCellProps,
        customBodyRender: (value) => value ?? "-",
      },
    },
    {
      name: "phone",
      label: "Phone No.",
      options: {
        setCellProps: commonCellProps,
        customBodyRender: (value) => formatPhoneNumber(value) ?? "-",
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        setCellProps: commonCellProps,
        customBodyRender: (value) => {
          if (!value) {
            return "-";
          } else {
            const {
              line1 = "",
              city = "",
              state = "",
              country = "",
              zipcode = "",
            } = value;
            return `${line1}, ${city}, ${state}, ${country} ${zipcode}`;
          }
        },
      },
    },
  ];

  const options = {
    textLabels: {
      body: {
        noMatch: "No patient ID found",
      },
    },
    filter: false,
    sort: false,
    selectableRows: "none",
    responsive: "standard",
    print: false,
    pagination: false,
    search: false,
    download: false,
    viewColumns: false,
    elevation: 0,
    serverSide: true,
  };

  const setPatientVerifyLoading = async (pms_pat_id, isLoading) => {
    const loadingPatients = _.cloneDeep(isVerifyLoading);
    if (isLoading) {
      loadingPatients.push(pms_pat_id);
      setIsVerifyLoading(loadingPatients);
    } else {
      const idx = loadingPatients.indexOf(pms_pat_id);
      setIsVerifyLoading(loadingPatients.splice(idx, 1));
    }
  };

  const verifyPatient = async (patData) => {
    const radio_id = patData?.radio_id;
    setPatientVerifyLoading(radio_id, true);
    try {
      const { data } = await PMS_SERVICES.getPmsPatSubscriptions(
        location_id,
        patData?.pms_id,
        patData?.pms_display_id
      );
      if (data) {
        const verifiedPats = _.cloneDeep(verifiedPatients);
        verifiedPats[radio_id] = Boolean(data?.length);
        setVerifiedPatients(verifiedPats);
        selectPatient(radio_id, verifiedPats);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPatientVerifyLoading(radio_id, false);
    }
  };

  const handleSearchPmsClick = async () => {
    if (!searchPmsId) return;

    setFetchLoading(true);
    try {
      const resp = await PMS_SERVICES.getPmsPatientDetails(
        group_id,
        location_id,
        searchPmsId
      );

      if (resp?.data) {
        if (_.isEmpty(resp.data)) {
          throw new Error("No matches found");
        }

        const {
          pms_id = "",
          pms_display_id = "",
          pms_info = {},
          gender = "",
          given = [],
          family: last_name = "",
          email = "",
          date_of_birth = "",
          phone = "",
          address = {},
        } = resp.data;

        const [first_name] = given;

        //capitalize gender value
        let genderValue =
          gender && gender[0].toUpperCase() + gender.slice(1).toLowerCase();
        if (!["Male", "Female"].includes(genderValue)) {
          genderValue = "Others";
        }

        const searchTabData = {
          pms_id,
          pms_display_id,
          pms_pat_id: pms_display_id || pms_id,
          pms_info,
          first_name,
          last_name,
          gender: genderValue,
          email,
          dob: date_of_birth ? formatDob(date_of_birth, "YYYY-MM-DD") : "-",
          phone,
          address,
          radio_id: pms_display_id || pms_id,
        };
        setTableData([...tableData, searchTabData]);
        setFetchLoading(false);
        await verifyPatient(searchTabData);
      } else {
        throw resp?.message || resp?.error;
      }
    } catch (err) {
      setNoMatches(true);
      console.log(err);
    } finally {
      setSearchPmsId("");
      setFetchLoading(false);
    }
  };

  const onSelectClick = async () => {
    if (!selectedPatient) return;

    setLinkingLoading(true);
    const selectedPatientData = tableData.find(
      (pat) => pat.radio_id === selectedPatient
    );
    delete selectedPatientData?.radio_id;
    onSelect(selectedPatientData);
    setLinkingLoading(false);
    setIsPatientSelected(true);
    closePopup();
  };

  const closePopup = () => {
    setTableData([]);
    setVerifiedPatients({});
    setShowPatientLookupModal(false);
  };

  const clearSelectedPatient = () => {
    setSelectedPatient();
    setIsPatientSelected(false);
    onSelect();
  };

  return (
    <>
      {showPatientLookupModal ? (
        <CommonDialog
          open={showPatientLookupModal}
          handleClose={closePopup}
          maxWidth="lg"
          primaryBtnTxt={"Select"}
          handlePrimaryButtonClick={onSelectClick}
          disabledPrimaryButton={
            !selectedPatient || linkingLoading || fetchLoading
          }
          isLoading={fetchLoading}
          primaryButtonStyles={{ minWidth: 195, boxShadow: "none" }}
          secondaryBtnTxt={"Cancel"}
          handleSecondaryButtonClick={closePopup}
          disableSecondaryButton={linkingLoading || fetchLoading}
          isSecondaryBtnLoading={false}
          secondaryButtonStyles={{ minWidth: 195, boxShadow: "none" }}
          noPadding={true}
        >
          <Grid container spacing={0}>
            <IconButton
              size="small"
              onClick={closePopup}
              className={styles.closeIcon}
              data-testid="patient-lookup-modal-close-button"
            >
              <CloseIcon />
            </IconButton>

            <Grid item xs={12} md={6} className={styles.titleContainer}>
              <Typography
                variant="h4"
                className={styles.title}
                data-testid="patient-lookup-modal-header"
              >
                Patient Lookup
              </Typography>
              <Typography variant="h6" className={styles.subTitle}>
                {""}
              </Typography>
              <Grid
                item
                className={clsx(styles.tableTitle, "d-flex align-center")}
              >
                <Typography
                  variant="h5"
                  data-testid="patient-lookup-modal-description"
                >
                  {fetchLoading && "Checking in the EHR for this patient…"}
                  {!fetchLoading &&
                    (tableData.length === 0
                      ? "No patient record found."
                      : "Potential matching EHR patient records")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} data-testid="patient-lookup-modal-image">
              <Hidden smDown>
                <img
                  src="/images/ehr-gif.gif"
                  alt="ehr"
                  className={styles.gifImage}
                />
              </Hidden>
            </Grid>
            <Grid item xs={12} className={styles.tableWrapper}>
              <MUIDataTable
                data={[...tableData, {}]}
                columns={tableColumns}
                options={options}
                data-testid="patient-lookup-table"
              />
            </Grid>
          </Grid>
        </CommonDialog>
      ) : (
        <></>
      )}
    </>
  );
};

PatientLookup.propTypes = {
  group_id: PropTypes.string,
  location_id: PropTypes.string,
  onSelect: PropTypes.func,
  showPatientLookupModal: PropTypes.bool,
  setShowPatientLookupModal: PropTypes.func,
};

export default PatientLookup;
