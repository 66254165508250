import moment from 'moment';

// Types
import IPatientListSliceData from 'Views/FlexPayV2/types/store/patientListSlice/patientListSliceData';

export const initialPatientListFilter = {
  // Default filters
  appointmentDate: {
    apptDateLabel: 'Next 7 Days',
    apptDateStart: moment().toISOString(),
    apptDateEnd: moment().add(7, 'days').toISOString(),
  },
  name: {
    firstName: '',
    lastName: '',
  },
  email: '',

  // Additional filters
  patientId: '',
  dateOfBirth: '',
  phoneNumber: '',
};

const getPatientListSliceData = (): IPatientListSliceData => ({
  patientListFilter: {
    ...initialPatientListFilter,
  },
  patientVOMap: {},
  patientListLoading: false,
  patientListTablePagination: {
    currentPageNo: 0,
    currentRowsPerPage: 25,
    totalCount: 0,
  },
  patientListTableSort: {
    orderBy: 'next_appointment_date',
    orderDesc: false,
  },
  exportLoading: false,
});

export default getPatientListSliceData;
