import IInvoiceCrudSliceReducers from 'Views/FlexPayV2/types/store/invoiceCrudSlice/IInvoiceCrudSliceReducers';
import {
  IInvoiceLineItemsVO,
  invoiceCustomLineItemDefault,
} from 'Views/FlexPayV2/types/vo/IInVoiceVO';
import getInvoiceCrudSliceData from './invoiceCrudSliceData';
import { generateNanoId } from 'Views/FlexPayV2/utils/commonUtils';

const getInvoiceCrudSliceReducers = (): IInvoiceCrudSliceReducers => ({
  setInvoice(state, { payload: invoiceVO }) {
    state.invoiceVO = invoiceVO;
  },
  setLoading(state, { payload: loading }) {
    state.loading = loading;
  },
  setInvoiceMode(state, { payload: mode }) {
    state.mode = mode;
  },
  setInvoiceType(state, { payload: invoiceType }) {
    state.invoiceVO.invoice_type = invoiceType;
  },
  setCurrentPage: (state, { payload: currentPage }) => {
    state.currentPage = currentPage;
  },
  setPatientInfo(state, { payload: patientInfo }) {
    state.invoiceVO.patient = {
      id: patientInfo.id,
      first_name: patientInfo.first_name,
      last_name: patientInfo.last_name,
      email: patientInfo.email,
      pms_patient_id: patientInfo.pms_patient_id,
      date_of_birth: patientInfo.date_of_birth,
      gender: patientInfo.gender,
      phone: patientInfo.phone,
      active_since: patientInfo.active_since,
      address: {
        address_line1: patientInfo.address.address_line1,
        city: patientInfo.address.city,
        state: patientInfo.address.state,
        postal_code: patientInfo.address.postal_code,
        country_code: patientInfo.address.country_code,
      },
    };
  },
  setInitialInvoiceLineItems(state, { payload: invoiceLineItems = [] }) {
    const mode = state.mode;
    const invoiceType = state.invoiceVO.invoice_type;

    // For create quick invoice we only patient info not the line items, they can manually add it.
    if (mode === 'CREATE' && invoiceType === 'QUICK') {
      state.invoiceVO.line_items = [];
      return;
    }

    state.invoiceVO.line_items = invoiceLineItems.map((eachLineItem) => {
      return {
        ...eachLineItem,
      };
    });
  },
  setInitialAdjustmentTypes(state, { payload: adjustmentTypes = [] }) {
    state.invoiceVO.adjustment_types = adjustmentTypes;
  },
  setBalanceLoading(state, { payload: balanceLoading }) {
    state.balanceLoading = balanceLoading;
  },
  setPatientBalanceStats(state, { payload: patientBalanceStats }) {
    state.patientBalance = patientBalanceStats;
  },
  setNewCustomInvoiceItem(state) {
    // Add custom amount row here
    const updatedLineItem: IInvoiceLineItemsVO = {
      ...invoiceCustomLineItemDefault,
      isSelected: true,
      nanoId: generateNanoId('ti'),
    };
    state.invoiceVO.line_items.push(updatedLineItem);
  },
  updateBillingInfo(state, { payload: { billingInfo, checked } }) {
    // No seperate operation for same_as_patient_info, so added it in single action
    state.invoiceVO.same_as_patient_info = checked;
    state.invoiceVO.billing_detail = billingInfo;
  },
  editBillingInfo(state, { payload: { key, value } }) {
    state.invoiceVO.billing_detail[key] = value;
  },
  setTerminalsLoading: (state, { payload: terminalsLoading }) => {
    state.terminalsLoading = terminalsLoading;
  },
  setTerminalList: (state, { payload: terminalList }) => {
    state.terminalVOList = terminalList;
  },
  updatePaymentDetails: (state, { payload: { key, value } }) => {
    state.paymentDetails[key] = value;
  },
  resetPaymentDetails: (state) => {
    state.paymentDetails = {
      paymentType: 'select',
      terminalId: 'select',
      cardType: '',
      cardStatus: {
        error: {},
        complete: false,
      },
    };
  },
  setIntentDetails: (state, { payload: value }) => {
    state.intentDetails = value;
  },
  setCrudLoading: (state, { payload: crudLoading }) => {
    state.crudLoading = crudLoading;
  },
  setSendLoading: (state, { payload: sendLoading }) => {
    state.sendLoading = sendLoading;
  },
  bulkSelectLineItems: (
    state,
    { payload: { isSelected, filteredLineItems } },
  ) => {
    filteredLineItems.forEach(({ itemIndex }) => {
      state.invoiceVO.line_items[itemIndex].isSelected = isSelected;
      state.invoiceVO.line_items[itemIndex].isEdited = true;
    });
  },
  setInvoiceDueDate: (state, { payload: dueDate }) => {
    state.invoiceVO.due_at = dueDate;
  },
  setInvoiceDueError: (state, { payload: dueError }) => {
    state.invoiceVO.due_error = dueError;
  },
  resetManageState: (state) => {
    const {
      invoiceVO,
      patientBalance,
      loading,
      mode,
      currentPage,
      terminalVOList = [],
      paymentDetails,
      intentDetails,
      crudLoading = false,
      sendLoading = false,
    } = getInvoiceCrudSliceData();

    state.invoiceVO = invoiceVO;
    state.patientBalance = patientBalance;
    state.loading = loading;
    state.mode = mode;
    state.currentPage = currentPage;
    state.terminalVOList = terminalVOList;
    state.paymentDetails = paymentDetails;
    state.intentDetails = intentDetails;
    state.crudLoading = crudLoading;
    state.sendLoading = sendLoading;
  },
  setInvoiceAmount: (state, { payload: invoiceAmount }) => {
    state.invoiceVO.amount = invoiceAmount;
  },
  updateInvoiceLineItem: (state, { payload: { index, invoiceLineItem } }) => {
    state.invoiceVO.line_items[index] = invoiceLineItem;
  },
  setCustomReasons: (state, { payload: customReasons }) => {
    state.customReasons = customReasons;
  },
});

export default getInvoiceCrudSliceReducers;
