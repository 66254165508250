import IDashboardSliceData from 'Views/FlexPayV2/types/store/dashboardSlice/IDashboardSliceData';

const getDashboardSliceData = (): IDashboardSliceData => ({
  overview: {
    totalPatients: 0,
    totalInvoices: 0,
    totalInvoiceAmount: 0,
    averageAmountPerVisit: 0,
  },
  recentInvoices: [],
  overdueInvoices: [],
  paymentDetails: {
    totalAmount: 0,
    methodAmounts: {},
  },
  invoiceStatus: {
    totalAmount: 0,
    statusAmounts: {},
  },
  paymentSource: {
    totalSourceAmount: {
      inOffice: 0,
      paymentLink: 0,
      totalAmount: 0,
    },
    rangeSourceAmount: [],
    sourceMode: '',
  },
  loading: true,
  // May be in local storage
  selectedDateRange: '30-days',
  paymentSpecialLoading: false,
});

export default getDashboardSliceData;
