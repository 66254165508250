import { customAlphabet } from 'nanoid';
import { IInvoiceLineItemsVO } from '../types/vo/IInVoiceVO';

export const getOneLineAddress = (addressVO: {
  address_line1?: string;
  city?: string;
  state?: string;
  country_code?: string;
  postal_code?: string;
}) => {
  const { address_line1, city, state, country_code, postal_code } =
    addressVO || {};

  const addressParts = [address_line1, city, state, country_code, postal_code];
  // 🔴 Join only the defined values, so need confirmation on this on team to get the all needed values
  return addressParts.filter((part) => part).join(', ');
};

export const centsToDollars = (cents: number) => {
  return parseFloat((cents / 100).toFixed(2));
};

export const dollarsToCents = (dollars: number) => {
  return parseInt((dollars * 100).toFixed(0));
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const formatCurrencyFromCents = (amount = 0) => {
  const dollarAmount = centsToDollars(amount);

  return formatter.format(dollarAmount);
};

// generate random nano id :
export const generateNanoId = (module: string) => {
  const nanoid = customAlphabet(
    '23456789abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ',
    12,
  );
  return module + '_' + nanoid();
};

// round up the decimal to two decimal
export const roundToTwoDecimals = (value: number) => {
  if (value === 0) return 0;

  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const getTreatmentDisplayName = (item: Partial<IInvoiceLineItemsVO>) => {
  const {
    treatment_code,
    treatment_name,
    reason_code,
    reason_id,
    description,
  } = item;

  if (reason_id) {
    return {
      type: 'custom',
      label: (reason_code || '') + (description ? ' - ' + description : ''),
    };
  }

  return {
    label:
      (treatment_code ? treatment_code + ' - ' : '') + (treatment_name || '-'),
  };
};
