import IInvoiceInfoSliceReducers from 'Views/FlexPayV2/types/store/invoiceInfoSlice/invoiceInfoSliceReducers';

// Note: in each action one data should be updated, not multiple

const getInvoiceSliceReducers = (): IInvoiceInfoSliceReducers => ({
  setInvoiceInfoLoading: (state, action) => {
    state.invoiceInfoLoading = action.payload;
  },
  setInvoiceInfo: (state, action) => {
    state.invoiceInfo = action.payload;
  },
  setInvoiceNotesLoading: (state, action) => {
    state.notes.loading = action.payload;
  },
  setInvoiceNotes: (state, action) => {
    state.notes.list = action.payload;
  },
  setNoteActionLoading: (state, action) => {
    state.notes.actionLoading = action.payload;
  },
  setActivitiesLoading: (state, action) => {
    state.activities.loading = action.payload;
  },
  setInvoiceActivities: (state, action) => {
    state.activities.list = action.payload;
  },
});

export default getInvoiceSliceReducers;
