import moment from 'moment';
import IPatientVO from './IPatientVO';
import IPaymentRefund from './IPaymentRefund';

interface IInvoiceVO {
  invoice_type: string;
  patient: Partial<IPatientVO>;
  amount: number;
  processing_fees: number;
  refunded_amount: number;
  currency_code: string;
  status: string;
  terms_accepted?: boolean;
  id: string;
  display_id: string;
  location_id: string;
  location_name: string;
  group_id: string;
  cancellation_desc: string | null;
  cancellation_reason: string | null;
  due_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  billing_detail: {
    address_line1: string;
    city: string;
    state: string;
    //id: string  -- problem with property name - property not needed
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    postal_code: string;
    country_code: string;
  };
  same_as_patient_info: boolean;
  mailing_address: {
    address_line1: string;
    city: string;
    state: string;
    postal_code: string;
    id: string;
  };
  line_items: IInvoiceLineItemsVO[];
  payments: [IInvoicePaymentVO];
  adjustment_types: {
    pms_adjustment_type_id: string;
    description: string;
    is_discount_type: boolean;
    id: string;
    location_id: string;
  }[];
}

export interface IInvoicePaymentVO {
  id: string;
  paid_at: string;
  invoice_id: string;
  amount: number;
  processing_fees: number;
  payment_status: string;
  payment_type: string;
  payment_method: {
    id: string;
    payment_method_funding: string;
    payment_method_name: string;
    payment_method_last4: string;
  };
  discount?: number;
  payment_refunds?: IPaymentRefund[];
}

export default IInvoiceVO;

export interface IInvoiceLineItemsVO {
  id: string; // id for the line item in invoice vo
  treatment_item_id?: string; // id of treatment in pms
  total_amount: number;

  // Required for both treatment and custom line item
  status: string;
  treatment_date: string;

  // For treatment line item from PMS
  treatment_code?: string;
  treatment_name?: string;
  treatment_area?: string;
  treatment_surface?: string;
  treatment_amount?: number;
  claim_amount?: number;
  paid_amount?: number;
  pms_provided_adjustment?: boolean;
  pms_adjustment_id?: string;
  pms_adjustment_type_id?: string;
  adjustment_amount?: number;

  // For custom amount line item
  reason_id?: string;
  reason_code?: string;
  description?: string;
  nanoId?: string; // temporary id for new line item

  // only for ui :
  isSelected: boolean;
  isEdited?: boolean;
}

export const invoiceCustomLineItemDefault: IInvoiceLineItemsVO = {
  id: '',
  status: 'planned',
  treatment_date: moment().toISOString(),
  reason_id: '',
  reason_code: '',
  description: '',
  total_amount: 0,

  // Only for UI :
  isSelected: false,
  isEdited: false,
  nanoId: '',
};

// UI Display Enum for InVoiceVO
export enum IInvoiceUiDisplayEnum {
  first_name = 'First Name',
  patient_id = 'Patient ID',
  last_name = 'Last Name',
  id = 'Invoice ID',
  dob = 'Date of Birth',
  email = 'Email',
  invoice_no = 'Invoice No',
  total_unit_amount = 'Total Unit Amount',
  created_dt = 'Created Date',
  due_dt = 'Due Date',
  status = 'Status',
}

export interface ICustomAmountReasonsList {
  id: string;
  code: string;
  description: string;
}
