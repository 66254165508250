import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

import {
  PhoneInput,
  RemoveButton,
  TextField,
  CheckBox,
  Select,
} from 'components';
import { Context } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';
import DatePickers from 'Views/Common/DatePickers';
import { USER_SERVICES } from 'Services';
import { getFileExtension, formatSSN } from 'utils';
import {
  emailValidationMessage,
  phoneValidationMessage,
  ssnValidationMessage,
} from 'utils/validationMessages';
import { getStripeDateError } from 'Shared/Utils';

const DOCUMENT_SIDE = {
  FRONT: 'front',
  BACK: 'back',
};
const STRIPE_OPTIONS = {
  stripe_contact: 'Stripe Contact',
};

const ContactDetailsForm = (props) => {
  const {
    checkErrors,
    first_name,
    last_name,
    title,
    contact_email,
    phone,
    is_internal,
    date_of_birth,
    ssn_last_4,
    is_ssn_provided = false,
    verification_document = {},
    isRepresentative = false,
    isPersonalDetailsRequired = false,
    index,
    handleDeleteDependent,
    handleTextInputChange,
    showDivider,
    showStripeCheckBox = false,
  } = props;

  const { dispatch } = useContext(Context);

  const checkDateError = () => {
    let dateError = '';

    if (date_of_birth || isPersonalDetailsRequired) {
      dateError = getStripeDateError(date_of_birth);
    }

    return dateError;
  };

  const emailError = emailValidationMessage(contact_email);
  const phoneError = phoneValidationMessage(phone);
  const ssnError =
    isRepresentative &&
    ((isPersonalDetailsRequired && !is_ssn_provided) || ssn_last_4) &&
    ssnValidationMessage(ssn_last_4);
  const dobError = isRepresentative && checkDateError();

  const containerId = isRepresentative
    ? 'representative-contact-details'
    : 'contact-details';

  const [identityDocFront, setIdentityDocFront] = useState({});
  const [identityDocBack, setIdentityDocBack] = useState({});
  const [isSSNFocused, setIsSSNFocused] = useState(false);
  const [defaultSSNValue, setDefaultSSNValue] = useState();
  const acceptedIdentityDocFormats = ['.pdf', '.jpeg', '.png'];
  const [permissions, setPermissions] = useState(
    !is_internal ? ['stripe_contact'] : [],
  );
  const identityDocMaxSize = 10000000;

  const dispatchGlobalNotification = (severity, message) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  useEffect(() => {
    if (ssn_last_4 || isSSNFocused) {
      setDefaultSSNValue(ssn_last_4 || '');
    } else if (is_ssn_provided) {
      setDefaultSSNValue('****');
    } else {
      setDefaultSSNValue('');
    }
  }, [is_ssn_provided, ssn_last_4, isSSNFocused]);

  const getFrontDocumentDetails = async () => {
    try {
      const response = await USER_SERVICES.getFileFromStripe(
        verification_document?.front,
      );

      if (response?.type === 'success') {
        setIdentityDocFront({
          ...identityDocFront,
          ...response.data,
        });
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBackDocumentDetails = async () => {
    try {
      const response = await USER_SERVICES.getFileFromStripe(
        verification_document?.back,
      );

      if (response?.type === 'success') {
        setIdentityDocBack({
          ...identityDocBack,
          ...response.data,
        });
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (verification_document?.front) {
      getFrontDocumentDetails();
    }
  }, [verification_document?.front]);

  useEffect(() => {
    if (verification_document?.back) {
      getBackDocumentDetails();
    }
  }, [verification_document?.back]);

  const updateUploadedFile = (docSide, file) => {
    handleTextInputChange(
      {
        ...verification_document,
        [`${docSide}File`]: file,
      },
      'verification_document',
      index,
    );
  };

  const handleUpload = async (event, docSide) => {
    const file = event.target.files[0];
    const fileExtension = getFileExtension(file.name);

    if (!acceptedIdentityDocFormats.includes(fileExtension)) {
      dispatchGlobalNotification('error', 'Unsupported file format');
      return;
    }

    if (file.size > identityDocMaxSize) {
      dispatchGlobalNotification(
        'error',
        'File size should not be more than 10MB',
      );
      return;
    }
    updateUploadedFile(docSide, file);
  };

  const renderClearIcons = (docSide) => {
    return (
      <>
        <Tooltip arrow title="Clear">
          <IconButton
            className="mr-10"
            size="small"
            onClick={(evt) => {
              evt?.stopPropagation();
              updateUploadedFile(docSide);
            }}
            data-testid={`clear-upload-button-${docSide}`}
          >
            <ClearIcon fontSize="inherit" className="theme_color_orange" />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const renderUploadIcons = (docSide, isDisabled) => {
    return (
      <Tooltip arrow title="Upload">
        <IconButton
          className="mr-10"
          size="small"
          component="label"
          onClick={(evt) => evt?.stopPropagation()}
          data-testid={`upload-identity-doc-button-${docSide}`}
          disabled={isDisabled}
        >
          <CloudUploadIcon
            fontSize="inherit"
            className={isDisabled ? '' : 'theme_color_orange'}
          />
          <input
            type="file"
            hidden
            onChange={(e) => handleUpload(e, docSide)}
            onClick={(e) => {
              e.target.value = null;
            }}
            accept={acceptedIdentityDocFormats}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const renderIdentityDocument = (docSide) => {
    const isRequired =
      docSide !== DOCUMENT_SIDE.BACK && isPersonalDetailsRequired;
    const stripeFile =
      docSide === DOCUMENT_SIDE.FRONT ? identityDocFront : identityDocBack;
    const uploadedFile = verification_document[`${docSide}File`];

    const hasError =
      isRequired &&
      checkErrors &&
      !(uploadedFile?.name || stripeFile?.filename);
    const hasStripeFile = Boolean(identityDocFront?.id || identityDocBack?.id);

    let fileName = '';
    if (uploadedFile?.name) {
      fileName = uploadedFile?.name;
    } else if (stripeFile?.filename) {
      fileName = `${stripeFile?.filename}.${stripeFile?.type}`;
    }

    return (
      <TextField
        fullWidth
        required={isRequired}
        name={`identity-document-${docSide}`}
        label={`ID ${docSide}side`}
        value={fileName}
        variant="outlined"
        error={hasError}
        helperText={`Upload proof of identity like Passport, Driver license or State ID - ${docSide}side `}
        readOnly={true}
        disabled={hasStripeFile}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {renderUploadIcons(docSide, hasStripeFile)}
              {uploadedFile && renderClearIcons(docSide)}
            </InputAdornment>
          ),
          'data-testid': `${containerId}-identity-doc-${docSide}`,
        }}
      />
    );
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPermissions(value);
    const isInternal = !value.includes('stripe_contact');
    handleTextInputChange(isInternal, 'is_internal', index);
  };

  const renderTextField = (props) => {
    const {
      name = '',
      label = '',
      id = '',
      value = '',
      error = false,
      text = '',
      required = false,
      fieldProps = {},
    } = props;

    return (
      <TextField
        fullWidth
        required={required}
        name={name}
        label={label}
        value={value}
        onChange={(e) => handleTextInputChange(e?.target?.value, name, index)}
        variant="outlined"
        error={error}
        helperText={error && text}
        InputProps={{
          'data-testid': `${containerId}-${name}`,
        }}
        {...fieldProps}
      />
    );
  };

  return (
    <>
      <Grid container spacing={3} alignItems="stretch" id={containerId}>
        <Grid item xs={12} sm={4}>
          {renderTextField({
            name: 'first_name',
            label: 'First Name',
            id: containerId,
            value: first_name,
            error: Boolean(checkErrors && !first_name),
            text: 'First name is required',
            required: true,
          })}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField({
            name: 'last_name',
            label: 'Last Name',
            value: last_name,
            error: Boolean(checkErrors && !last_name),
            text: 'Last name is required',
            required: true,
          })}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField({
            name: 'title',
            label: 'Contact Title',
            value: title,
            error: Boolean(checkErrors && !title),
            text: 'Contact title is required',
            required: true,
          })}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField({
            name: 'contact_email',
            label: 'Email',
            value: contact_email,
            error: Boolean(checkErrors && emailError),
            text: emailError,
            required: true,
          })}
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <PhoneInput
              id={`${containerId}-phone-number`}
              isValid={!Boolean(checkErrors && phoneError)}
              error={Boolean(checkErrors && phoneError)}
              countryCodeEditable={false}
              value={phone}
              onChange={(e) => handleTextInputChange(e, 'phone', index)}
            />

            <FormHelperText className="color_error">
              {Boolean(checkErrors) && phoneError}
            </FormHelperText>
          </FormControl>
        </Grid>

        {showStripeCheckBox && (
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="permissions-multi-select">Permissions</InputLabel>
              <Select
                labelId="permissions-multi-select"
                id="multiple-checkbox"
                multiple
                value={permissions}
                label="Permissions"
                onChange={handleChange}
                renderValue={(selected) =>
                  selected
                    .map((item) => STRIPE_OPTIONS?.[item] || item)
                    .join(', ')
                }
              >
                {Object.keys(STRIPE_OPTIONS).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      <CheckBox checked={permissions.includes(key)} />
                      {STRIPE_OPTIONS[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isRepresentative && isPersonalDetailsRequired && (
          <>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth>
                <DatePickers
                  required={isPersonalDetailsRequired}
                  inputVariant="outlined"
                  label="Date of Birth"
                  format="MM/dd/yyyy"
                  placeholder="MM/DD/YYYY"
                  error={Boolean(checkErrors && dobError)}
                  name={'date_of_birth'}
                  value={date_of_birth || null}
                  disableFuture
                  invalidDateMessage={''}
                  minDateMessage={''}
                  maxDateMessage={''}
                  onChange={(e) =>
                    handleTextInputChange(e, 'date_of_birth', index)
                  }
                  KeyboardButtonProps={{
                    'data-testid': `${containerId}-date-picker-button`,
                    'aria-label': 'change date',
                  }}
                  InputProps={{
                    'data-testid': `${containerId}-date-picker`,
                  }}
                />
                <FormHelperText className="color_error">
                  {Boolean(checkErrors) && dobError}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              {renderTextField({
                name: 'ssn_last_4',
                label: 'Last 4 SSN',
                value: ssn_last_4 || defaultSSNValue,
                error: Boolean(checkErrors && ssnError),
                text: ssnError,
                required: isPersonalDetailsRequired,
                fieldProps: {
                  onFocus: () => setIsSSNFocused(true),
                  onBlur: () => setIsSSNFocused(false),
                  onInput: (e) => (e.target.value = formatSSN(e.target.value)),
                },
              })}
            </Grid>

            <Grid item xs={12} sm={4}>
              {renderIdentityDocument(DOCUMENT_SIDE.FRONT)}
            </Grid>

            <Grid item xs={12} sm={4}>
              {renderIdentityDocument(DOCUMENT_SIDE.BACK)}
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={4}>
          {!isRepresentative && index > 0 && (
            <RemoveButton
              size="large"
              color="secondary"
              variant="outlined"
              onClick={() => handleDeleteDependent(index)}
              fullWidth
            >
              Remove
            </RemoveButton>
          )}
        </Grid>
      </Grid>

      {showDivider && <Divider className="mg_top_32 mg_bottom_32" />}
    </>
  );
};

ContactDetailsForm.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
};

export default ContactDetailsForm;
