import React, { useContext, useEffect } from 'react';

import _ from 'lodash';
import { useHistory } from 'react-router';
import { Button, CircularProgress } from '@material-ui/core';

// Context
import { PlansContext } from 'Views/Plans/context';
import { Context as GlobalContext } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';
import {
  HIGHLIGHT_ERRORS,
  SAVING_PLAN,
  SAVE_ERROR,
  RESET_PLAN,
} from 'Views/Plans/context/actions';

// Services
import { PLAN_SERVICES, USER_SERVICES } from 'Services';

// Constants
import { PAGE_URLS } from 'Routes/Main/constants';

// Utils
import Utils from 'Shared/Utils';

const SaveDetails = (props) => {
  const history = useHistory();
  const {
    state: {
      selectedGroup,
      productInfo,
      cpt_codes,
      default_cpt_value,
      plans,
      isValid,
      isValidPrice,
      isSavingPlan,
      selectedPriceModel,
      discounts,
      plan_id: plan_package_id,
      isPlansByExcel,
    },
    dispatch,
  } = useContext(PlansContext);
  const { executeHandleSubmit = false, setExecuteHandleSubmit } = props || {};
  const { services = [] } = discounts || {};
  const disabledLifeTimeValueCodes =
    services
      ?.filter((code) => !code?.enableLifeTimeValue)
      .map((val) => val?.code) || [];
  const { plan_package_type: planPackageType, additional_members } =
    productInfo;
  const { dispatch: globalDispatch } = useContext(GlobalContext);

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  const handleSuccessresponse = (plan_package_id) => {
    dispatchGlobalNotification(
      'success',
      `Successfully ${plan_package_id ? 'updated' : 'created'} plan!`,
    );

    dispatch({ type: RESET_PLAN });
    redirectToPlansListing();
  };

  useEffect(() => {
    if (executeHandleSubmit) {
      createPlan();
    }
  }, [executeHandleSubmit]);

  const getVendorTransfers = (payoutValues) => {
    const group_tx_frequency = [],
      group_tx_amount_pri = [],
      group_tx_amount_dep = [],
      group_tx_cpt_consume = [];

    payoutValues.forEach((pVal) => {
      if (pVal.primary || pVal.dependent) {
        const primaryPayoutAmount = pVal.primary
          ? Math.round(pVal.primary * 100)
          : 0;
        const dependentPayoutAmount = pVal.dependent
          ? Math.round(pVal.dependent * 100)
          : 0;

        if (pVal.cpt_code) {
          group_tx_cpt_consume.push({
            cpt_code: pVal.cpt_code,
            tx_frequency: pVal.month,
            tx_amount_pri: primaryPayoutAmount || 0,
            tx_amount_dep: dependentPayoutAmount || 0,
          });
        } else {
          group_tx_frequency.push(pVal.month);
          group_tx_amount_pri.push(primaryPayoutAmount || 0);
          group_tx_amount_dep.push(dependentPayoutAmount || 0);
        }
      }
    });

    return {
      group_tx_frequency,
      group_tx_amount_pri,
      group_tx_amount_dep,
      group_tx_cpt_consume,
    };
  };

  const getPayload = (planPackageId) => {
    const {
      discount_level,
      discount_value,
      categories: categoryList = [],
      services: serviceList = [],
    } = discounts;

    let payload = {
      plan_package_id: planPackageId,
      categories: [],
      services: [],
    };

    const updatedServ = serviceList.map((service) => ({
      ...service,
      enableLifeTimeValue: undefined,
      retail_price: isNaN(parseInt(service?.retail_price))
        ? 0
        : service?.retail_price,
    }));

    if (discount_level === 'no_discount') {
      payload.discount_level = 'global';
      payload.global_discount = {
        discount_type: 'no_discount',
        discount_value: 0,
      };

      const updatedCat = categoryList.map((category) => ({
        ...category,
        discount_type: 'discount_percent',
        discount_value: category?.discount_value || 0,
      }));
      payload.categories = updatedCat;
      payload.services = [];
    } else if (discount_level === 'global') {
      payload.discount_level = 'global';
      payload.global_discount = {
        discount_type: 'discount_percent',
        discount_value,
      };

      const updatedCat = categoryList.map((category) => ({
        ...category,
        discount_type: 'discount_percent',
        discount_value: 0,
      }));
      payload.categories = updatedCat;
      payload.services = updatedServ;
    } else {
      payload.discount_level = 'category';
      const updatedCat = categoryList.map((category) => ({
        ...category,
        discount_type: 'discount_percent',
        discount_value: category?.discount_value || 0,
      }));
      payload.categories = updatedCat;
      payload.services = updatedServ;
    }

    return payload;
  };

  const saveCategoryDiscounts = async (planPackageId = plan_package_id) => {
    if (!planPackageId) return;
    if (isPlansByExcel) {
      handleSuccessresponse(plan_package_id);
      return;
    }

    try {
      const params = planPackageId ? `?plan_package_id=${planPackageId}` : '';
      const payload = getPayload(planPackageId);

      const response = await USER_SERVICES.postProcedureCategories(
        'group',
        selectedGroup?.group_id,
        params,
        payload,
      );

      if (response?.type === 'success') {
        handleSuccessresponse(plan_package_id);
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err);
      setExecuteHandleSubmit(false);
      dispatch({ type: SAVE_ERROR });
      dispatchGlobalNotification(
        'error',
        err?.message ||
          err?.error ||
          'Error in saving data. Please try again in sometime',
      );
    }
  };

  const checkMemberPricing = () => {
    let hasError = false;

    const totalMembers = additional_members + 1;

    const groupedTiers = plans.reduce((result, obj) => {
      result[obj?.plan_details?.billing_frequency] =
        result[obj?.plan_details?.billing_frequency] || [];
      result[obj?.plan_details?.billing_frequency].push(obj);

      return result;
    }, {});

    Object.values(groupedTiers).forEach((gTier) => {
      let totalMemberTiers = 0;

      gTier.forEach(({ member_tier }) => {
        totalMemberTiers +=
          Number(member_tier?.member_to) - Number(member_tier?.member_from) + 1;
      });

      if (totalMemberTiers !== totalMembers) hasError = true;
    });

    if (hasError) {
      dispatchGlobalNotification(
        'error',
        'Tier member range is not continous in member tier',
      );
    }

    return hasError;
  };

  const createPlan = async () => {
    if (planPackageType === 'Tiered') {
      const hasError = checkMemberPricing();

      if (hasError) return;
    }

    if (isSavingPlan) {
      return;
    }

    let invalidPricing = false;
    Object.keys({ ...isValidPrice }).forEach((key) => {
      const validityObj = {
        ...isValidPrice[key],
        ...(planPackageType === 'Tiered'
          ? { tiers: true, member_tier: isValidPrice[key]?.member_tier }
          : { tiers: isValidPrice[key]?.tiers, member_tier: true }),
      };
      const isNotValid = Object.keys(validityObj).some(
        (vKey) => !validityObj[vKey],
      );

      isNotValid && (invalidPricing = true);
    });

    if (
      !(isPlansByExcel || discounts?.formValid) ||
      invalidPricing ||
      Object.keys(isValid).some(
        (indKey) => indKey !== 'errorIndex' && !isValid[indKey],
      )
    ) {
      dispatch({ type: HIGHLIGHT_ERRORS });
      return;
    }

    const updatedProductInfo = _.cloneDeep(productInfo);
    delete updatedProductInfo.allowFutureDateToggle;

    const {
      stripe_product_id,
      renewal_plan_pkg_id,
      plan_status,
      allowed_age_max,
      plan_package_type,
      additional_members,
      ...productDetails
    } = updatedProductInfo;

    const sortedPlan = Utils.getSortedPricingTiers(plans);

    const data = {
      ...productDetails,
      plan_package_type,
      additional_members,
      group_id: selectedGroup.group_id,
      partner_id: selectedGroup.partner_id,
      payment_cpt_codes: disabledLifeTimeValueCodes,
      cpt_codes:
        !cpt_codes.length ||
        (cpt_codes.length === 1 && _.isEqual(cpt_codes[0], default_cpt_value))
          ? []
          : cpt_codes.map((allCode) => {
              const { group_tx_endofterm_amount, ...code } = allCode;

              let codeValue = code;
              if (code.frequency === 'unlimited') {
                delete codeValue.quantity;
                delete codeValue.total_unit;
              }

              return {
                ...codeValue,
                retail_price: Math.round((code.retail_price || 0) * 100),
                subscription_price: Math.round(
                  (code.subscription_price || 0) * 100,
                ),
              };
            }),
      pricing_tiers_list: sortedPlan.map((sPlan) => {
        const {
          plan_details,
          tiers,
          subscribili_comm,
          member_tier,
          revshare_payout = {},
          commission,
          vendor_payout,
          plan_id: planId,
        } = sPlan;

        const { primary = 0, dependent = 0, type } = subscribili_comm;
        const {
          enabled: revshareEnabled = false,
          revshare_id: revshareId = '',
          payout_type: revshareType = 'percent',
          amount: revshareAmount = 0,
        } = revshare_payout;
        const { type: commission_type, values } = commission;
        const { values: vendor_payout_values = [] } = vendor_payout;

        const updatedTiers = tiers.map(({ tier_level, ...tier }) => ({
          ...tier,
          unit_amount: Math.round(tier.unit_amount * 100),
          unit_charge_amount: Math.round(tier.unit_charge_amount * 100),
          upfront_amount: Math.round(tier.upfront_amount * 100),
          upfront_charge_amount: Math.round(tier.upfront_charge_amount * 100),
          flat_amount: 0,
          flat_charge_amount: 0,
          signup_amount: tier.signup_amount
            ? Math.round(tier.signup_amount * 100)
            : 0,
          signup_charge_amount: tier.signup_charge_amount
            ? Math.round(tier.signup_charge_amount * 100)
            : 0,
        }));

        const getPricingStatus = () => {
          if (plan_status === 'Hidden') return 'Hidden';
          return plan_details?.status || plan_status;
        };

        return {
          plan_type: plan_details.member_type,
          ...(plan_package_type === 'Tiered' && {
            member_from: Number(member_tier?.member_from),
            member_to: Number(member_tier?.member_to),
            price_name: member_tier?.price_name,
          }),
          renewal_plan_pkg_id,
          status: getPricingStatus(),
          pricing_tiers: {
            price_description: plan_details.price_description,
            billing_frequency: plan_details.billing_frequency,
            stripe_fee_paid_by: plan_details.stripe_fee_paid_by,
            tiers: updatedTiers,
          },
          commission: {
            subscribili_comm: {
              primary: {
                type,
                amount: (type === 'percent' ? primary : primary * 100) + '',
              },
              additional: {
                type,
                amount: (type === 'percent' ? dependent : dependent * 100) + '',
              },
            },
            commission_type,
            primary: { ...values[0] },
            additional: { ...values[1] },
          },
          revshare_id: revshareEnabled ? revshareId : null,
          revshare_payout: revshareEnabled
            ? {
                payout_type: revshareType,
                amount:
                  revshareType === 'percent'
                    ? revshareAmount
                    : revshareAmount * 100,
              }
            : undefined,
          transfer: getVendorTransfers(vendor_payout_values),
          private_only: plan_details.private_only,
          plan_id: planId,
        };
      }),
    };

    if (plan_package_type === 'Tiered') {
      data.plan_package_type = 'Base';
    }

    data.price_type = selectedPriceModel;

    allowed_age_max && (data.allowed_age_max = allowed_age_max);

    dispatch({ type: SAVING_PLAN });

    const service = plan_package_id
      ? PLAN_SERVICES.updatePlan
      : PLAN_SERVICES.createPlan;

    const {
      type: responseType,
      data: responseData,
      message,
    } = await service(data, plan_package_id);

    if (responseType === 'success') {
      if (discounts?.formUpdated && (isPlansByExcel || discounts?.formValid)) {
        const { plan_package_id: respPackageId } =
          responseData?.rows?.[0] || {};

        saveCategoryDiscounts(respPackageId);
      } else {
        dispatch({ type: RESET_PLAN });
        dispatchGlobalNotification(
          'success',
          `Successfully ${plan_package_id ? 'updated' : 'created'} plan!`,
        );
        redirectToPlansListing();
      }
    } else {
      setExecuteHandleSubmit(false);
      dispatch({ type: SAVE_ERROR });
      dispatchGlobalNotification(
        'error',
        message || 'Error in saving data. Please try again in sometime',
      );
    }
  };

  const redirectToPlansListing = () =>
    history.push({
      pathname: PAGE_URLS.PLANS_LIST,
      state: {
        data: selectedGroup,
      },
    });

  const title = plan_package_id ? 'Update Plan' : 'Create Plan';

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={redirectToPlansListing}
        data-testid="cancel-plan-button"
      >
        Cancel
      </Button>
      <Button
        className="mg_left_8 button-wrapper"
        variant="contained"
        color="secondary"
        size="large"
        onClick={createPlan}
        disabled={isSavingPlan}
        data-testid={`${title.replace(/ /g, '-').toLowerCase()}-button`}
      >
        {isSavingPlan ? (
          <CircularProgress
            color="inherit"
            thickness={5}
            size={25}
            className="button-spinner"
          />
        ) : (
          title
        )}
      </Button>
    </>
  );
};

export default SaveDetails;
