import { createSlice } from '@reduxjs/toolkit';

import getInvoicePaySliceData from './invoicePaySliceData';
import getInvoicePaySliceReducers from './invoicePaySliceReducers';

const invoicePaySlice = createSlice({
  name: 'invoicePay',
  initialState: getInvoicePaySliceData(),
  reducers: {
    ...getInvoicePaySliceReducers(),
  },
});

export const {
  setLocationSettingsLoading,
  setLocationSettings,
  setVerifyLoading,
  setAccessVerified,
  setInvoiceInfoLoading,
  setInvoiceInfo,
  setInvoicePayment,
  setHSAFSACardType,
  setTermsAccepted,
  setSubmitLoading,
  setIntentDetails,
} = invoicePaySlice.actions;

export default invoicePaySlice.reducer;
