import React from 'react';

import { useHistory } from 'react-router';
import { IconButton, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';

// Components
import { Chip, TableListView } from 'components';

// Constants
import { PAGE_URLS } from 'Routes/Main/constants';

// Styles
import styles from './AssociatedRegion.module.scss';

const AssociatedRegion = ({
  regionList,
  hierarchyDetails: { partner_id, group_id },
  isCreationEnabled,
  isSettingsView,
}) => {
  const history = useHistory();

  const commonCellProps = () => ({ className: styles.tableColumn });
  const tableColumns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        setCellProps: commonCellProps,
        sort: false,
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        setCellProps: commonCellProps,
        sort: false,
        display: !isSettingsView,
      },
    },
  ];

  const tableData = regionList?.map((indRegion) => ({
    name: indRegion.region_name,
    address: indRegion?.address?.line1,
    city: indRegion?.address?.city,
    state: indRegion?.address?.state,
    status: <Chip label={indRegion.status} status={indRegion.status} />,
    action: (
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => viewRegion(indRegion)}
        data-testid="view-region-button"
      >
        <VisibilityIcon className="theme_color_orange" fontSize="inherit" />
      </IconButton>
    ),
  }));

  const triggerRegionCreation = () =>
    history.push({
      pathname: PAGE_URLS.CREATE_REGION,
      state: { partner_id, group_id },
    });

  const viewRegion = (regionData) =>
    history.push({
      pathname: PAGE_URLS.REGION_INFO,
      state: { regionData },
    });

  const onRowClick = (rowData, rowMeta) => {
    const { dataIndex = 0 } = rowMeta;

    !isSettingsView && viewRegion(regionList[dataIndex]);
  };

  const btnProps = {
    otherButtons: isCreationEnabled && (
      <Button
        onClick={triggerRegionCreation}
        color="secondary"
        size="large"
        data-testid="add-region"
      >
        Add Region
      </Button>
    ),
  };

  return (
    <TableListView
      headerTitle="Associated Region(s)"
      tableData={tableData}
      tableOptions={{
        onRowClick,
      }}
      tableColumns={tableColumns}
      pagination={Boolean(tableData.length > 10)}
      headerSection={true}
      emptyPlaceHolderContent="No Associated Regions found"
      {...btnProps}
    />
  );
};

AssociatedRegion.propTypes = {
  regionList: PropTypes.array,
  hierarchyDetails: PropTypes.object,
  isCreationEnabled: PropTypes.bool,
  isSettingsView: PropTypes.bool,
};

export default AssociatedRegion;
