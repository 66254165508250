import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  TextField,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';

// Components
import {
  CommonDialog,
  RoleValidator,
  Select,
  SectionHeader,
  PhoneInput,
  CheckBox,
} from 'components';
import DatePickers from 'Views/Common/DatePickers';

// Context
import { Context } from 'context';
import {
  SUBSCRIBERS_FILTERS_UPDATED,
  LEVEL_LIST_DATA_UPDATED,
  LEVEL_LIST_DATA_LOADING,
  ADD_NOTIFICATION,
} from 'context/actions';

// Utils
import Utils, {
  checkIfInternalUser,
  isPartnerUser,
  isGroupUser,
  isRegionUser,
  isLocationUser,
} from 'Shared/Utils';
import { formatDate } from 'utils';
import {
  PARTNER_FILTER_ROLES,
  GROUP_FILTER_ROLES,
  REGION_FILTER_ROLES,
  LOCATION_FACILITY_FILTER_ROLES,
} from './filterToRoleUtils';

// Services
import {
  PARTNER_SERVICES,
  GROUP_SERVICES,
  REGION_SERVICES,
  LOCATION_SERVICES,
  PLAN_SERVICES,
  FACILITY_SERVICES,
} from 'Services';

// Constants
import {
  PLAN_PROCESS_FREQUENCIES,
  STAT_CARDS,
  OVERDUE_LIST,
  HAS_PAYMENT_METHOD,
} from '../enum';
import { DEPENDENT_VALUES } from './constants';
import {
  ALL_LOCATIONS,
  ALL_GROUPS,
  ALL_PARTNERS,
  ALL_PLANS,
  ALL_REGIONS,
} from '../constants';
import { PAGE_URLS } from 'Routes/Main/constants';

const FilterModal = (props) => {
  const {
    state: { user, subscriberFilters, filterDataLoading: loading, filterData },
    dispatch,
  } = useContext(Context);
  const history = useHistory();

  const { filterState, isFilterActive } = subscriberFilters;
  const [customFilter, setCustomFilter] = useState(
    _.cloneDeep({
      ...filterState,
      phone: filterState?.phone === '' ? '1' : filterState.phone,
    }),
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [blurred, setBlurred] = useState([]);
  const [dateError, setDateError] = useState('');

  const [partners, setPartners] = useState(filterData?.partners);
  const [groups, setGroups] = useState(filterData?.groups);
  const [regions, setRegions] = useState(filterData?.regions);
  const [locations, setLocations] = useState(filterData?.locations);
  const [regionalLocations, setRegionalLocations] = useState();
  const [isFacilityEnabled, setIsFacilityEnabled] = useState(
    props?.isFacilityEnabled,
  );
  const [facilities, setFacilities] = useState(filterData?.facilities);
  const [plans, setPlans] = useState(filterData?.plans);

  const dataLoading = Object.keys(loading).some((key) => loading[key]);

  const {
    partner_id,
    group_id,
    region_id,
    location_id,
    facility_id,
    plan_package_id,
    statStatus,
    overdue_days = [],
    created_start,
    created_end,
    first_name,
    last_name,
    billing_frequency,
    email,
    phone,
    patient_id,
    date_of_birth,
    has_payment_method = '',
  } = customFilter;

  const setGlobalNotification = (severity, message) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message,
        },
      },
    });

  const onFilterValueChange = (name, value) => {
    let dependentValues = DEPENDENT_VALUES?.[name] || {};

    if (name === 'stat_key') {
      name = 'statStatus';
    }

    setCustomFilter((previousFilter) => ({
      ...previousFilter,
      ...dependentValues,
      [name]: value,
    }));
  };

  const internalUsers = checkIfInternalUser(user);
  const partnerUsers = isPartnerUser(user);
  const groupUsers = isGroupUser(user);
  const regionUsers = isRegionUser(user);
  const locationUsers = isLocationUser(user);

  const fetchPartnerList = async () => {
    let partnersList = [];
    if (internalUsers)
      partnersList = partners?.length ? partners : await getPartnersList();

    const selectedPartnerId =
      partner_id || props.partnerId || partnersList?.[0]?.partner_id || '';

    if (selectedPartnerId !== partner_id)
      onFilterValueChange('partner_id', selectedPartnerId);
  };

  const fetchGroupList = async () => {
    if (partner_id) {
      let groupsList = [];
      let selectGroupId = '';
      let stateGroupId = group_id || props.groupId;

      if (internalUsers || partnerUsers) {
        const samePartner =
          Boolean(groups?.length) && partner_id === groups?.[0]?.partner_id;

        if (partner_id === 'ALL_PARTNERS') {
          setGroups([{ ...ALL_GROUPS }]);

          groupsList = [{ ...ALL_GROUPS }];
        } else {
          groupsList = samePartner ? groups : await getGroupsList();
        }

        if (stateGroupId) {
          const hasGroup = groupsList?.find(
            (indGroup) => indGroup.group_id === stateGroupId,
          );
          if (hasGroup) selectGroupId = stateGroupId;
          else if (groupsList?.length === 1)
            selectGroupId = groupsList?.[0]?.group_id || '';
        }
      } else {
        selectGroupId = stateGroupId || '';
      }

      if (selectGroupId !== group_id)
        onFilterValueChange('group_id', selectGroupId);
    }
  };

  const fetchRegionList = async () => {
    let regionsList = [];

    if (internalUsers || partnerUsers || groupUsers) {
      const sameRegionGroup =
        Boolean(regions?.length) && group_id === regions?.[0]?.group_id;

      if (group_id === 'ALL_GROUPS') {
        setRegions([{ ...ALL_REGIONS }]);

        regionsList = [{ ...ALL_REGIONS }];
      } else {
        regionsList = sameRegionGroup ? regions : await getRegionsList();
      }
    }

    let selectRegionId =
      regionsList?.length === 1 ? regionsList?.[0]?.region_id : '';
    if (region_id) {
      if (locationUsers) {
        selectRegionId = region_id;
      } else {
        const hasRegion = regionsList.find(
          (indRegion) => indRegion.region_id === region_id,
        );
        (hasRegion || region_id === ALL_REGIONS.region_id) &&
          (selectRegionId = region_id);
      }
    }

    if (selectRegionId !== region_id)
      onFilterValueChange('region_id', selectRegionId);
  };

  const fetchLocationList = async () => {
    let locationsList = [];

    if (
      internalUsers ||
      partnerUsers ||
      groupUsers ||
      regionUsers ||
      locationUsers
    ) {
      let sameLocationRegion = false;
      if (locations?.length) {
        if (
          (region_id && regionalLocations) ||
          (region_id === ALL_REGIONS.region_id && !regionalLocations)
        ) {
          region_id === locations?.[0]?.region_id &&
            (sameLocationRegion = true);
        }
      }

      const regionId = region_id !== ALL_REGIONS.region_id ? region_id : '';
      locationsList = sameLocationRegion
        ? locations
        : await getLocationsList(regionId);
    }

    let selectLocationId =
      locationsList?.length === 1 ? locationsList?.[0]?.location_id : '';
    if (location_id) {
      const hasLocation = locationsList.find(
        (indLocation) => indLocation.location_id === location_id,
      );
      (hasLocation || location_id === ALL_LOCATIONS.location_id) &&
        (selectLocationId = location_id);
    }

    if (selectLocationId !== location_id)
      onFilterValueChange('location_id', selectLocationId);
  };

  const clearDateRange = (evt) => {
    evt.stopPropagation();

    setDateRange({
      created_start: null,
      created_end: null,
    });
  };

  const getFrequencyKey = (selFreq) =>
    Object.keys(PLAN_PROCESS_FREQUENCIES).find(
      (key) => PLAN_PROCESS_FREQUENCIES[key] === selFreq,
    );

  const setDateRange = ({ startDate, endDate }) =>
    setCustomFilter((prevSelection) => ({
      ...prevSelection,
      created_start: startDate,
      created_end: endDate,
    }));

  const setFrequency = (frequency) =>
    setCustomFilter((prevSelection) => ({
      ...prevSelection,
      billing_frequency: frequency,
    }));

  const setLoader = (listName, loadingState) =>
    dispatch({
      type: LEVEL_LIST_DATA_LOADING,
      payload: {
        key: [listName],
        value: loadingState,
      },
    });

  const getPartnersList = async () => {
    setLoader('partners', true);

    try {
      const response = await PARTNER_SERVICES.fetchAllPartners();

      if (response?.type === 'success') {
        setLoader('partners', false);

        setPartners([{ ...ALL_PARTNERS }, ...(response?.data?.rows || [])]);

        const partnersList = response?.data?.rows || [];
        return partnersList;
      } else {
        throw response?.error || response?.message;
      }
    } catch (err) {
      setPartners([]);
      setGlobalNotification('error', err);
    }

    setLoader('partners', false);
  };

  const getGroupsList = async () => {
    setLoader('groups', true);

    try {
      const params =
        partner_id === ALL_PARTNERS.partner_id ? {} : { partner_id };
      const response = await GROUP_SERVICES.fetchGroupByPartnerId(params);

      if (response?.type === 'success') {
        setLoader('groups', false);
        const groupsList = response?.data?.rows || [];

        setGroups(groupsList);

        if (groupsList?.length > 1) {
          setGroups([ALL_GROUPS, ...groupsList]);
        } else {
          setGroups([...groupsList]);
        }

        return groupsList;
      } else {
        throw response?.error || response?.message;
      }
    } catch (err) {
      setGroups([]);
      setGlobalNotification('error', err);
    }

    setLoader('groups', false);
  };

  const getRegionsList = async () => {
    setLoader('regions', true);

    try {
      const response = await REGION_SERVICES.fetchRegionByQueryParams(
        partner_id,
        group_id,
      );

      if (response?.type === 'success') {
        setLoader('regions', false);
        const regionsList = response?.data?.rows || [];

        if (regionsList?.length > 1) {
          setRegions([ALL_REGIONS, ...regionsList]);
        } else {
          setRegions([...regionsList]);
        }

        return regionsList;
      } else {
        throw response?.error || response?.message;
      }
    } catch (err) {
      setRegions([]);
      setGlobalNotification('error', err);
    }

    setLoader('regions', false);
  };

  const getLocationsList = async (regionId) => {
    if (
      !partner_id ||
      partner_id === ALL_PARTNERS.partner_id ||
      !group_id ||
      group_id === ALL_GROUPS.group_id
    )
      return;

    setLoader('locations', true);

    try {
      let params = '&all=yes';
      regionId && (params = `&region_id=${regionId}`);

      const response = await LOCATION_SERVICES.fetchLocationByPartnerId(
        group_id,
        partner_id,
        params,
      );

      if (response?.type === 'success') {
        setRegionalLocations(Boolean(regionId));

        setLoader('locations', false);
        const locationsList = response?.data?.rows || [];

        if (locationsList?.length > 1) {
          setLocations([ALL_LOCATIONS, ...locationsList]);
        } else {
          setLocations([...locationsList]);
        }

        return locationsList;
      } else {
        throw response?.error || response?.message;
      }
    } catch (err) {
      setLocations([]);
      setGlobalNotification('error', err);
    }

    setLoader('locations', false);
  };

  const getFacilityList = async (groupId, locationId) => {
    setLoader('facilities', true);

    try {
      const response = await FACILITY_SERVICES.getFacilityList(
        groupId,
        locationId,
      );

      if (response?.type === 'success') {
        setLoader('facilities', false);

        setFacilities(response?.data?.rows);

        return response?.data?.rows || [];
      } else {
        throw response?.error || response?.message;
      }
    } catch (err) {
      setFacilities([]);
      setGlobalNotification('error', err);
    } finally {
      setLoader('facilities', false);
    }
  };

  const getPlansList = async () => {
    if (!group_id) return;
    else if (group_id === ALL_GROUPS.group_id) {
      setPlans([]);

      setCustomFilter((prevSelection) => ({
        ...prevSelection,
        plan_package_id: '',
      }));

      return;
    }

    setLoader('plans', true);

    try {
      const response = await PLAN_SERVICES.fetchGroupPlanPackages(group_id);

      if (response?.type === 'success') {
        const plansList = response?.data?.rows || [];
        setLoader('plans', false);
        setPlans([ALL_PLANS, ...plansList]);

        if (plansList?.length === 1) {
          setCustomFilter((prevSelection) => ({
            ...prevSelection,
            plan_package_id: plansList?.[0]?.plan_package_id,
          }));
        }
      } else {
        throw response?.error || response?.message;
      }
    } catch (err) {
      setPlans([]);
      setGlobalNotification('error', err);
    }

    setLoader('plans', false);
  };

  const emitFilterCloseEvent = (type) => {
    let updatedFilterState = { ...customFilter };

    if (type === 'reset') {
      const { region_id: rId = '', location_id: lId = '' } =
        user?.settings?.level_data || {};

      updatedFilterState = {
        ...customFilter,
        region_id: rId,
        location_id: lId,
        facility_id: '',
        plan_package_id: '',
        text: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        billing_frequency: '',
        patient_id: '',
        subscription_id: '',
        date_of_birth: '',
        has_payment_method: '',
        created_start: null,
        created_end: null,
      };
    } else {
      if (updatedFilterState?.has_payment_method) {
        updatedFilterState.has_payment_method =
          updatedFilterState?.has_payment_method?.toLowerCase();
      }
      dispatch({
        type: LEVEL_LIST_DATA_UPDATED,
        payload: {
          partners,
          groups,
          regions,
          locations,
          facilities,
          plans,
        },
      });

      props.closeFilterModal();
    }

    dispatch({
      type: SUBSCRIBERS_FILTERS_UPDATED,
      payload: {
        subscriberFilters: {
          filterState: {
            ...updatedFilterState,
            phone:
              updatedFilterState?.phone === '1'
                ? ''
                : updatedFilterState?.phone,
          },
          pageProps: {
            page_number: 0,
            rowsPerPage: 100,
          },
          isFilterUpdated: true,
          isFilterFromModal: true,
        },
      },
    });

    setCustomFilter({
      ...updatedFilterState,
      phone: updatedFilterState?.phone === '' ? '1' : updatedFilterState?.phone,
    });

    if (history.location.pathname !== PAGE_URLS.SUBSCRIBER_LIST)
      history.push(PAGE_URLS.SUBSCRIBER_LIST);
  };

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  const renderTextField = (name, label, value) => (
    <TextField
      fullWidth
      name={name}
      variant="outlined"
      value={value}
      onChange={(event) =>
        onFilterValueChange(event.target.name, event.target.value)
      }
      label={label}
      inputProps={{ 'data-testid': `filter-${name}` }}
    />
  );

  const handleDateChange = (e, name) => {
    const validDOB =
      e != 'Invalid Date' ||
      moment(
        e,
      ).isValid(); /* Note: != insteadOf !== as e is not a String value */
    const futureDate = moment(e).isAfter();

    setBlurred([]);
    if (!validDOB) {
      setDateError('Invalid date format');
      return;
    } else if (futureDate) {
      setDateError('Date of Birth cannot be in the future');
      return;
    }

    setDateError('');
    onFilterValueChange(name, e);
  };

  useEffect(() => {
    let frequency = '';
    if (plan_package_id && filteredTiers?.length === 1) {
      frequency = getFrequencyKey(
        filteredTiers?.[0]?.pricing_tiers?.billing_frequency || '',
      );

      setCustomFilter((prevSelection) => ({
        ...prevSelection,
        billing_frequency: frequency,
      }));
    }
  }, [plan_package_id]);

  useEffect(() => {
    if (location_id && group_id && isFacilityEnabled) {
      getFacilityList(group_id, location_id);
    } else {
      setCustomFilter((prevSelection) => ({
        ...prevSelection,
        facility_id: '',
      }));
    }
  }, [location_id]);

  useEffect(() => {
    region_id && fetchLocationList();
  }, [region_id]);

  useEffect(() => {
    const facilityAllowedGroup =
      groups?.find((group) => group?.group_id === group_id) || {};
    if (facilityAllowedGroup?.allow_facility) {
      setIsFacilityEnabled(true);
    } else {
      setIsFacilityEnabled(false);
    }
  }, [group_id, groups]);

  useEffect(() => {
    if (partner_id && group_id) {
      (async () => {
        await fetchRegionList();
        getPlansList();
      })();
    }
  }, [group_id]);

  useEffect(() => {
    partner_id && fetchGroupList();
  }, [partner_id]);

  useEffect(() => {
    fetchPartnerList();
  }, []);

  const renderDatePicker = (name, label, value, datepickerProps) => (
    <FormControl variant="outlined" fullWidth>
      <DatePickers
        autoOk
        disableFuture
        variant="inline"
        name={name}
        label={label}
        value={value || null}
        format={'MM/dd/yyyy'}
        invalidDateMessage=""
        minDateMessage=""
        maxDateMessage=""
        onChange={(selectedDate) => handleDateChange(selectedDate, name)}
        inputVariant="outlined"
        KeyboardButtonProps={{
          'data-testid': `filter-modal-date-picker-${name}-button`,
          'aria-label': 'change date',
        }}
        inputProps={{
          'data-testid': 'filter-modal-date-picker',
        }}
        {...datepickerProps}
        onBlur={() => setBlurred([name])}
        onClose={() => setBlurred([name])}
        error={blurred.includes(name) && dateError}
      />
      {blurred.includes(name) && dateError && (
        <FormHelperText className="color_error">{dateError}</FormHelperText>
      )}
    </FormControl>
  );

  const renderDropdown = (
    label,
    name,
    value,
    list,
    listName,
    disabled,
    loading,
  ) => (
    <>
      <FormControl fullWidth disabled={disabled} variant="outlined">
        <InputLabel>{label}</InputLabel>
        <Select
          id={`filter-modal-select-${label}`}
          name={name}
          label={label}
          value={value || ''}
          onChange={(event) =>
            onFilterValueChange(event.target.name, event.target.value)
          }
        >
          {![
            'partner_id',
            'group_id',
            'region_id',
            'location_id',
            'plan_package_id',
          ].includes(name) && <MenuItem value="">Select {label}</MenuItem>}
          {list?.map((item, index) => (
            <MenuItem
              key={item[name]}
              value={item[name]}
              data-testid={`filter-${label}-select-item-${index}`}
            >
              {item[listName]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {loading && (
        <CircularProgress size={20} thickness={5} className="dropdown-loader" />
      )}
    </>
  );

  const selectedPlan =
    plans.find((indPlan) => indPlan.plan_package_id === plan_package_id) || {};

  const filteredTiers =
    selectedPlan?.pricing_tiers_list?.filter(
      (item, index, thisObj) =>
        index ===
        thisObj.findIndex(
          (t) =>
            t.pricing_tiers?.billing_frequency ===
            item.pricing_tiers?.billing_frequency,
        ),
    ) || [];

  const renderFrequencyDropdown = () => {
    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel>Frequency</InputLabel>
        <Select
          id="filter-modal-select-frequency"
          name="frequency"
          label="Frequency"
          value={billing_frequency || ''}
          onChange={(event) => setFrequency(event.target.value)}
        >
          <MenuItem value="">Select Frequency</MenuItem>

          {!filteredTiers?.length
            ? Object.keys(PLAN_PROCESS_FREQUENCIES).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {PLAN_PROCESS_FREQUENCIES[key]}
                  </MenuItem>
                );
              })
            : filteredTiers?.map((indTier, i) => {
                const freqVal = Object.keys(PLAN_PROCESS_FREQUENCIES).find(
                  (key) =>
                    PLAN_PROCESS_FREQUENCIES[key] ===
                    indTier?.pricing_tiers?.billing_frequency,
                );

                return (
                  <MenuItem
                    key={freqVal}
                    value={freqVal}
                    data-testid={`subscription-frequency-select-item-${i}`}
                  >
                    {indTier?.pricing_tiers?.billing_frequency}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
    );
  };

  const checkformStatus = () => {
    const formUnChanged = _.isEqual(
      {
        ...filterState,
        phone: filterState?.phone === '' ? '1' : filterState.phone,
      },
      customFilter,
    );

    return formUnChanged;
  };

  const phoneError = Boolean(phone?.length > 1 && !Utils.validateMobile(phone));

  return (
    <CommonDialog
      open={props.isFilterOpen}
      dialogTitle="Filter Subscribers"
      primaryBtnTxt="Search"
      isLoading={props.isLoading}
      primaryButtonProps={{
        disabled:
          !(partner_id && group_id) ||
          dateError ||
          phoneError ||
          checkformStatus() ||
          dataLoading,
      }}
      handlePrimaryButtonClick={() => emitFilterCloseEvent('save')}
      secondaryBtnTxt={isFilterActive ? 'Clear Filters' : ''}
      secondaryButtonProps={{ disabled: dataLoading }}
      handleSecondaryButtonClick={() => emitFilterCloseEvent('reset')}
      handleClose={props.closeFilterModal}
    >
      <Grid container spacing={3} alignItems="center">
        <RoleValidator roles={LOCATION_FACILITY_FILTER_ROLES}>
          <Grid item xs={12}>
            <SectionHeader title="By Provider" titleVariant="h6" />
            <Divider />
          </Grid>
        </RoleValidator>

        <RoleValidator roles={PARTNER_FILTER_ROLES}>
          <Grid item xs={12} sm={4} md={3} className="position-relative">
            {renderDropdown(
              'Partner',
              'partner_id',
              partner_id,
              partners,
              'partner_name',
              Boolean(dataLoading),
              loading.partners,
            )}
          </Grid>
        </RoleValidator>

        <RoleValidator roles={GROUP_FILTER_ROLES}>
          <Grid item xs={12} sm={4} md={3} className="position-relative">
            {renderDropdown(
              'Group',
              'group_id',
              group_id,
              groups,
              'group_name',
              Boolean(
                !partner_id || partner_id === 'ALL_PARTNERS' || dataLoading,
              ),
              loading.groups,
            )}
          </Grid>
        </RoleValidator>

        <RoleValidator roles={REGION_FILTER_ROLES}>
          <Grid item xs={12} sm={4} md={3} className="position-relative">
            {renderDropdown(
              'Region',
              'region_id',
              region_id,
              regions,
              'region_name',
              Boolean(
                !partner_id ||
                  !group_id ||
                  group_id === 'ALL_GROUPS' ||
                  dataLoading,
              ),
              loading.regions,
            )}
          </Grid>
        </RoleValidator>

        <RoleValidator roles={LOCATION_FACILITY_FILTER_ROLES}>
          <Grid item xs={12} sm={4} md={3} className="position-relative">
            {renderDropdown(
              'Location',
              'location_id',
              location_id,
              locations,
              'location_name',
              !partner_id ||
                !group_id ||
                !region_id ||
                !locations?.length ||
                dataLoading,
              loading.locations,
            )}
          </Grid>
          {isFacilityEnabled && (
            <Grid item xs={12} sm={4} md={3} className="position-relative">
              {renderDropdown(
                'Facility',
                'facility_id',
                facility_id,
                facilities,
                'facility_name',
                !partner_id ||
                  !group_id ||
                  !region_id ||
                  !location_id ||
                  dataLoading,
                loading.facilities,
              )}
            </Grid>
          )}
        </RoleValidator>

        <Grid item xs={12}>
          <SectionHeader title="By Subscriber" titleVariant="h6" />
          <Divider />
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField('first_name', 'First Name', first_name)}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField('last_name', 'Last Name', last_name)}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField('email', 'Email Address', email)}
        </Grid>

        <Grid item xs={12} sm={4}>
          <PhoneInput
            id="filter-modal-phone"
            isValid={!(phone?.length > 1 && !Utils.validateMobile(phone))}
            error={phoneError}
            countryCodeEditable={false}
            value={phone}
            onChange={(value) => onFilterValueChange('phone', value)}
            placeholder=""
          />
        </Grid>

        {/* <Grid item xs={12} sm={4} md={3}>
          {renderTextField("subscription_id", "Subscriber ID", subscription_id)}
        </Grid> */}

        <Grid item xs={12} sm={4}>
          {renderTextField('patient_id', 'Patient ID', patient_id)}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderDatePicker('date_of_birth', 'DOB', date_of_birth)}
        </Grid>

        <Grid item xs={12}>
          <SectionHeader title="By Subscription" titleVariant="h6" />
          <Divider />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            placeholder="Date Range"
            InputProps={{
              readOnly: true,
              endAdornment: created_start && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={clearDateRange}
                    data-testid="filter-clear-daterange-icon"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={
              created_start
                ? `${formatDate(created_start)} to ${formatDate(created_end)}`
                : ''
            }
            variant="outlined"
            onClick={handlePopoverOpen}
            label={'Select Date Range'}
            inputProps={{
              'data-testid': 'filter-date-range',
            }}
          />
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableAutoFocus
          >
            <DateRangePicker
              open={true}
              toggle={handlePopoverClose}
              initialDateRange={{
                startDate: created_start || moment().subtract(1, 'months'),
                endDate: created_end || moment(),
              }}
              maxDate={new Date()}
              onChange={setDateRange}
            />
          </Popover>
        </Grid>
        <Grid item xs={12} sm={4} md={4} className="position-relative">
          {renderDropdown(
            'Plans',
            'plan_package_id',
            plan_package_id,
            plans,
            'plan_name',
            Boolean(!plans?.length || dataLoading),
            loading.plans,
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} className="position-relative">
          {renderFrequencyDropdown()}
        </Grid>
        <Grid item xs={12} sm={4} md={4} className="position-relative">
          {renderDropdown(
            'Status',
            'stat_key',
            statStatus,
            STAT_CARDS,
            'short_title',
            Boolean(!STAT_CARDS?.length),
            loading.statStatus,
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} className="position-relative">
          {renderDropdown(
            'Payment Method',
            'has_payment_method',
            has_payment_method,
            HAS_PAYMENT_METHOD,
            'has_payment_method',
            false,
            false,
          )}
        </Grid>
        {statStatus === 'Overdue' && (
          <Grid item xs={12} sm={4} md={4} className="position-relative">
            <MultiSelectDropdown
              label="Overdue Days"
              name="overdue_days"
              value={overdue_days}
              list={OVERDUE_LIST}
              listName="label"
              disabled={Boolean(!OVERDUE_LIST?.length)}
              loading={loading.overdue_days}
              onValueChange={onFilterValueChange}
            />
          </Grid>
        )}
      </Grid>
    </CommonDialog>
  );
};

const MultiSelectDropdown = ({
  label,
  name,
  value,
  list,
  listName,
  disabled,
  loading,
  onValueChange,
}) => {
  const renderSelectedValues = (list, listName, name, values) => {
    const finalValue = values.map((value) => {
      const selectedItem = list.find((item) => item[name] === value);

      return selectedItem?.[listName];
    });

    return finalValue.join(', ');
  };

  return (
    <>
      <FormControl fullWidth disabled={disabled} variant="outlined">
        <InputLabel>{label}</InputLabel>
        <Select
          id={`filter-modal-select-${label}`}
          multiple
          name={name}
          label={label}
          value={value || []}
          onChange={(event) =>
            onValueChange(event.target.name, event.target.value)
          }
          renderValue={() => renderSelectedValues(list, listName, name, value)}
        >
          {list?.map((item, index) => (
            <MenuItem
              key={item[name]}
              value={item[name]}
              data-testid={`filter-${label}-select-item-${index}`}
              style={{ padding: 4, backgroundColor: '#FFF' }}
            >
              <CheckBox
                data-testid={`filter-${label}-select-item-${index}-checkbox`}
                checked={value?.includes(item[name])}
              />
              {item[listName]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {loading && (
        <CircularProgress size={20} thickness={5} className="dropdown-loader" />
      )}
    </>
  );
};

MultiSelectDropdown.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  list: PropTypes.array,
  listName: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onValueChange: PropTypes.func,
};

FilterModal.propTypes = {
  isFacilityEnabled: PropTypes.bool,
  isFilterOpen: PropTypes.bool,
  closeFilterModal: PropTypes.func,
  partnerId: PropTypes.string,
  groupId: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default FilterModal;
