import { createSlice } from '@reduxjs/toolkit';

import getPatientInfoSliceData from './patientInfoSliceData';
import getPatientInfoSliceReducers from './patientInfoSliceReducers';

const patientInfoSlice = createSlice({
  name: 'patientInfo',
  initialState: getPatientInfoSliceData(),
  reducers: {
    ...getPatientInfoSliceReducers(),
  },
});

export const { setPatientInfoLoading, setPatientInfo } =
  patientInfoSlice.actions;

export default patientInfoSlice.reducer;
