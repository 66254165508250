import httpService from 'Api/http.service';
import { checkResponse } from './service.utils';

const { REACT_APP_FLEX_API_BASE_URL } = process.env;

const activitiesURL = `${REACT_APP_FLEX_API_BASE_URL}/v1/activities`;

export const getInvoiceActivities = async (id: string) => {
  const response = await httpService({
    url: activitiesURL + `/invoice/${id}?limit=50&offset=0`,
    method: 'get',
  });

  return checkResponse(response);
};
