import moment from 'moment';
import IInvoiceSliceData from 'Views/FlexPayV2/types/store/invoiceSlice/invoiceSliceData';

export const initialInvoiceFilter = {
  created: {
    createdLabel: 'Last 30 Days',
    createdStart: moment().subtract(30, 'days').toDate().toISOString(),
    createdEnd: moment().toISOString(),
  },
  due: {
    dueLabel: '',
    dueStart: null,
    dueEnd: null,
  },
  patientId: '',
  name: {
    firstName: '',
    lastName: '',
  },
  invoiceId: '',
  email: '',
  status: [],
};

export const initialInvoicePagination = {
  currentPageNo: 0,
  currentRowsPerPage: 25,
  totalCount: 0,
};

const getInvoiceSliceData = (): IInvoiceSliceData => ({
  invoiceFilter: {
    ...initialInvoiceFilter,
  },
  invoiceVOMap: {},
  invoiceLoading: false,
  invoiceStats: {
    paidAmount: 0,
    outstandingAmount: 0,
    overdueAmount: 0,
  },
  invoiceTablePagination: {
    ...initialInvoicePagination,
  },
  invoiceTableSort: {
    orderBy: 'created_at',
    orderDesc: true,
  },
  exportLoading: false,
  actionLoading: {
    delete: false,
    cancel: false,
    refund: false,
    download: false,
    send: false,
  },
  invoiceStatusMap: {
    draft: ['draft'],
    open: ['open'],
    paid: ['paid'],
    overdue: ['overdue'],
    refunded: ['refunded', 'partially_refunded'],
  },
});

export default getInvoiceSliceData;
