import moment from 'moment';
import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import {
  getPatientsList,
  downloadPatientsList,
} from 'Views/FlexPayV2/patients/services/patients.service';

// Utils
import Utils from 'Shared/Utils';
import { constructPatientListParams } from 'Views/FlexPayV2/utils/queryParamsUtils';
import { downloadFile, formatDate } from 'utils';

// Types
import IGlobalSliceData from 'Views/FlexPayV2/types/store/globalSlice/IGlobalSliceData';
import IPatientListSliceData from 'Views/FlexPayV2/types/store/patientListSlice/patientListSliceData';
import IPatientListFilters, {
  IPatientListTablePagination,
  IPatientListTableSort,
} from 'Views/FlexPayV2/types/vo/IPatientListFilters';

// Store
import { RootState } from '../storeRoot';
import {
  setPatientListLoading,
  setPatientListVOMap,
  setPatientListFilter,
  setPatientListTablePagination,
  setPatientListTableSorting,
  setExportLoading,
} from './patientListSlice';
import { initialPatientListFilter } from './patientListSliceData';

const getGlobalSlice = (getState: () => { global: IGlobalSliceData }) =>
  getState().global;

const getPatientListSlice = (
  getState: () => { patientList: IPatientListSliceData },
) => getState().patientList;

// fetchPatientListThunk - fetch the patient list data as per the filter
export const fetchPatientListThunk = createAsyncThunk<
  void,
  void,
  { state: RootState }
>(
  'patientList/fetchPatientListThunk',
  async (_, { getState: gs, dispatch }) => {
    // set loading to true
    dispatch(setPatientListLoading(true));

    // fetch the params as per the filter applied
    const globalSlice = getGlobalSlice(gs);
    const patientListFilterData = getPatientListSlice(gs).patientListFilter;
    const patientListTablePagination =
      getPatientListSlice(gs).patientListTablePagination;
    const patientListTableSort = getPatientListSlice(gs).patientListTableSort;

    const { qParams: formattedParams } = constructPatientListParams(
      globalSlice,
      patientListFilterData,
      patientListTablePagination,
      patientListTableSort,
    );

    const listResponse = await getPatientsList(formattedParams);

    batch(() => {
      if (Utils.checkIfSuccess(listResponse) && listResponse?.data) {
        // Setting the patient list data
        const patientList = listResponse.data.data || [];

        // Setting the patient list VO map
        const patinetListVOMap = patientList.reduce((acc, eachVO) => {
          acc[eachVO.id] = eachVO;
          return acc;
        }, {});

        dispatch(setPatientListVOMap(patinetListVOMap));

        dispatch(
          setPatientListTablePagination({
            ...patientListTablePagination,
            totalCount: listResponse?.data?.total_count,
          }),
        );
      } else {
        dispatch(setPatientListVOMap({}));
      }

      // set loading to false
      dispatch(setPatientListLoading(false));
    });
  },
);

// exportPatientListThunk - export the patient list data as per the filter
export const exportPatientListThunk = createAsyncThunk<
  void,
  void,
  { state: RootState }
>(
  'patientList/exportPatientListThunk',
  async (_, { getState: gs, dispatch }) => {
    // set loading to true
    dispatch(setExportLoading(true));

    // fetch the params as per the filter applied
    const globalSlice = getGlobalSlice(gs);
    const patientListFilterData = getPatientListSlice(gs).patientListFilter;
    const patientListTablePagination =
      getPatientListSlice(gs).patientListTablePagination;
    const patientListTableSort = getPatientListSlice(gs).patientListTableSort;

    const { qParams: formattedParams } = constructPatientListParams(
      globalSlice,
      patientListFilterData,
      patientListTablePagination,
      patientListTableSort,
    );

    const downloadResponse = await downloadPatientsList(formattedParams);

    if (Utils.checkIfSuccess(downloadResponse) && downloadResponse?.data) {
      const {
        appointmentDate: { apptDateStart, apptDateEnd },
      } = patientListFilterData;

      downloadFile(
        downloadResponse.data,
        'text/csv',
        `Patients Report - ${formatDate(apptDateStart)} to ${formatDate(
          apptDateEnd,
        )}.csv`,
      );

      window.setWindowNotification('success', 'Exported successfully');
    }

    // set loading to false
    dispatch(setExportLoading(false));
  },
);

// apply filter :
export const applyFilterThunk = createAsyncThunk<
  void, // return type
  Partial<IPatientListFilters>, // payload type
  { state: RootState } // store type as thunk is independent of store
>(
  'patientList/applyFilterThunk',
  async (newFilterVO, { getState: gs, dispatch }) => {
    batch(() => {
      // update the filter in patient filter slice, component will get update
      const { patientListFilter } = getPatientListSlice(gs);
      dispatch(setPatientListFilter({ ...patientListFilter, ...newFilterVO }));

      // fetch the patient list data as per the new filter
      dispatch(fetchPatientListThunk());
    });
  },
);

// clear filter :
export const clearFilterThunk = createAsyncThunk<
  void, // return type
  void, // payload type
  { state: RootState } // store type as thunk is independent of store
>('patientList/clearFilterThunk', async (_, { getState: gs, dispatch }) => {
  batch(() => {
    // Reset the filter in patient filter slice, component will get update
    dispatch(
      setPatientListFilter({
        ...initialPatientListFilter,
      }),
    );

    // fetch the patient list data as per the new filter
    dispatch(fetchPatientListThunk());
  });
});

// Apply single filter and reset rest of the filters
export const applySingleFilterThunk = createAsyncThunk<
  void, // return type
  Partial<IPatientListFilters>, // payload type
  { state: RootState } // store type as thunk is independent of store
>(
  'patientList/applySingleFilterThunk',
  async (newFilterVO, { getState: gs, dispatch }) => {
    // Update the single filter in patient filter slice
    // Reset the other filter in patient filter slice to update the component
    dispatch(
      setPatientListFilter({
        ...initialPatientListFilter,
        ...newFilterVO,
      }),
    );

    // fetch the patient list data as per the new filter
    dispatch(fetchPatientListThunk());
  },
);

// changePaginationPropsThunk - this will change the pagination props and fetch the patient List
export const changePaginationPropsThunk = createAsyncThunk<
  void, // return type
  Partial<IPatientListTablePagination>, // payload type
  { state: RootState } // store type as thunk is independent of store
>(
  'patientList/changePaginationPropsThunk',
  async (newPaginationVO, { getState: gs, dispatch }) => {
    batch(() => {
      //
      const { patientListTablePagination } = getPatientListSlice(gs);
      dispatch(
        setPatientListTablePagination({
          ...patientListTablePagination,
          ...newPaginationVO,
        }),
      );

      // fetch the patient list data as per the new filter
      dispatch(fetchPatientListThunk());
    });
  },
);

// change pagination props thunk : this will change the pagination props and fetch the patient List
export const changeSortingThunk = createAsyncThunk<
  void, // return type
  Partial<IPatientListTablePagination & IPatientListTableSort>, // payload type
  { state: RootState } // store type as thunk is independent of store
>(
  'patientList/changeSortingThunk',
  async (newSortingVO, { getState: gs, dispatch }) => {
    batch(() => {
      const { patientListTableSort } = getPatientListSlice(gs);

      dispatch(
        setPatientListTableSorting({
          ...patientListTableSort,
          ...newSortingVO,
        }),
      );

      // fetch the patient list data as per the new filter
      dispatch(fetchPatientListThunk());
    });
  },
);
