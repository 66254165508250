import httpService from 'Api/http.service';
import { checkResponse } from '../../invoices/services/service.utils';

const { REACT_APP_FLEX_API_BASE_URL } = process.env;

const patientsURL = `${REACT_APP_FLEX_API_BASE_URL}/v1/patients`;

export const getPatientsList = async (params: string) => {
  const response = await httpService({
    url: patientsURL + `?${params}`,
    method: 'get',
  });

  return checkResponse(response);
};

export const downloadPatientsList = async (params: any) => {
  const response = await httpService({
    url: patientsURL + '/download?' + params,
    method: 'get',
  });

  return checkResponse(response);
};

export const getPatientById = async (id: string) => {
  const response = await httpService({
    url: patientsURL + `/${id}`,
    method: 'get',
  });

  return checkResponse(response);
};
