import IPatientSliceData from 'Views/FlexPayV2/types/store/patientInfoSlice/patientInfoSliceData';

const getPatientSliceData = (): IPatientSliceData => ({
  patientInfoLoading: false,
  patientVO: {
    first_name: '',
    last_name: '',
    gender: '',
    pms_patient_id: '',
    pms_patient_display_id: '',
    date_of_birth: '',
    employee_id: '',
    department: '',
    email: '',
    phone: '',
    id: '',
    active_since: '',
    address: {
      address_line1: '',
      city: '',
      state: '',
      postal_code: '',
      country_code: '',
    },
    invoices: [],
  },
});

export default getPatientSliceData;
