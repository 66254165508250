import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import {
  cancelInvoiceById,
  deleteInvoiceById,
  downloadInvoices,
  getInvoices,
  refundInvoiceById,
  sendInvoiceReceipt,
  sendInvoiceReminder,
  downloadInvoiceById,
} from 'Views/FlexPayV2/invoices/services/invoices.service';

// Utils
import Utils from 'Shared/Utils';
import { constructInVoiceParams } from 'Views/FlexPayV2/utils/queryParamsUtils';
import { downloadFile, formatDate } from 'utils';

// Constants
import { PAGE_URLS } from 'Routes/Main/constants';

// Types
import IInvoiceFilters, {
  IInvoiceTablePagination,
  IInvoiceTableSort,
} from 'Views/FlexPayV2/types/vo/IInvoiceFilters';
import IGlobalSliceData from 'Views/FlexPayV2/types/store/globalSlice/IGlobalSliceData';
import IInvoiceSliceData from 'Views/FlexPayV2/types/store/invoiceSlice/invoiceSliceData';

// Store
import { RootState } from '../storeRoot';
import {
  setActionLoading,
  setExportLoading,
  setInvoiceFilter,
  setInvoiceLoading,
  setInvoiceStats,
  setInvoiceTablePagination,
  setInvoiceTableSorting,
  setInvoiceVOMap,
} from './invoiceSlice';
import { changeGroupThunk } from '../globalSlice/globalSliceThunks';
import {
  initialInvoiceFilter,
  initialInvoicePagination,
} from './invoiceSliceData';
import { setLocationId } from '../globalSlice/globalSlice';
import { fetchInvoiceInfoThunk } from '../invoiceInfoSlice/invoiceInfoSliceThunks';
import { fetchPatientListThunk } from '../patientListSlice/patientListSliceThunks';
// import { fakeInvoiceList } from 'Views/FlexPayV2/types/vo/IInVoiceVO';

const getGlobalSlice = (getState: () => { global: IGlobalSliceData }) =>
  getState().global;

const getInvoiceSlice = (getState: () => { invoiceList: IInvoiceSliceData }) =>
  getState().invoiceList;

// fetchInvoiceListThunk
export const fetchInvoiceListThunk = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('invoice/fetchInvoiceListThunk', async (_, { getState: gs, dispatch }) => {
  // load the table :
  dispatch(setInvoiceLoading(true));

  // fetch the invoice list data as per the filter
  const globalSlice = getGlobalSlice(gs);
  const invoiceFilterSlice = getInvoiceSlice(gs).invoiceFilter;
  const invoiceTablePagination = getInvoiceSlice(gs).invoiceTablePagination;
  const invoiceTableSort = getInvoiceSlice(gs).invoiceTableSort;

  const params = constructInVoiceParams(
    globalSlice,
    invoiceFilterSlice,
    invoiceTablePagination,
    invoiceTableSort,
  );
  const invoiceResponse = await getInvoices(params);

  if (Utils.checkIfSuccess(invoiceResponse) && invoiceResponse?.data) {
    // set the invoice list data in store
    // invoiceResponse.data.data
    const invoiceList = invoiceResponse.data.data || [];

    // Setting the VO Map :
    const invoiceVOMap = invoiceList.reduce((acc, eachInvoiceVO) => {
      acc[eachInvoiceVO.id] = eachInvoiceVO;
      return acc;
    }, {});

    dispatch(setInvoiceVOMap(invoiceVOMap));

    dispatch(
      setInvoiceTablePagination({
        ...invoiceTablePagination,
        totalCount: invoiceResponse?.data?.total_count,
      }),
    );

    const paidAmount = invoiceResponse.data.total_paid_amount || 0;
    const outstandingAmount =
      invoiceResponse.data.total_outstanding_amount || 0;
    const overdueAmount = invoiceResponse.data.total_overdue_amount || 0;

    // set the invoice stats:
    dispatch(
      setInvoiceStats({
        paidAmount,
        outstandingAmount,
        overdueAmount,
      }),
    );
  } else {
    dispatch(setInvoiceVOMap({}));
    setInvoiceStats({
      paidAmount: 0,
      outstandingAmount: 0,
      overdueAmount: 0,
    });
  }

  // set loading to false
  dispatch(setInvoiceLoading(false));
});

// export Invoices Thunk : Export all invoices that matches the filter
export const exportInvoicesThunk = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('invoice/exportInvoicesThunk', async (_, { getState: gs, dispatch }) => {
  // set loading to true
  dispatch(setExportLoading(true));

  // fetch the params as per the filter applied
  const globalSlice = getGlobalSlice(gs);
  const invoiceFilterSlice = getInvoiceSlice(gs).invoiceFilter;
  const invoiceTablePagination = getInvoiceSlice(gs).invoiceTablePagination;
  const invoiceTableSort = getInvoiceSlice(gs).invoiceTableSort;

  const params = constructInVoiceParams(
    globalSlice,
    invoiceFilterSlice,
    invoiceTablePagination,
    invoiceTableSort,
  );

  const downloadResponse = await downloadInvoices(params);

  if (Utils.checkIfSuccess(downloadResponse) && downloadResponse?.data) {
    const {
      created: { createdEnd, createdStart },
    } = invoiceFilterSlice;

    downloadFile(
      downloadResponse.data,
      'text/csv',
      `Invoices Report - ${formatDate(createdStart)} to ${formatDate(
        createdEnd,
      )}.csv`,
    );

    window.setWindowNotification('success', 'Exported successfully');
  }

  // set loading to false
  dispatch(setExportLoading(false));
});

// apply filter :
export const applyFilterThunk = createAsyncThunk<
  void, // return type
  Partial<IInvoiceFilters>, // payload type
  { state: RootState } // store type as thunk is independent of store
>(
  'invoice/applyFilterThunk',
  async (newFilterVO, { getState: gs, dispatch }) => {
    batch(() => {
      // get and set the new filter in invoide filter slice, component will get update
      const { invoiceFilter } = getInvoiceSlice(gs);
      dispatch(setInvoiceFilter({ ...invoiceFilter, ...newFilterVO }));
      dispatch(setInvoiceTablePagination({ ...initialInvoicePagination }));

      // fetch the invoide list data as per the new filter
      dispatch(fetchInvoiceListThunk());
    });
  },
);

// clear filter :
export const clearFilterThunk = createAsyncThunk<
  void, // return type
  void, // payload type
  { state: RootState } // store type as thunk is independent of store
>('invoice/clearFilterThunk', async (_, { getState: gs, dispatch }) => {
  batch(() => {
    // reset the filter in invoice filter slice, component will get update
    dispatch(
      setInvoiceFilter({
        ...initialInvoiceFilter,
        created: {
          createdLabel: '',
          createdStart: null,
          createdEnd: null,
        },
      }),
    );

    // fetch the invoide list data as per the new filter
    dispatch(fetchInvoiceListThunk());
  });
});

// Apply single filter and reset rest of the filters
export const applySingleFilterThunk = createAsyncThunk<
  void, // return type
  Partial<IInvoiceFilters>, // payload type
  { state: RootState } // store type as thunk is independent of store
>(
  'invoice/applySingleFilterThunk',
  async (newFilterVO, { getState: gs, dispatch }) => {
    // update the filter in invoice filter slice, component will get update
    dispatch(
      setInvoiceFilter({
        ...initialInvoiceFilter,
        created: {
          createdLabel: '',
          createdStart: null,
          createdEnd: null,
        },
        ...newFilterVO,
      }),
    );

    // fetch the invoide list data as per the new filter
    dispatch(fetchInvoiceListThunk());
  },
);

// change pagination props thunk : this will change the pagination props and fetch the invoice List
export const changePaginationPropsThunk = createAsyncThunk<
  void, // return type
  Partial<IInvoiceTablePagination>, // payload type
  { state: RootState } // store type as thunk is independent of store
>(
  'invoice/changePaginationPropsThunk',
  async (newPaginationVO, { getState: gs, dispatch }) => {
    batch(() => {
      //
      const { invoiceTablePagination } = getInvoiceSlice(gs);
      dispatch(
        setInvoiceTablePagination({
          ...invoiceTablePagination,
          ...newPaginationVO,
        }),
      );

      // fetch the invoice list data as per the new filter
      dispatch(fetchInvoiceListThunk());
    });
  },
);

// change pagination props thunk : this will change the pagination props and fetch the invoice List
export const changeSortingThunk = createAsyncThunk<
  void, // return type
  Partial<IInvoiceTablePagination & IInvoiceTableSort>, // payload type
  { state: RootState } // store type as thunk is independent of store
>(
  'invoice/changeSortingThunk',
  async (newSortingVO, { getState: gs, dispatch }) => {
    batch(() => {
      //
      const { invoiceTableSort } = getInvoiceSlice(gs);
      dispatch(
        setInvoiceTableSorting({
          ...invoiceTableSort,
          ...newSortingVO,
        }),
      );

      // fetch the invoide list data as per the new filter
      dispatch(fetchInvoiceListThunk());
    });
  },
);

// change group thunk : this will change the group in global and fetch the invoice List
export const changeGroupInVoiceThunk = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  'invoice/changeGroupInVoiceThunk',
  async (newGroupId, { getState: gs, dispatch }) => {
    const isOk = await dispatch(changeGroupThunk(newGroupId));
    if (!isOk) return;

    // fetch the invoice list data as per the new group
    dispatch(fetchInvoiceListThunk());
  },
);

// change location thunk : this will change the location in global and fetch the invoice List
export const changeLocationInVoiceThunk = createAsyncThunk<
  void,
  { locationId: string; history: any },
  { state: RootState }
>(
  'invoice/changeLocationInVoiceThunk',
  async ({ locationId, history }, { getState: gs, dispatch }) => {
    dispatch(setLocationId(locationId));

    // fetch the invoice list data as per the new location only if the current page is invoice list
    if (history?.location?.pathname === PAGE_URLS.FLEX_PAY_INVOICES_LIST) {
      dispatch(fetchInvoiceListThunk());
    }

    // fetch the patients list data as per the new location only if the current page is patients list
    if (history?.location?.pathname === PAGE_URLS.FLEX_PAY_PATIENTS_LIST) {
      dispatch(fetchPatientListThunk());
    }
  },
);

// cancel invoice thunk : this will cancel the invoice and fetch the invoice List or redirect to list
export const cancelInvoiceThunk = createAsyncThunk<
  { success: boolean },
  {
    invoiceId: string;
    cancelReason: string;
    cancelDesc: string;
    pageToRefresh: string;
  },
  { state: RootState }
>(
  'invoice/cancelInvoiceThunk',
  async (
    { invoiceId, cancelReason, cancelDesc, pageToRefresh },
    { getState: gs, dispatch },
  ) => {
    dispatch(setActionLoading({ cancel: true }));

    const response = await cancelInvoiceById(invoiceId, {
      cancel_reason: cancelReason,
      cancel_desc: cancelDesc,
    });

    let success = false;

    if (!Utils.checkIfSuccess(response)) {
      window.setWindowNotification('success', 'Invoice cancelled successfully');

      if (pageToRefresh === 'info') {
        // fetch the invoice info
        dispatch(fetchInvoiceInfoThunk(invoiceId));
      } else {
        // fetch the invoice list
        dispatch(fetchInvoiceListThunk());
      }

      success = true;
    }

    dispatch(setActionLoading({ cancel: false }));

    return { success };
  },
);

// delete invoice thunk : this will delete the invoice and fetch the invoice info or List
export const deleteInvoiceThunk = createAsyncThunk<
  { success: boolean },
  {
    invoiceId: string;
    pageToRefresh: string;
    history: any;
  },
  { state: RootState }
>(
  'invoice/deleteInvoiceThunk',
  async ({ invoiceId, pageToRefresh, history }, { dispatch }) => {
    dispatch(setActionLoading({ delete: true }));

    const response = await deleteInvoiceById(invoiceId);

    let success = false;

    if (Utils.checkIfSuccess(response)) {
      window.setWindowNotification('success', 'Invoice deleted successfully');

      if (pageToRefresh === 'info') {
        // redirect to list page
        history.push(PAGE_URLS.FLEX_PAY_INVOICES_LIST);
      } else {
        // fetch the invoice list
        dispatch(fetchInvoiceListThunk());
      }

      success = true;
    }

    dispatch(setActionLoading({ delete: false }));

    return { success };
  },
);

// refund invoice thunk : this will refund and cancel the invoice and fetch the invoice info or List
export const refundInvoiceThunk = createAsyncThunk<
  { success: boolean },
  {
    invoiceId: string;
    refundAmount: number;
    reason: string;
    desc: string;
    pageToRefresh: string;
  },
  { state: RootState }
>(
  'invoice/refundInvoiceThunk',
  async (
    { invoiceId, refundAmount, reason, desc, pageToRefresh },
    { getState: gs, dispatch },
  ) => {
    dispatch(setActionLoading({ refund: true }));

    const response = await refundInvoiceById(invoiceId, {
      amount: refundAmount,
      refund_reason: reason,
      refund_description: desc || null,
    });

    let success = false;

    if (Utils.checkIfSuccess(response)) {
      window.setWindowNotification('success', 'Invoice refunded successfully');

      success = true;

      if (pageToRefresh === 'info') {
        // fetch the invoice info
        dispatch(fetchInvoiceInfoThunk(invoiceId));
      } else {
        // fetch the invoice list
        dispatch(fetchInvoiceListThunk());
      }
    }

    dispatch(setActionLoading({ refund: false }));

    return { success };
  },
);

// download receipt thunk : this will download the receipt as pdf
export const downloadReceiptThunk = createAsyncThunk<
  void,
  {
    paymentId: string;
    invoiceId?: string;
    type?: string;
  },
  { state: RootState }
>(
  'invoice/downloadReceiptThunk',
  async ({ paymentId, invoiceId, type = 'Receipt' }, { dispatch }) => {
    dispatch(setActionLoading({ download: true }));

    const downloadResponse = await downloadInvoiceById(invoiceId);

    if (Utils.checkIfSuccess(downloadResponse) && downloadResponse?.data) {
      downloadFile(
        downloadResponse.data,
        'application/pdf',
        `${type} - ${invoiceId}.pdf`,
      );

      window.setWindowNotification(
        'success',
        `${type} downloaded successfully`,
      );
    }

    dispatch(setActionLoading({ download: false }));
  },
);

// send reminder thunk - this will send the reminder to the patient
export const sendInvoiceReminderThunk = createAsyncThunk<
  { success: boolean },
  string,
  { state: RootState }
>('invoice/sendInvoiceReminderThunk', async (invoiceId, { dispatch }) => {
  dispatch(setActionLoading({ send: true }));

  const response = await sendInvoiceReminder(invoiceId);

  let success = false;

  if (Utils.checkIfSuccess(response)) {
    window.setWindowNotification('success', 'Reminder sent successfully');

    success = true;
  }

  dispatch(setActionLoading({ send: false }));

  return { success };
});

export const sendInvoiceReceiptThunk = createAsyncThunk<
  { success: boolean },
  string,
  { state: RootState }
>('invoice/sendInvoiceReceiptThunk', async (invoiceId, { dispatch }) => {
  dispatch(setActionLoading({ send: true }));

  const response = await sendInvoiceReceipt(invoiceId);

  let success = false;

  if (Utils.checkIfSuccess(response)) {
    window.setWindowNotification('success', 'Receipt sent successfully');

    success = true;
  }

  dispatch(setActionLoading({ send: false }));

  return { success };
});
