import React from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { IconButton, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Components
import { Chip, TableListView } from 'components';

// Constants
import { PAGE_URLS } from 'Routes/Main/constants';

// Styles
import styles from './AssociatedLocation.module.scss';

const AssociatedLocation = ({
  locationList,
  hierarchyDetails: { partner_id, group_id, region_id },
  isCreationEnabled,
  isSettingsView,
}) => {
  const history = useHistory();

  const commonCellProps = () => ({ className: styles.tableColumn });

  if (region_id) {
    locationList = locationList.filter((item) => item.region_id === region_id);
  }

  const tableColumns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        setCellProps: commonCellProps,
        sort: false,
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        setCellProps: commonCellProps,
        sort: false,
        display: !isSettingsView,
      },
    },
  ];

  const tableData = locationList?.map((indLocation) => ({
    name: indLocation.location_name,
    address: indLocation?.address?.line1,
    city: indLocation?.address?.city,
    state: indLocation?.address?.state,
    status: <Chip label={indLocation.status} status={indLocation.status} />,
    action: (
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => viewLocation(indLocation)}
        data-testid="view-location-button"
      >
        <VisibilityIcon className="theme_color_orange" fontSize="inherit" />
      </IconButton>
    ),
  }));

  const triggerLocationCreation = () =>
    history.push({
      pathname: PAGE_URLS.CREATE_LOCATION,
      state: { partner_id, group_id, region_id },
    });

  const viewLocation = (locationData) =>
    history.push({
      pathname: PAGE_URLS.LOCATION_INFO,
      state: { locationData },
    });

  const onRowClick = (rowData, rowMeta) => {
    const { dataIndex = 0 } = rowMeta;

    !isSettingsView && viewLocation(locationList[dataIndex]);
  };

  const btnProps = {
    otherButtons: isCreationEnabled && (
      <Button
        onClick={triggerLocationCreation}
        color="secondary"
        size="large"
        data-testid="add-location"
      >
        Add Location
      </Button>
    ),
  };

  return (
    <TableListView
      headerTitle="Associated Location(s)"
      tableData={tableData}
      tableOptions={{
        onRowClick,
      }}
      tableColumns={tableColumns}
      pagination={Boolean(tableData.length > 10)}
      headerSection={true}
      emptyPlaceHolderContent="No Associated Location found"
      {...btnProps}
    />
  );
};

AssociatedLocation.propTypes = {
  locationList: PropTypes.array.isRequired,
  hierarchyDetails: PropTypes.object,
  isCreationEnabled: PropTypes.bool,
  isSettingsView: PropTypes.bool,
};

export default AssociatedLocation;
