import { createSlice } from '@reduxjs/toolkit';

import getInvoiceInfoSliceData from './invoiceInfoSliceData';
import getInvoiceInfoSliceReducers from './invoiceInfoSliceReducers';

const invoiceInfoSlice = createSlice({
  name: 'invoiceInfo',
  initialState: getInvoiceInfoSliceData(),
  reducers: {
    ...getInvoiceInfoSliceReducers(),
  },
});

export const {
  setInvoiceInfoLoading,
  setInvoiceInfo,
  setInvoiceNotesLoading,
  setInvoiceNotes,
  setNoteActionLoading,
  setActivitiesLoading,
  setInvoiceActivities,
} = invoiceInfoSlice.actions;

export default invoiceInfoSlice.reducer;
